/* eslint-disable */
import { graphql } from 'react-apollo'
import { compose, withHandlers } from 'recompose'

import * as mutations from './mutations'

export const withSignedUrl = compose(
  graphql(mutations.getSignedUrl, {
    name: 'getSignedUrl',
  }),
  withHandlers({
    getSignedUrl:
      ({ getSignedUrl }) =>
      (fileId) =>
        getSignedUrl({
          variables: { fileId },
        }),
  }),
)

export default (options) => {
  return compose(
    graphql(mutations.uploadFile, {
      name: 'uploadFile',
      options,
    }),
    graphql(mutations.deleteFile, {
      name: 'deleteFile',
      options,
    }),
    graphql(mutations.getUploadSignedUrl, {
      name: 'getUploadSignedUrl',
      options,
    }),
    withHandlers({
      deleteFile:
        ({ deleteFile }) =>
        (fileId) =>
          deleteFile({ variables: { fileId } }),
      uploadFile:
        ({ uploadFile }) =>
        ({ entityId, fileInput, file, providerKey }) =>
          uploadFile({
            variables: { entityId, fileInput, providerKey },
          }).then((r) => r.data.uploadFile),
      getUploadSignedUrl:
        ({ getUploadSignedUrl }) =>
        (entityId) =>
          getUploadSignedUrl({
            variables: { entityId },
          }).then((r) => r.data.getUploadSignedUrl),
    }),
  )
}
