import { statusLabels as _statusLabels } from '../ManuscriptCard/utils'
import {
  BinCategory,
  BinKeys,
  ManuscriptDropDownStatuses,
  ManuscriptStatusMap,
  OrderCategory,
  Permission,
  PermissionsInfo,
  StorageSelection,
} from './types'

export const statusLabels: typeof _statusLabels & { all: string } = {
  ..._statusLabels,
  all: 'All Statuses',
}

export const manuscriptBinStatusesMap: Readonly<ManuscriptStatusMap> = {
  ActionRequired: ['draft', 'revisionRequested', 'qualityChecksRequested'],
  InProgress: [
    'technicalChecks',
    'academicEditorInvited',
    'academicEditorAssigned',
    'reviewersInvited',
    'underReview',
    'pendingApproval',
    'qualityChecksSubmitted',
    'submitted',
  ],
  Finalized: [
    'refusedToConsider',
    'withdrawn',
    'void',
    'rejected',
    'published',
  ],
}

export const ALL_POSSIBLE_STATUSES: ManuscriptDropDownStatuses = Object.keys(
  manuscriptBinStatusesMap,
)
  .map((key) => manuscriptBinStatusesMap[key])
  .flat()
  .concat('All')

export const DEFAULT_ITEM_PER_PAGE = 10

export const binList = Object.keys(BinCategory).filter((bin) =>
  isNaN(+bin),
) as BinCategory[]

export const manuscriptPermissionList = Object.keys(Permission).filter((bin) =>
  isNaN(+bin),
) as Permission[]

export const permissionsInfo: PermissionsInfo = {
  Viewer:
    'Access all active manuscripts across journals where viewer permission is held, along with assigned manuscripts. Note that editorial actions are limited to assigned manuscripts.',
  Editor: '',
}

export const BIN_CATEGORY_FILTER_MAP = {
  ActionRequired: 'needsAttention',
  All: 'All',
  Finalized: 'archived',
  InProgress: 'inProgress',
} as const satisfies Record<BinKeys, string>

export const DEFAULT_STORAGE_SELECTION: StorageSelection = {
  chosenOrder: OrderCategory.UPDATED_DATE_NEWEST_FIRST,
}

export const STATUS_TOOLTIP =
  'You can choose a status from the available manuscripts on the current page. The available statuses change when you select a different category from above.'

export const ORDER_CATEGORY_FILTER_MAP = {
  [OrderCategory.UPDATED_DATE_OLDEST_FIRST]: {
    orderBy: 'updated',
    orderDirection: 'asc',
  },
  [OrderCategory.UPDATED_DATE_NEWEST_FIRST]: {
    orderBy: 'updated',
    orderDirection: 'desc',
  },
  [OrderCategory.SUBMISSION_DATE_OLDEST_FIRST]: {
    orderBy: 'submittedDate',
    orderDirection: 'asc',
  },
  [OrderCategory.SUBMISSION_DATE_NEWEST_FIRST]: {
    orderBy: 'submittedDate',
    orderDirection: 'desc',
  },
}
