import { Card, Space } from '@hindawi/phenom-ui'
import React from 'react'
import { BinCard, OrderSelect, StatusSelect } from '.'
import { BinCategory, Permission, permissionMapping } from '../types'
import {
  binList,
  manuscriptPermissionList,
  permissionsInfo,
} from '../constants'

export const FiltersCard = ({
  statuses,
  bin,
  manuscriptPermission,
  onChangeBin,
  onChangeManuscriptPermission,
  chosenStatus,
  onChangeStatus,
  onChangeOrder,
  chosenOrder,
  getCountByBin,
  getCountByManuscriptPermission,
  hasViewerPermission,
}) => {
  return (
    <Card data-test-id="filters-card">
      <Space direction="vertical" size={16}>
        <BinCard
          bin={manuscriptPermission}
          binCategory={Permission}
          binList={manuscriptPermissionList}
          onChangeBin={onChangeManuscriptPermission}
          getCountByBin={getCountByManuscriptPermission}
          info={permissionsInfo}
          visible={hasViewerPermission}
        />
        <BinCard
          bin={bin}
          binCategory={BinCategory}
          binList={binList}
          onChangeBin={onChangeBin}
          getCountByBin={getCountByBin}
          visible={
            permissionMapping.get(manuscriptPermission) !== Permission.Viewer
          }
        />
        <StatusSelect
          chosenStatus={chosenStatus}
          onChangeStatus={onChangeStatus}
          statuses={statuses}
        />
        <OrderSelect onChangeOrder={onChangeOrder} chosenOrder={chosenOrder} />
      </Space>
    </Card>
  )
}
