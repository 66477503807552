import React, { ReactNode } from 'react'
import { Route } from 'react-router-dom'
import track from 'react-tracking'

export const TrackedRoute: React.FC<{
  pageName?: string
  component?: any
  children?: ReactNode
  exact?: boolean
  path?: string
}> = ({ pageName, component, children, path, ...props }) => {
  const w = window as any
  if (w.initGTM && !w.isGTMActive) {
    w.initGTM()
  }
  const whatToRender: {
    children?: ReactNode | undefined
    component?: any
  } = {}

  if (!component) {
    whatToRender.children = children
  } else {
    whatToRender.component = track({ page: pageName || path })(component)
  }

  return <Route {...whatToRender} path={path} {...props} />
}

export default TrackedRoute
