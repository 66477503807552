export const alphanumericRegex = new RegExp(/(^[a-zA-Z0-9-\s]+$)/)
export const emailRegex = new RegExp(
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
)

export const required = (value) => {
  if (value === null || value === undefined) {
    return 'Required'
  }

  if (typeof value === 'string' && value.trim() === '') {
    return 'Required'
  }

  if (Array.isArray(value) && !value.length) {
    return 'Required'
  }

  return undefined
}
export const alphaNumericValidator = (value) =>
  alphanumericRegex.test(value) ? undefined : 'Invalid'

export const issnValidator = (value) =>
  alphanumericRegex.test(value) || !value ? undefined : 'Invalid'

export const exactLength = (length) => (value) => {
  if (value && value.length === length) return undefined
  return `The input should have exactly ${length} characters!`
}

export const emailValidator = (value) =>
  emailRegex.test(value) ? undefined : 'Invalid email'

export const oneOfTheFormats =
  ({ formats, errorMessage }) =>
  (value) => {
    const entryIsValid = formats.some((format) =>
      new RegExp(format).test(value),
    )
    return entryIsValid ? undefined : errorMessage
  }
