import { useState, useEffect } from 'react'

const Fade = ({ children, timeout = 3000 }) => {
  const [visible, setVisible] = useState(true)

  useEffect(() => {
    setVisible(true)
    const timeoutEffect = setTimeout(() => {
      setVisible(false)
    }, timeout)

    return () => {
      clearTimeout(timeoutEffect)
    }
  }, [children, timeout])

  return visible && children
}

export default Fade
