import gql from 'graphql-tag'
import { fileDetails } from '../../../component-files/client/graphql/fragments'

export const teamMember = gql`
  fragment teamMember on TeamMember {
    id
    isSubmitting
    isCorresponding
    invited
    responded
    reviewerNumber
    invitationSource
    user {
      id
    }
    status
    alias {
      aff
      affRorId
      email
      country
      name {
        surname
        givenNames
      }
    }
  }
`
export const specialIssue = gql`
  fragment specialIssue on SpecialIssue {
    id
    name
    callForPapers
    isActive
    endDate
    customId
  }
`

export const draftManuscriptDetails = gql`
  fragment draftManuscriptDetails on Manuscript {
    id
    journalId
    sourceJournalId
    sourceJournalManuscriptId
    linkedSubmissionCustomId
    isAuthorEmailEditable
    submissionId
    preprintValue
    submittedDate
    submissionSystem
    meta {
      ... on ManuscriptMeta {
        title
        abstract
        agreeTc
        articleTypeId
        dataAvailability
        fundingStatement
        conflictOfInterest
      }
    }
    status
    authors {
      ...teamMember
    }
    files {
      ...fileDetails
    }
    specialIssue {
      ...specialIssue
    }
    section {
      id
      name
    }
  }
  ${specialIssue}
  ${teamMember}
  ${fileDetails}
`

export const activeJournals = gql`
  fragment activeJournals on Journal {
    id
    name
    code
    apc
    eissn
    pissn
    acceptsPhenomSubmissions
    preprints {
      type
      format
    }
    preprintDescription
    articleTypes {
      id
      name
    }
    specialIssues {
      ...specialIssue
    }
    sections {
      id
      name
      customId
      specialIssues {
        ...specialIssue
      }
    }
  }
  ${specialIssue}
`

export const sourceJournals = gql`
  fragment sourceJournals on SourceJournal {
    id
    name
    eissn
    pissn
  }
`

export const articleTypes = gql`
  fragment articleTypes on ArticleType {
    id
    name
  }
`

export const submissionEditorialMapping = gql`
  fragment submissionEditorialMapping on SubmissionEditorialMapping {
    submissionId
    submissionEditorialModel {
      name
      hasTransfers
      hasPreprints
      hasCoverLetter
      hasSupplementalMaterials
      hasAbstract
      hasPrefilledTitle
      hasCOIDeclaration
      hasDataAvailabilityStatement
      hasFundingStatement
      hasTermsAndConditions
      peerReviewModel
    }
  }
`

export const submissionEditorialModel = gql`
  fragment submissionEditorialModel on SubmissionEditorialModel {
    name
    hasTransfers
    hasPreprints
    hasCoverLetter
    hasSupplementalMaterials
    hasAbstract
    hasPrefilledTitle
    hasCOIDeclaration
    hasDataAvailabilityStatement
    hasFundingStatement
    hasTermsAndConditions
    peerReviewModel
  }
`
