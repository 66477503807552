import React, { useEffect } from 'react'
import { Loader, parseSearchParams } from '@hindawi/ui'

const ReviewerRedirect = ({ location, history }) => {
  useEffect(() => {
    const {
      agree,
      email,
      token,
      invitationId,
      fragmentId: manuscriptId,
    } = parseSearchParams(location.search)

    if (agree === 'false') {
      history.replace({
        pathname: `/emails/decline-review`,
        search: `?invitationId=${invitationId}&manuscriptId=${manuscriptId}`,
      })
    } else if (email && token) {
      history.replace({
        pathname: `/emails/accept-review-new-user`,
        search: `?invitationId=${invitationId}&manuscriptId=${manuscriptId}&token=${token}&email=${email}`,
      })
    }
  }, [history, location.search])

  return <Loader />
}

export default ReviewerRedirect
