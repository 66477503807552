import React, { useCallback } from 'react'
import { useApolloClient } from 'react-apollo'
import { useHistory, useLocation } from 'react-router-dom'
import LogRocket from 'logrocket'

import { useKeycloak } from '../../../../component-sso/client'

import { AuthenticatedAppBarContent, UnauthenticatedAppBarContent } from './'
import { useCurrentUser } from '../CurrentUserContext'
import { clearAuthTokens } from '../../utils'

const unauthenticatedPaths = new Set([
  '/eqs-decision',
  '/emails/decline-review',
])

const searchPaths = ['/']

const AppBar = () => {
  const { keycloak, loading } = useKeycloak()
  const currentUser = useCurrentUser()
  const gqlClient = useApolloClient()
  const history = useHistory()
  const location = useLocation()

  const logout = useCallback(() => {
    void gqlClient
      .clearStore()
      .then(() => {
        void keycloak?.logout()
        clearAuthTokens()
        LogRocket.startNewSession()
      })
      .catch(() => {
        void keycloak?.logout()
        clearAuthTokens()
        LogRocket.startNewSession()
      })
  }, [keycloak, gqlClient])

  const goToDashboard = () => {
    history.push('/')
  }

  const goTo = (path) => {
    history.push(path)
  }

  const isUnauthenticated =
    !keycloak?.authenticated || keycloak?.isTokenExpired()
  const isUnauthenticatedPath = unauthenticatedPaths.has(location.pathname)
  if (isUnauthenticatedPath || isUnauthenticated) {
    return <UnauthenticatedAppBarContent />
  }

  const hasAdministrativeRole = currentUser && currentUser.hasAdministrativeRole
  const isSuperAdmin =
    currentUser && currentUser.role === 'admin' && currentUser.isSuperAdmin

  const isStaffMember = ['admin', 'editorialAssistant'].includes(
    currentUser?.role,
  )

  const showSearchButton =
    searchPaths.includes(location.pathname) && !isStaffMember

  return !currentUser || loading ? (
    <UnauthenticatedAppBarContent />
  ) : (
    <AuthenticatedAppBarContent
      currentUser={currentUser}
      goTo={goTo}
      goToDashboard={goToDashboard}
      logout={logout}
      showSearchButton={showSearchButton}
      isSuperAdmin={isSuperAdmin}
      hasAdministrativeRole={hasAdministrativeRole}
    />
  )
}

export default AppBar
