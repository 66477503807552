import { Tooltip, Text } from '@hindawi/phenom-ui'
import React from 'react'
import { Delimiter } from './'

export const CustomIdCell = ({ customId }: { customId: string }) => (
  <div>
    <Text>ID</Text>
    <Tooltip
      copyContent={customId}
      showCopyContent
      replaceTooltipText="Custom ID"
      className="custom-id-value"
    >
      <Delimiter />
      <Text>{customId}</Text>
    </Tooltip>
  </div>
)
