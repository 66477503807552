import React from 'react'
import { space } from 'styled-system'
import styled from 'styled-components'

const StatusTag = ({
  children,
  old = false,
  version,
  statusColor,
  hasVersion,
  ...rest
}) => (
  <Root
    data-test-id="manuscript-status"
    old={old}
    pl={4}
    pr={hasVersion ? 0 : 4}
    statusColor={statusColor}
    {...rest}
  >
    {children}
    {!old && hasVersion && (
      <VersionTag ml={4} statusColor={statusColor}>
        v{version}
      </VersionTag>
    )}
  </Root>
)

export default StatusTag

// #region styles
const getStatusColor = ({ statusColor, theme, old }) =>
  old ? theme.textSecondaryColor : theme[statusColor]

const VersionTag = styled.span`
  align-items: center;
  background-color: ${getStatusColor};
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  color: #f5f5f5;
  display: flex;
  font-family: 'Nunito';
  height: inherit;
  justify-content: center;
  min-width: calc(4px * 6);
  text-transform: lowercase;

  ${space};
`

const Root = styled.div`
  align-items: center;
  background-color: #ffffff;
  border: 1px solid ${getStatusColor};
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  color: ${getStatusColor};
  display: flex;
  height: calc(${(props) => props.height || 6} * 4px);
  font-size: 11px;
  font-family: 'Nunito';
  font-weight: 700;
  text-align: center;
  text-transform: upperCase;
  width: fit-content;
  white-space: nowrap;
  margin-left: 10px;

  ${space};
`
// #endregion
