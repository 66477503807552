import React from 'react'
import { IconWileyLogo, NavBar } from '@hindawi/phenom-ui'
import { useHistory } from 'react-router'

function UnauthenticatedAppBarContent(): React.ReactNode {
  const paths = ['/loggedOut', '/reviewFiles']
  const isRedirectDisabled = paths.some((element) =>
    location.pathname.includes(element),
  )

  const goTo = useHistory().push

  return (
    <NavBar
      logo={
        <IconWileyLogo
          onClick={isRedirectDisabled ? undefined : () => goTo('/')}
          style={{
            cursor: isRedirectDisabled ? 'default' : 'pointer',
          }}
        />
      }
      userControl={<></>}
      actionSection={<></>}
    />
  )
}

export default UnauthenticatedAppBarContent
