import { useState } from 'react'

export type StatusCountT = {
  [status: string]: number
}

export const useStatusesCountState = () => {
  const [statusesManuscriptsCount, setStatusesCount] = useState({})

  return {
    value: statusesManuscriptsCount,
    set: setStatusesCount,
  }
}
