import { useState } from 'react'

export type ValueT = any[]

export const useSuggestionsState = () => {
  const [value, set] = useState([])
  return {
    value,
    set,
  }
}

export const useJournalSuggestionsState = () => useSuggestionsState()
export const useSectionSuggestionsState = () => useSuggestionsState()
export const useSpecialIssueSuggestionsState = () => useSuggestionsState()
export const useManuscriptSuggestionsState = () => useSuggestionsState()
