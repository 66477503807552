import React from 'react'

import { Flex, Text, Popover } from '@hindawi/phenom-ui'
import { AuthorTag } from '.'
import { Author } from '@shared/ui/types'

const AuthorTooltipBody = ({ author }: { author: Author }) => (
  <Flex
    direction="column"
    gap="@spacer-1"
    p="@spacer-3"
    minWidth="260px"
    ml={2}
  >
    <AuthorTag author={author} />
    <Text>{author.alias.email}</Text>
    <Text>{author.alias.aff}</Text>
  </Flex>
)

export function AuthorTagWithTooltip({
  author,
}: {
  author: Author
}): React.ReactNode {
  return (
    <Popover
      arrowPointAtCenter
      content={<AuthorTooltipBody author={author} />}
      placement="bottomLeft"
      showArrow
    >
      <span data-test-id={`author-tag-with-tooltip-${author.id}`}>
        <AuthorTag author={author} />
      </span>
    </Popover>
  )
}
