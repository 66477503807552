import React, { useState } from 'react'
import { Row, Col, Button, Radio, Space, Title } from '@hindawi/phenom-ui'

import { connect } from '../../../store'

import {
  SearchWithAutocomplete,
  InputSearch,
  SearchCategoryWrapper,
  Wrapper,
  Card,
  ManuscriptListingWrapper,
  JournalName,
  ManuscriptTitle,
} from './styles'

import { ManuscriptSuggestion, Suggestion, Option } from '../SuggestionsManager'
import { ManuscriptsList } from '../ManuscriptsList/ManuscriptsList'
import { useWidgetFiltersManager } from '../../../store/EADashboard/widgetFilters'
import { useManuscriptSuggestionsManager } from '../../../store/EADashboard/widgetSuggestions'
import { DashboardWidgetProvider } from '../../../store/EADashboard/index'
import './styles.css'
import { useCurrentUser } from '../../../../../component-authentication/client'

interface SearchManuscriptsWidgetProps {
  editorialAssistantId?: string
}

const SearchManuscriptsWidget: React.FC<SearchManuscriptsWidgetProps> = ({
  editorialAssistantId,
}) => {
  const [searchCategory, setSearchCategory] = useState('id')
  const [widgetExpanded, setWidgetExpanded] = useState(false)
  const [localManuscript, setLocalManuscript] = useState('')
  const [localManuscriptCustomId, setLocalManuscriptCustomId] = useState('')

  const { manuscriptCustomId, manuscriptByTtitle, manuscripts } =
    useWidgetFiltersManager()

  const currentUser = useCurrentUser()

  const input =
    currentUser && currentUser.role === 'admin'
      ? {}
      : {
          editorialAssistantId: currentUser.id,
        }

  const manuscriptsSuggestions = useManuscriptSuggestionsManager(input)

  const resetState = () => {
    manuscriptsSuggestions.reset?.()
    manuscriptByTtitle.reset?.()
    manuscriptCustomId.reset?.()
    setLocalManuscript('')
    setLocalManuscriptCustomId('')
    manuscripts.reset?.()
  }

  const categoryChangeHandler = (e) => {
    setSearchCategory(e.target.value)
    resetState()
  }

  const closeWidget = () => {
    setWidgetExpanded(false)
    resetState()
  }

  const renderLabel = ({
    title,
    journal,
  }: ManuscriptSuggestion): JSX.Element => (
    <Space direction="vertical" size={4}>
      <JournalName type="success">{journal && journal.name}</JournalName>
      <ManuscriptTitle>{title}</ManuscriptTitle>
    </Space>
  )

  const renderExpandedContent = () => {
    const hasManuscript = manuscripts.value.length

    let content = (
      <Space align="center" className="greeter-row" direction="vertical">
        <Title level={2}>
          <div>Nothing to show here. Please type</div>
          <div>or</div>
        </Title>
        <Button onClick={closeWidget} type="secondary">
          Close
        </Button>
      </Space>
    )

    if (hasManuscript || manuscripts.loading) {
      content = (
        <ManuscriptListingWrapper direction="vertical">
          <ManuscriptsList
            limit={1}
            loading={manuscripts.loading ?? false}
            manuscripts={manuscripts.value.map((item) => ({
              ...item,
              expanded: true,
            }))}
          />
          <div className="close-button-wrapper">
            <Button onClick={closeWidget} type="secondary">
              Close
            </Button>
          </div>
        </ManuscriptListingWrapper>
      )
    }

    return content
  }

  const handleManuscriptSelect = (entry: Option) => {
    manuscriptByTtitle.set(entry)
    setLocalManuscript(entry.displayValue)
  }

  const handleManuscriptCustomIdSearch = () => {
    manuscriptCustomId.set(localManuscriptCustomId)
  }

  return (
    <Wrapper className={widgetExpanded ? 'widgetExpanded' : undefined}>
      <Card className="widget-content">
        <Row gutter={[12, 12]}>
          <Col flex="325px">
            <SearchCategoryWrapper>
              <span>Find manuscript by</span>
              <Radio.Group
                buttonStyle="solid"
                defaultValue="id"
                onChange={categoryChangeHandler}
                size="small"
                value={searchCategory}
              >
                <Radio.Button value="id">ID</Radio.Button>
                <Radio.Button value="title">Title</Radio.Button>
              </Radio.Group>
            </SearchCategoryWrapper>
          </Col>
          <Col flex="auto">
            {searchCategory === 'title' ? (
              // @ts-ignore
              <SearchWithAutocomplete
                dropdownClassName="manuscripts-suggestions"
                getSuggestions={manuscriptsSuggestions.fetch ?? (() => {})}
                loading={manuscriptsSuggestions.loading}
                onChange={setLocalManuscript}
                onFocus={() => setWidgetExpanded(true)}
                placeholder="Search by title"
                renderDisplayValue={({ title }: ManuscriptSuggestion) => title}
                renderLabel={renderLabel}
                renderValue={({ id }: Suggestion) => id}
                selectSuggestion={handleManuscriptSelect}
                suggestions={manuscriptsSuggestions.value}
                value={localManuscript}
              />
            ) : (
              <InputSearch
                enterButton
                onChange={(e) => setLocalManuscriptCustomId(e.target.value)}
                onFocus={() => setWidgetExpanded(true)}
                onSearch={handleManuscriptCustomIdSearch}
                placeholder="Search by ID"
                value={localManuscriptCustomId}
              />
            )}
          </Col>
        </Row>
        {widgetExpanded && renderExpandedContent()}
      </Card>
    </Wrapper>
  )
}

export default connect(DashboardWidgetProvider, SearchManuscriptsWidget)
