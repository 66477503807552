// @ts-nocheck
import { useContext } from 'react'
import { SuggestionsContext, DashboardWidgetContext } from '../'
import { useSuggestionsManager } from './useSuggestionsManager'
import { ManagerApiI } from '../../'
import { ValueT } from './state'

interface useSuggestionManagerProps {
  editorialAssistantId?: string
  journalId?: string
  sectionId?: string
}

type useSuggestionManagerT = (
  props?: useSuggestionManagerProps,
) => ManagerApiI<ValueT, string>

export const useJournalSuggestionsManager: useSuggestionManagerT = (params) => {
  const context = useContext(SuggestionsContext).journalSuggestions
  const query = 'getJournalsSuggestions'

  const inputResolver = (searchTerm: string) => ({
    name: searchTerm,
    ...params,
  })

  return useSuggestionsManager({
    context,
    query,
    inputResolver,
  })
}

export const useSectionSuggestionsManager: useSuggestionManagerT = (params) => {
  const context = useContext(SuggestionsContext).sectionSuggestions
  const query = 'getSectionsSuggestions'

  const inputResolver = (searchTerm: string) => ({
    name: searchTerm,
    ...params,
  })

  return useSuggestionsManager({
    context,
    query,
    inputResolver,
  })
}

export const useSpecialIssueSuggestionsManager: useSuggestionManagerT = (
  params,
) => {
  const context = useContext(SuggestionsContext).specialIssueSuggestions
  const query = 'getSpecialIssuesSuggestions'

  const inputResolver = (searchTerm: string) => ({
    name: searchTerm,
    ...params,
  })

  return useSuggestionsManager({
    context,
    query,
    inputResolver,
  })
}

export const useManuscriptSuggestionsManager: useSuggestionManagerT = (
  params,
) => {
  const context = useContext(DashboardWidgetContext).manuscriptSuggestions
  const query = 'getManuscriptsV2'

  const inputResolver = (searchTerm: string) => ({
    name: searchTerm,
    ...params,
  })

  return useSuggestionsManager({
    context,
    query,
    inputResolver,
  })
}
