import React from 'react'
import { DateService, FormatType } from '../../component-date-service'

/**
 *
 * @deprecated please use `useDateParser` hook from @shared/ui/hooks instead
 */
export const DateParser = (data: {
  children: (timestampToRender: string, timestampAgo: string) => React.ReactNode
  timestamp: string
  humanizeThreshold?: number
  format?: FormatType
}) => {
  const defaultHumanizeThreshold = 0
  const { children, timestamp, humanizeThreshold, format } = data

  return children(
    DateService.getTimeStampToRender({
      timestamp,
      humanizeThreshold: humanizeThreshold || defaultHumanizeThreshold,
      format,
    }),
    DateService.getTimeAgo({ timestamp }),
  )
}
