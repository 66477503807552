import React from 'react'
import { Row, Text, Icon } from '@hindawi/ui'
import { Modal } from './Modal'
import { Button, Preset, Title } from '@hindawi/phenom-ui'

export const InformationModal = ({ icon, title, error, toggle }) => (
  <Modal toggle={toggle}>
    <Row alignItems="start">
      {icon && (
        <Icon
          fontSize="48px"
          icon={icon}
          lineHeight="48px"
          mr={7}
          reverse
          warning
        />
      )}
      <Row alignItems="start" flexDirection="column">
        <Title preset={Preset.PRIMARY}>{title}</Title>
        {error && (
          <Text color={'#FC6A4B'} mt={2}>
            {error}
          </Text>
        )}
      </Row>
    </Row>
    <Row mt={12}>
      <Button
        style={{
          marginRight: 3,
          width: 25,
        }}
        onClick={toggle}
        type="primary"
      >
        OK
      </Button>
    </Row>
  </Modal>
)
