import React from 'react'

import { Modal, Row, Title } from '@hindawi/phenom-ui'
import { usePaginatedLazyQuery } from './hooks'

import {
  ModalDivider,
  SearchInput,
  SearchResults,
  TopSection,
} from './components'
import { PAGE_SIZE } from './constants'

export function SearchModal({
  isModalVisible,
  setIsModalVisible,
}: {
  isModalVisible: boolean
  setIsModalVisible: (value: boolean) => void
}) {
  const {
    showResults,
    manuscripts,
    loading,
    handleSearch,
    page,
    handleChangePage,
    totalManuscripts,
    setPage,
  } = usePaginatedLazyQuery()

  return (
    <Modal
      data-test-id="search-modal"
      width="auto"
      footer={null}
      closable={true}
      open={isModalVisible}
      onCancel={() => setIsModalVisible(false)}
      style={{
        position: 'fixed',
        inset: '32px 80px',
        maxWidth: '100%',
        paddingBottom: '0',
      }}
    >
      <div
        style={{
          maxHeight: '90vh',
        }}
      >
        <TopSection>
          <Row>
            <Title preset="primary">Search manuscript</Title>
          </Row>
          <Row
            style={{
              flexWrap: 'nowrap',
              alignItems: 'start',
              gap: '32px',
            }}
          >
            <SearchInput
              onSearch={(input: string) => {
                handleSearch(input)
                setPage(1)
              }}
            />
          </Row>
        </TopSection>
        <ModalDivider />
        <SearchResults
          totalManuscripts={totalManuscripts}
          page={page}
          PageSize={PAGE_SIZE}
          setIsModalVisible={setIsModalVisible}
          loading={loading}
          manuscripts={manuscripts}
          showResults={showResults}
          handleChangePage={handleChangePage}
        />
      </div>
    </Modal>
  )
}
