import React from 'react'
import { TechnicalError } from '@hindawi/phenom-ui'

export const TechnicalErrorPage = () => {
  const goToDashboard = () => (window.location.pathname = '/')
  return (
    <TechnicalError
      supportEmail={'reviewhelp@wiley.com'}
      actionButton={{
        name: 'GO TO DASHBOARD',
        action: goToDashboard,
      }}
    />
  )
}
