import { BIN_CATEGORY_FILTER_MAP, ORDER_CATEGORY_FILTER_MAP } from './constants'
import {
  BinCategory,
  BinWithCount,
  OrderCategory,
  Permission,
  PermissionWithCount,
  StatusWithFilter,
} from './types'

export const findVariablesForOrder = (order: OrderCategory) => {
  const orderVariables = ORDER_CATEGORY_FILTER_MAP[order]
  if (!orderVariables) {
    return { orderBy: undefined, orderDirection: undefined }
  }
  return orderVariables
}

const calculateRange = ({
  page,
  totalCount,
  pageSize,
  currentCount,
}: Record<string, number>): {
  start: number
  end: number
} => {
  if (currentCount === 0) {
    return {
      start: 0,
      end: 0,
    }
  }
  if (currentCount === totalCount) {
    return {
      start: 1,
      end: totalCount,
    }
  }

  if (pageSize * page > totalCount) {
    return {
      start: pageSize * (page - 1) + 1,
      end: totalCount,
    }
  }

  return {
    start: pageSize * (page - 1) + 1,
    end: pageSize * page,
  }
}

export const getResultsTopDescription = ({
  total,
  page,
  pageSize,
  currentCount,
}) => {
  if (!currentCount) {
    return ''
  }

  const { start, end } = calculateRange({
    page,
    totalCount: total,
    pageSize,
    currentCount,
  })

  return `Showing ${
    start === end ? `manuscript: ${start}` : `manuscripts: ${start}–${end}`
  } of ${total}`
}

export const getViewerCount = (
  availableStatuses: StatusWithFilter[],
  viewerAvailableStatuses: StatusWithFilter[],
): (() => PermissionWithCount) => {
  return () => {
    return {
      Editor: availableStatuses
        .map((availableStatus) => {
          return availableStatus.count
        })
        .reduce((acc, prev) => prev + acc, 0),
      Viewer: viewerAvailableStatuses
        .map((viewerAvailableStatus) => {
          return viewerAvailableStatus.count
        })
        .reduce((acc, prev) => prev + acc, 0),
    } satisfies Record<keyof typeof Permission, number>
  }
}

export const getCountByBin = (
  availableStatuses: StatusWithFilter[],
): (() => BinWithCount) => {
  return () => {
    const binsWithCount = {
      ActionRequired: 0,
      InProgress: 0,
      Finalized: 0,
      All: 0,
    } satisfies Record<keyof typeof BIN_CATEGORY_FILTER_MAP, number>

    Object.keys(binsWithCount)
      .filter((bin) => bin !== BinCategory.All)
      .forEach((bin) => {
        binsWithCount[bin] = availableStatuses
          .filter(
            (availableStatus) =>
              availableStatus.statusFilter === BIN_CATEGORY_FILTER_MAP[bin],
          )
          .map((availableStatus) => {
            return availableStatus.count
          })
          .reduce((acc, prev) => prev + acc, 0)
      })

    binsWithCount['All'] = Object.keys(binsWithCount)
      .map((binKey) => binsWithCount[binKey])
      .reduce((acc, prev) => prev + acc, 0)

    return binsWithCount
  }
}

export const addAllToStatuses = (statuses: string[]) => ['All', ...statuses]
