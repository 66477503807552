import React, { useCallback, useEffect, useState } from 'react'

import KeycloakContext, { KeycloakT } from './KeycloakContext'
import { useQuery } from 'react-apollo'
import { queries } from '../../component-authentication/client/graphql'
import CurrentUserContext from '../../component-authentication/client/components/CurrentUserContext'

function KeycloakProvider({ children }) {
  const [{ keycloak, currentUser, currentUserLoading }, setState] = useState<{
    keycloak: KeycloakT
    currentUser: any
    currentUserLoading: boolean
  }>({
    keycloak: null,
    currentUser: null,
    currentUserLoading: true,
  })

  const setKeycloak = useCallback(
    (keycloakOrFn) => {
      const keycloak =
        typeof keycloakOrFn === 'function' ? keycloakOrFn() : keycloakOrFn
      setState((state) => ({
        ...state,
        keycloak,
      }))
    },
    [setState],
  )

  const { loading, data } = useQuery(queries.currentUser, {
    fetchPolicy: 'no-cache',
    skip: !keycloak || !keycloak?.authenticated || keycloak.isTokenExpired(),
  })
  useEffect(() => {
    if (data?.currentUser) {
      setState((state) => ({
        ...state,
        currentUser: data?.currentUser,
        currentUserLoading: loading || false,
      }))
    }
  }, [data, setState])

  return (
    <KeycloakContext.Provider
      value={{ keycloak, loading: currentUserLoading, setKeycloak }}
    >
      <CurrentUserContext.Provider value={currentUser}>
        {children}
      </CurrentUserContext.Provider>
    </KeycloakContext.Provider>
  )
}

export default KeycloakProvider
