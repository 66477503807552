import React from 'react'
import { compose, lifecycle, withState } from 'recompose'
import { Row, Text, ShadowedBox } from '@hindawi/ui'
import { useJournal } from '../../../component-journal-info'
import { Button, Spinner, Title } from '@hindawi/phenom-ui'

import { parseSearchParams } from '../utils'
import withAuthenticationGQL from '../graphql'

const loading = `Loading...`
const confirmSubtitle = `Your account has been successfully confirmed.`
const errorTitle = `Something went wrong. Please try again.`

const ConfirmAccount = ({ message: { title, subtitle }, history }) => {
  const { name } = useJournal()
  const confirmTitle = `Welcome to ${name}!`
  return (
    <ShadowedBox center mt={5}>
      <Title level={4} style={{ textAlign: 'center' }}>
        {title !== errorTitle ? confirmTitle : errorTitle}
      </Title>
      <Row mb={title !== errorTitle ? 2 : 0} mt={2}>
        <Text>{subtitle}</Text>
      </Row>
      {title !== errorTitle && (
        <Row>
          {title === loading ? (
            <Spinner />
          ) : (
            <Button onClick={() => history.replace('/')} type="primary">
              Go to Dashboard
            </Button>
          )}
        </Row>
      )}
    </ShadowedBox>
  )
}

export default compose(
  withAuthenticationGQL,
  withState('message', 'setConfirmMessage', {
    title: loading,
  }),
  lifecycle({
    componentDidMount() {
      const { location, confirmUser, setConfirmMessage } = this.props
      const { confirmationToken, userId } = parseSearchParams(location.search)
      confirmUser({
        variables: {
          input: {
            userId,
            token: confirmationToken,
          },
        },
      })
        .then(() => {
          setConfirmMessage({
            subtitle: confirmSubtitle,
          })
        })
        .catch((err) => {
          setConfirmMessage({
            title: errorTitle,
            subtitle: err.message,
          })
        })
    },
  }),
)(ConfirmAccount)
