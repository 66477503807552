import { useState, useCallback } from 'react'

export function useSearch(initialSearch = '') {
  const [searchQuery, setSearchQuery] = useState(initialSearch)

  const clearSearch = useCallback(() => {
    setSearchQuery('')
  }, [])

  const changeSearch = useCallback((e) => {
    setSearchQuery(e.target.value)
  }, [])

  return { searchQuery, clearSearch, changeSearch }
}
