import { useCallback, useEffect, useState } from 'react'

import { get } from 'lodash'
import { useLazyQuery } from 'react-apollo'
import { searchManuscripts } from '../../../../../../component-dashboard/client/graphql/queries'
import { PaginatedLazyQuery } from '../types'
import { PAGE_SIZE } from '../constants'

export const usePaginatedLazyQuery = (): PaginatedLazyQuery => {
  const [searchValue, setSearchValue] = useState<string>('')
  const [page, setPage] = useState<number>(1)

  const handleChangePage = (pageNumber: number) => {
    setPage(pageNumber)
  }

  const [searchManuscriptsQuery, { data, loading }] = useLazyQuery(
    searchManuscripts,
    { fetchPolicy: 'network-only' },
  )

  const handleSearch = useCallback(
    (value: string = searchValue) => {
      const getQueryInput = () => ({
        variables: {
          input: {
            searchValue: value,
            page: page - 1,
            pageSize: PAGE_SIZE,
          },
        },
      })
      const queryInput = getQueryInput()
      setSearchValue(value)
      searchManuscriptsQuery(queryInput)
    },
    [searchManuscriptsQuery, page, searchValue],
  )

  useEffect(() => {
    handleSearch()
  }, [page, searchValue, handleSearch])

  const manuscripts = get(data, 'searchManuscripts.manuscripts', [])
  const totalManuscripts = get(data, 'searchManuscripts.totalCount', 0)

  return {
    handleChangePage,
    page,
    manuscripts,
    handleSearch,
    loading,
    showResults: !loading && data,
    totalManuscripts,
    setPage,
  }
}
