import React, { Fragment } from 'react'
import { isNumber } from 'lodash'
import styled from 'styled-components'
import { compose, withState, withHandlers } from 'recompose'
import { Title, Preset, Spinner } from '@hindawi/phenom-ui'

import { withFetching } from './helpers'
import AuthorCardEdit from './AuthorCardEdit'
import { Row, Tag, Icon, Item, PersonInfo, DragHandle } from '../'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

const Author = ({
  item,
  index,
  isFetching,
  toggleEdit,
  deleteAuthor,
  isSubmitting,
  isEditingForm,
  isCorresponding,
}) => (
  <Fragment>
    <Row justify="space-between" mb={1}>
      <Item justify="flex-start">
        <Title preset={Preset.SMALL}>
          {isNumber(index) ? `#${index + 1} Author` : 'Author'}
        </Title>
        <Fragment>
          {isSubmitting && <Tag ml={1}>SUBMITTING</Tag>}
          {isCorresponding && <Tag ml={1}>CORRESPONDING</Tag>}
        </Fragment>
      </Item>
      {isFetching ? (
        <StyledSpinner>
          <Spinner size="small" />
        </StyledSpinner>
      ) : (
        !isEditingForm && (
          <Fragment>
            {!isSubmitting && (
              <Icon
                color="colorSecondary"
                data-test-id={`delete-author-${index}`}
                fontSize="16px"
                icon="delete"
                mr={3}
                onClick={deleteAuthor}
              />
            )}
            <Icon
              color="colorSecondary"
              data-test-id={`edit-author-${index}`}
              fontSize="16px"
              icon="edit"
              onClick={toggleEdit}
            />
          </Fragment>
        )
      )}
    </Row>
    <PersonInfo person={item} />
  </Fragment>
)

const AuthorCard = ({
  item,
  index,
  onEdit,
  editMode,
  isFetching,
  cancelEdit,
  saveAuthor,
  toggleEdit,
  isSubmitting,
  deleteAuthor,
  fetchingError,
  isCorresponding,
  setCorresponding,
  isEditingForm,
  authorsLength,
  isAuthorEmailEditable,
}) => {
  const { setNodeRef, transform, transition, attributes, listeners } =
    useSortable({
      id: item.id,
    })
  const style = {
    transform: CSS.Transform.toString({
      scaleX: 1,
      scaleY: 1,
      x: transform?.x || 0,
      y: transform?.y || 0,
    }),
    transition,
  }
  return (
    <Root
      data-test-id={`author-${item.id}-card`}
      style={!isEditingForm ? style : undefined}
      ref={setNodeRef}
    >
      {!isEditingForm && <DragHandle {...listeners} {...attributes} />}
      <AuthorContainer>
        {editMode ? (
          <AuthorCardEdit
            cancelEdit={cancelEdit}
            deleteAuthor={deleteAuthor(item, index)}
            fetchingError={fetchingError}
            index={isEditingForm ? authorsLength - 1 : index}
            isAuthorEmailEditable={isAuthorEmailEditable}
            isFetching={isFetching}
            item={item}
            onEdit={onEdit}
            saveAuthor={saveAuthor}
            setCorresponding={setCorresponding}
          />
        ) : (
          <Author
            deleteAuthor={deleteAuthor(item, index)}
            index={isEditingForm ? index - 1 : index}
            isCorresponding={isCorresponding}
            isEditingForm={isEditingForm}
            isFetching={isFetching}
            isSubmitting={isSubmitting}
            item={item}
            toggleEdit={toggleEdit}
          />
        )}
      </AuthorContainer>
    </Root>
  )
}

export default compose(
  withFetching,
  withState(
    'editMode',
    'setEditMode',
    ({ isEditingForm = false }) => isEditingForm,
  ),
  withHandlers({
    toggleEdit:
      ({ index, editMode, setEditMode, onEdit }) =>
      () => {
        setEditMode(!editMode)
        if (typeof onEdit === 'function') {
          onEdit(index)
        }
      },
    cancelEdit:
      ({ index, setEditMode, clearError, onCancelEdit }) =>
      () => {
        setEditMode(false)
        clearError()
        if (typeof onCancelEdit === 'function') {
          onCancelEdit(index)
        }
      },
    deleteAuthor:
      ({ onDeleteAuthor, ...props }) =>
      (author, index) =>
      () => {
        if (typeof onDeleteAuthor === 'function') {
          onDeleteAuthor(author, { ...props, index })
        }
      },
    saveAuthor:
      ({ id, onSaveAuthor, ...props }) =>
      (index) =>
      (values) => {
        if (typeof onSaveAuthor === 'function') {
          onSaveAuthor({ id, ...values }, { ...props, index })
        }
      },
  }),
)(AuthorCard)

// #region styles
const AuthorContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: calc(4px * 4);
`

const Root = styled.div`
  align-items: center;
  background-color: ${(props) => (props.isOver ? '#DBDBDB' : 'white')};
  border-radius: 4px;
  box-shadow: 0 1px 2px 1px #dbdbdb;
  display: flex;
  justify-content: flex-start;
  margin-bottom: calc(4px * 2);
  position: relative;
  width: 100%;
`

const StyledSpinner = styled.div`
  position: absolute;
  right: calc(4px * 6);
  top: calc(4px) * 4;
`
// #endregion
