import React from 'react'
import ReactDOM from 'react-dom'
import { Row, Icon } from '@hindawi/ui'
import styled from 'styled-components'

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 400;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);

  display: flex;
  align-items: center;
  justify-content: center;
`

const ModalRoot = styled(Row)`
  position: relative;
  width: 567px;
  border-radius: 4px;
  box-shadow: 3px 4px 10px 1px rgba(51, 51, 51, 0.18);
  background-color: white;
`

export const Modal = ({ children, toggle }) => {
  const handleClickOutside = (event) => {
    event.stopPropagation()
    toggle()
  }
  const handleClickInside = (event) => event.stopPropagation()

  return ReactDOM.createPortal(
    <ModalOverlay onClick={handleClickOutside}>
      <ModalRoot flexDirection="column" onClick={handleClickInside} p={10}>
        <Icon
          color={'#4F4F4F'}
          fontSize="16px"
          icon="expand"
          inclined
          lineHeight="16px"
          onClick={toggle}
          p={4}
          position="absolute"
          right={0}
          top={0}
        />
        {children}
      </ModalRoot>
    </ModalOverlay>,
    document.getElementById('ps-modal-root'),
  )
}
