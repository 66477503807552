import React from 'react'
import styled from 'styled-components'
import { compose, withStateHandlers } from 'recompose'
import { Icon } from '..'

const Dropdown = ({ toggleDropdown, expanded, children }) => (
  <Root>
    <Icon fontSize="16px" icon="moreDefault" onClick={toggleDropdown} />
    {expanded && (
      <DropdownOptionsWrapper data-test-id="user-panel-dropdown">
        {children}
      </DropdownOptionsWrapper>
    )}
    {expanded && <ToggleOverlay onClick={toggleDropdown} />}
  </Root>
)

const Root = styled.div`
  position: relative;
`

const ToggleOverlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
`

export const DropdownOption = styled.div.attrs((props) => ({
  'data-test-id': props['data-test-id'] || 'dropdown-option',
}))`
  align-items: center;
  color: #4f4f4f;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;

  font-family: 'Nunito';
  font-size: 14px;
  line-height: 18px;

  height: calc(4px * 8);
  padding: calc(4px * 2);

  &:hover {
    background-color: rgba(0, 126, 146, 0.1);
  }
`

const DropdownOptionsWrapper = styled.div.attrs((props) => ({
  'data-test-id': props['data-test-id'] || 'admin-dropdown',
}))`
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 1px 2px 1px #dbdbdb;

  position: absolute;
  right: 0px;
  width: calc(4px * 36);
  z-index: 10;
`

Dropdown.DropdownOption = DropdownOption

export default compose(
  withStateHandlers(
    { expanded: false },
    {
      toggleDropdown:
        ({ expanded }) =>
        () => ({ expanded: !expanded }),
    },
  ),
)(Dropdown)
