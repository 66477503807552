import React, { Fragment } from 'react'
import { Formik } from 'formik'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { compose, setDisplayName, withHandlers } from 'recompose'
import { Spinner, Title } from '@hindawi/phenom-ui'

import { Text, Row, Icon, withFetching } from '../../'
import { Button } from '@hindawi/phenom-ui'

const FormModal = ({
  title,
  onClose,
  content,
  onSubmit,
  subtitle,
  isFetching,
  cancelText,
  confirmText,
  initialValues,
  fetchingError,
  renderContent,
  isCurrentUser,
  validate,
  customSubmitHandler,
}) => (
  <Root>
    <Icon
      color="#939393"
      fontSize="24px"
      icon="remove1"
      onClick={(event) => {
        event.stopPropagation()
        onClose()
      }}
      right={8}
      secondary
      top={12}
    />
    <Title level={4} style={{ textAlign: 'center' }}>
      {title}
    </Title>
    {subtitle && (
      <Text mb={2} secondary>
        {subtitle}
      </Text>
    )}
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
    >
      {({ handleSubmit, ...formProps }) => {
        const confirmOnClickHandler = customSubmitHandler
          ? customSubmitHandler(formProps)
          : (event) => {
              event.stopPropagation()
              handleSubmit()
            }
        return (
          <Fragment>
            {renderContent(formProps)}
            {fetchingError && (
              <Text align="center" error>
                {fetchingError}
              </Text>
            )}

            <Row justify="center" mt={isCurrentUser ? 5 : 10}>
              {isFetching ? (
                <Spinner size="small" />
              ) : (
                <Fragment>
                  <Button
                    data-test-id="modal-cancel"
                    onClick={(event) => {
                      event.stopPropagation()
                      onClose()
                    }}
                    style={{
                      width: '124px',
                      marginRight: '2px',
                    }}
                    type="primary"
                  >
                    {cancelText}
                  </Button>
                  <Button
                    data-test-id="modal-confirm"
                    onClick={confirmOnClickHandler}
                    style={{
                      width: '124px',
                      marginLeft: '2px',
                    }}
                    type="primary"
                  >
                    {confirmText}
                  </Button>
                </Fragment>
              )}
            </Row>
          </Fragment>
        )
      }}
    </Formik>
  </Root>
)

FormModal.propTypes = {
  /** Title that will be showed on the card. */
  title: PropTypes.string,
  /** Subtitle that will be showed on the card. */
  subtitle: PropTypes.string,
  /** The text you want to see on the button when someone submit the report. */
  confirmText: PropTypes.string,
  /** The text you want to see on the button when someone cancel the report. */
  cancelText: PropTypes.string,
  /** Callback function fired when cancel confirmation card. */
  onCancel: PropTypes.func, // eslint-disable-line
  /** Callback function fired when confirm confirmation card. */
  onSubmit: PropTypes.func,
  /** When is true will show a spinner.  */
  onClose: PropTypes.func,
  /** Callback function fired when you want to close the card. */
  isFetching: PropTypes.bool,
  /** The component you want to show on the card. */
  content: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
}

FormModal.defaultProps = {
  title: '',
  confirmText: 'SUBMIT',
  cancelText: 'CANCEL',
  subtitle: '',
  onCancel: () => {},
  onSubmit: () => {},
  onClose: () => {},
  isFetching: false,
  content: () => {},
}

export default compose(
  withFetching,
  withHandlers({
    onSubmit:
      ({ onSubmit, ...props }) =>
      (values) => {
        if (typeof onSubmit === 'function') {
          onSubmit(values, props)
        } else {
          props.hideModal()
        }
      },
    onClose:
      ({ onCancel, ...props }) =>
      () => {
        if (typeof onCancel === 'function') {
          onCancel(props)
        }
        props.hideModal()
      },
    renderContent:
      ({ content, ...props }) =>
      (formProps) => {
        if (!content) return null
        if (typeof content === 'object') {
          return content
        } else if (typeof content === 'function') {
          return content({ ...props, formProps })
        }
      },
  }),
  setDisplayName('FormModal'),
)(FormModal)

const Root = styled.div`
  align-items: center;
  background: #ffffff;
  border: none;
  border-radius: 4px;
  box-shadow: 0 1px 2px 1px #dbdbdb;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: calc(4px * 10);
  width: calc(4px * 154);
`
