import gql from 'graphql-tag'
import { fragments as fileFragments } from '../../../component-files/client'

import {
  draftManuscriptDetails,
  teamMember,
  submissionEditorialMapping,
} from './fragments'

export const createDraftManuscript = gql`
  mutation createDraftManuscript($input: CreateDraftManuscriptInput) {
    createDraftManuscript(input: $input) {
      ...draftManuscriptDetails
    }
  }
  ${draftManuscriptDetails}
`

export const updateDraftManuscript = gql`
  mutation updateDraftManuscript(
    $manuscriptId: String!
    $autosaveInput: DraftAutosaveInput
  ) {
    updateDraftManuscript(
      manuscriptId: $manuscriptId
      autosaveInput: $autosaveInput
    ) {
      ...draftManuscriptDetails
    }
  }
  ${draftManuscriptDetails}
`

export const addAuthorToManuscript = gql`
  mutation addAuthorToManuscript(
    $manuscriptId: String!
    $authorInput: AuthorInput!
  ) {
    addAuthorToManuscript(
      manuscriptId: $manuscriptId
      authorInput: $authorInput
    ) {
      ...teamMember
    }
  }
  ${teamMember}
`

export const editAuthorFromManuscript = gql`
  mutation editAuthorFromManuscript(
    $manuscriptId: String!
    $authorTeamMemberId: String!
    $authorInput: AuthorInput!
  ) {
    editAuthorFromManuscript(
      manuscriptId: $manuscriptId
      authorTeamMemberId: $authorTeamMemberId
      authorInput: $authorInput
    ) {
      ...teamMember
    }
  }
  ${teamMember}
`

export const removeAuthorFromManuscript = gql`
  mutation removeAuthorFromManuscript(
    $manuscriptId: String!
    $authorTeamMemberId: String!
  ) {
    removeAuthorFromManuscript(
      manuscriptId: $manuscriptId
      authorTeamMemberId: $authorTeamMemberId
    ) {
      ...teamMember
    }
  }
  ${teamMember}
`

export const updateAutosave = gql`
  mutation updateAutosave($params: AutosaveInput) {
    updateAutosave(params: $params) @client
  }
`

export const addFileToManuscript = gql`
  mutation addFileToManuscript(
    $manuscriptId: String!
    $fileInput: FileInput
    $file: Upload!
  ) {
    addFileToManuscript(
      manuscriptId: $manuscriptId
      fileInput: $fileInput
      file: $file
    ) {
      ...fileDetails
    }
  }
  ${fileFragments.fileDetails}
`

export const removeFileFromManuscript = gql`
  mutation removeFileFromManuscript($fileId: String!) {
    removeFileFromManuscript(fileId: $fileId)
  }
`

export const updateManuscriptFile = gql`
  mutation updateManuscriptFile($fileId: String!, $type: String!) {
    updateManuscriptFile(fileId: $fileId, type: $type) {
      ...fileDetails
    }
  }
  ${fileFragments.fileDetails}
`

export const getFileSignedUrl = gql`
  mutation getFileSignedUrl($fileId: String!) {
    getFileSignedUrl(fileId: $fileId)
  }
`

export const submitManuscript = gql`
  mutation submitManuscript($manuscriptId: String!) {
    submitManuscript(manuscriptId: $manuscriptId)
  }
`
export const editManuscript = gql`
  mutation editManuscript($manuscriptId: String!) {
    editManuscript(manuscriptId: $manuscriptId)
  }
`

export const setSubmissionEditorialMapping = gql`
  mutation setSubmissionEditorialMapping(
    $submissionId: String!
    $submissionEditorialModel: SubmissionEditorialModelInput!
  ) {
    setSubmissionEditorialMapping(
      submissionId: $submissionId
      submissionEditorialModel: $submissionEditorialModel
    ) {
      ...submissionEditorialMapping
    }
  }
  ${submissionEditorialMapping}
`
