import React from 'react'
import { Divider } from '@hindawi/phenom-ui'

// FIXME Should be rewritten without inline styled using <Row /> and <Col /> components
export const ModalDivider = () => (
  <Divider
    type="horizontal"
    style={{
      margin: '24px -40px 16px',
      width: 'auto',
    }}
  />
)
