// @ts-nocheck
import React from 'react'

type ProviderT = React.FC | React.FC[]

const addProvider = (CurrentComponent, CurrentWrapper: React.FC) => (
  <CurrentWrapper>{CurrentComponent}</CurrentWrapper>
)

export const connect = (Provider: ProviderT, Component) => (props) => {
  if (Array.isArray(Provider)) {
    return Provider.reduce(addProvider, <Component {...props} />)
  }
  return addProvider(<Component {...props} />, Provider)
}
