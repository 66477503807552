type LocalStorageAPIFn<Response> = () => Response

type getFn = (key: string) => any
type setFn = (key: string, value: any) => void

type LocalStoragePublicAPI = {
  get: getFn
  set: setFn
}

export const localStorageService: LocalStorageAPIFn<
  LocalStoragePublicAPI
> = () => {
  const get: getFn = (key) =>
    localStorage[key] ? JSON.parse(localStorage[key]) : null
  const set: setFn = (key, value) => {
    localStorage[key] = JSON.stringify(value)
  }

  return { get, set }
}
