import { useState } from 'react'

export type ValueT = any[]

export const useManuscriptsState = () => {
  const [manuscripts, setManuscripts] = useState([])
  const [totalCount, setTotalCount] = useState(0)

  const entries = {
    value: manuscripts,
    set: setManuscripts,
  }

  const total = {
    value: totalCount,
    set: setTotalCount,
  }

  return {
    entries,
    total,
  }
}
