import gql from 'graphql-tag'

import { fileDetails } from './fragments'

export const uploadFile = gql`
  mutation uploadFile(
    $entityId: String!
    $fileInput: FileInput
    $providerKey: String!
  ) {
    uploadFile(
      entityId: $entityId
      fileInput: $fileInput
      providerKey: $providerKey
    ) {
      ...fileDetails
    }
  }
  ${fileDetails}
`

export const getSignedUrl = gql`
  mutation getSignedUrl($fileId: String!) {
    getSignedUrl(fileId: $fileId)
  }
`

export const getUploadSignedUrl = gql`
  mutation getUploadSignedUrl($entityId: String!) {
    getUploadSignedUrl(entityId: $entityId) {
      url
      fields {
        key
        fileId
        bucket
        X_Amz_Algorithm
        X_Amz_Credential
        X_Amz_Date
        X_Amz_Security_Token
        Policy
        X_Amz_Signature
      }
    }
  }
`

export const deleteFile = gql`
  mutation deleteFile($fileId: String!) {
    deleteFile(fileId: $fileId)
  }
`
