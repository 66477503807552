import { ROUTES } from '@shared/ui/constants'
import { useHasAccessToDashboard } from '@shared/ui/hooks'
import React, { ReactNode } from 'react'
import { Redirect } from 'react-router-dom'
import {
  FiltersCard,
  FiltersSection,
  ResultsSection,
  ResultsSectionContainer,
  Root,
} from './components'
import { DEFAULT_ITEM_PER_PAGE } from './constants'
import { usePaginatedLazyQuery } from './hooks'

export function Dashboard(): ReactNode {
  const hasAccessTo = useHasAccessToDashboard()
  const {
    getStatuses,
    bin,
    chosenStatus,
    handleChangePage,
    loading,
    manuscripts,
    onChangeBin,
    onChangeOrder,
    onChangeStatus,
    chosenOrder,
    page,
    showResults,
    totalManuscripts,
    getCountByBin,
    resultsTopDescription,
    hasViewerPermission,
    getCountByManuscriptPermission,
    manuscriptPermission,
    onChangeManuscriptPermission,
  } = usePaginatedLazyQuery()

  const statuses = getStatuses()

  if (hasAccessTo('adminDashboard')) {
    return <Redirect push to={ROUTES.ADMIN_SEARCH_DASHBOARD} />
  }

  if (hasAccessTo('editorialAssistantDashboard')) {
    return <Redirect push to={ROUTES.EA_DASHBOARD} />
  }

  const noManuscripts = manuscripts.length

  return (
    <>
      <Root data-test-id="dashboard-list-items">
        <FiltersSection data-test-id="editorial-dashboard-filter-section">
          <FiltersCard
            bin={bin}
            chosenStatus={chosenStatus}
            onChangeBin={onChangeBin}
            onChangeOrder={onChangeOrder}
            onChangeStatus={onChangeStatus}
            chosenOrder={chosenOrder}
            statuses={statuses}
            getCountByBin={getCountByBin}
            getCountByManuscriptPermission={getCountByManuscriptPermission}
            hasViewerPermission={hasViewerPermission}
            manuscriptPermission={manuscriptPermission}
            onChangeManuscriptPermission={onChangeManuscriptPermission}
          />
        </FiltersSection>

        <ResultsSectionContainer data-test-id="editorial-dashboard-results-section">
          <ResultsSection
            itemsPerPage={DEFAULT_ITEM_PER_PAGE}
            handleChangePage={handleChangePage}
            loading={loading}
            manuscripts={manuscripts}
            noManuscripts={noManuscripts}
            page={page}
            showResults={showResults}
            totalManuscripts={totalManuscripts}
            chosenBin={bin}
            resultsTopDescription={resultsTopDescription}
          />
        </ResultsSectionContainer>
      </Root>
    </>
  )
}
