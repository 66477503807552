import React from 'react'
import { Col } from '@hindawi/phenom-ui'
import { AuthorTagList } from '@shared/ui'
import { Author } from '@shared/ui/types'

export const AuthorsCell = ({ authors }: { authors: Author[] }) => (
  <>
    {authors && authors.length > 0 && (
      <Col flex="auto">
        <AuthorTagList authors={authors} withTooltip />
      </Col>
    )}
  </>
)
