import ReactDOM from 'react-dom'
import React, { Fragment } from 'react'

import Root from './ModalRoot'
import ModalContext from './ModalContext'

export default ({
    modalKey,
    dismissable,
    overlayColor,
    component: Component = (props) => (
      <span onClick={props.hideModal}>a modal here</span>
    ),
  }) =>
  (WrappedComponent) =>
  // eslint-disable-next-line react/display-name
  (props) => (
    <ModalContext.Consumer>
      {({ modalKey: mk, hideModal, showModal, data = {} }) => (
        <Fragment>
          {mk &&
            mk === modalKey &&
            ReactDOM.createPortal(
              <Root
                onClick={dismissable ? hideModal : null}
                overlayColor={overlayColor}
              >
                <Component {...props} {...data} hideModal={hideModal} />
              </Root>,
              document.getElementById('ps-modal-root'),
            )}
          <WrappedComponent
            {...props}
            hideModal={hideModal}
            showModal={(data) => showModal(modalKey, data)}
          />
        </Fragment>
      )}
    </ModalContext.Consumer>
  )
