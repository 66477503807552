const getRoleFilter = ({
  peerReviewModel: {
    hasTriageEditor,
    hasFigureheadEditor,
    figureheadEditorLabel,
  },
  triageEditorLabel,
  academicEditorLabel,
}) => {
  const roles = {
    all: 'All',
    admin: 'Admin',
    author: 'Author',
    reviewer: 'Reviewer',
    academicEditor: academicEditorLabel,
    editorialAssistant: 'Editorial Assistant',
  }
  if (hasTriageEditor) roles.triageEditor = triageEditorLabel
  if (hasFigureheadEditor) roles.figureheadEditor = figureheadEditorLabel
  return {
    options: Object.entries(roles).reduce(
      (acc, el) => [
        ...acc,
        {
          value: el[0],
          label: el[1],
        },
      ],
      [],
    ),
  }
}

export default getRoleFilter
