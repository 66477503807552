const capitalize = (word) => word[0].toUpperCase() + word.slice(1)

export const statusToCategoryMap = {
  draft: 'inProgress',
  technicalChecks: 'inProgress',
  submitted: 'inProgress',
  academicEditorInvited: 'inProgress',
  academicEditorAssigned: 'inProgress',
  reviewersInvited: 'inProgress',
  underReview: 'inProgress',
  reviewCompleted: 'inProgress',
  revisionRequested: 'inProgress',
  pendingApproval: 'inProgress',
  rejected: 'rejected',
  inQA: 'inProgress',
  revisionSubmitted: 'inProgress',
  revisionChecks: 'inProgress',
  withdrawn: 'withdrawn',
  published: 'approved',
  accepted: 'approved',
  withdrawalRequested: 'approved',
  olderVersion: 'withdrawn',
  academicEditorAssignedEditorialType: 'inProgress',
  makeDecision: 'inProgress',
  qualityChecksRequested: 'approved',
  qualityChecksSubmitted: 'approved',
  refusedToConsider: 'rejected',
  void: 'withdrawn',
  deleted: 'withdrawn',
}

export const getStatusCategory = (status) =>
  `status${capitalize(statusToCategoryMap[status])}`

enum PriorityTagEnum {
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
  POSITIVE = 'positive',
}

const priorityMap = {
  inProgress: PriorityTagEnum.MEDIUM,
  rejected: PriorityTagEnum.HIGH,
  approved: PriorityTagEnum.LOW,
  withdrawn: undefined,
}

export const getStatusPriority = (status) => {
  return priorityMap[statusToCategoryMap[status]] //NOTE: The positive category still not implemented ?? PriorityTagEnum.POSITIVE
}

export type ManuscriptStatus = keyof typeof statusToCategoryMap

export const statusLabels = {
  draft: 'Draft',
  technicalChecks: 'In Screening',
  inQA: 'In Quality Checks',
  refusedToConsider: 'Refused to Consider',
  rejected: 'Rejected',
  submitted: 'Submitted',
  academicEditorInvited: 'Academic Editor Invited',
  academicEditorAssigned: 'Academic Editor Agreed',
  reviewersInvited: 'Reviewers Invited',
  underReview: 'Reviewers Agreed',
  reviewCompleted: 'Reports Submitted',
  revisionRequested: 'Revision Requested',
  olderVersion: 'Older Version ',
  pendingApproval: 'Pending CE Approval ',
  makeDecision: 'Pending AE Decision',
  accepted: 'Accepted',
  qualityChecksRequested: 'QC Updates Required',
  qualityChecksSubmitted: 'QC Updates Submitted',
  published: 'Published',
  withdrawn: 'Withdrawn',
  deleted: 'Deleted',
  void: 'Void',
} as const
