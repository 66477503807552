import Keycloak from 'keycloak-js'
import {
  getAuthTokens,
  setAuthTokens,
  identifyLogrocketUser,
} from '../../component-authentication/client/utils'

let refreshInterval

const init = (config, onSuccess, onError) => {
  const { authServerURL, realm, clientID } = config

  window.clearInterval(refreshInterval)

  const keycloak = new Keycloak({
    url: authServerURL,
    clientId: clientID,
    realm,
  })

  const { token, idToken, refreshToken } = getAuthTokens()
  // https://www.keycloak.org/docs/latest/securing_apps/index.html#_javascript_adapter
  keycloak
    .init({
      token,
      idToken,
      refreshToken,
      onLoad: 'login-required',
      timeSkew: 0,
      checkLoginIframe: false,
    })
    .then(() => {
      // @ts-ignore
      keycloak.forceUpdateToken = () => {
        return keycloak
          .updateToken(3600 * 48) // 48 hours (longer than the token validity)
          .then(updateTokens)
          .catch((e) =>
            console.error('Error force refreshing keycloak token', e),
          )
      }
      const updateTokens = () => {
        const { token, idToken, refreshToken } = keycloak
        setAuthTokens({ token, idToken, refreshToken })
      }
      refreshInterval = window.setInterval(
        () => {
          void keycloak
            .updateToken(300) // 5 minutes
            .then(updateTokens)
            .catch((e) => console.error('Error refreshing keycloak token', e))
        },
        15 * 60 * 1000, // 15 minutes
      )
      updateTokens()
      identifyLogrocketUser(keycloak)
      onSuccess(keycloak)
    })
    .catch((error) => {
      onError(error, keycloak)
    })
}

export default {
  init,
}
