import { useState } from 'react'

export const usePagination = (totalCount, initialItemsPerPage) => {
  const [page, setPage] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(initialItemsPerPage)

  const toFirst = () => {
    setPage(0)
  }

  const toLast = () => {
    const floor = Math.floor(totalCount / itemsPerPage)
    setPage(totalCount % itemsPerPage ? floor : floor - 1)
  }

  const changeItemsPerPage = (e) => {
    setItemsPerPage(e.target.value)
    setPage(0)
  }

  const nextPage = () => {
    setPage((page) =>
      page * itemsPerPage + itemsPerPage < totalCount ? page + 1 : page,
    )
  }

  const prevPage = () => {
    setPage((page) => Math.max(0, page - 1))
  }

  return {
    page,
    toLast,
    setPage,
    toFirst,
    prevPage,
    nextPage,
    changeItemsPerPage,
    itemsPerPage,
    hasMore: itemsPerPage * (page + 1) < totalCount,
  }
}
