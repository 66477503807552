import React from 'react'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import { space } from 'styled-system'
import styled from 'styled-components'

import { heightHelper, widthHelper } from '../styledHelpers'

const FLEX_START = 'flex-start'

/** @component */

const ItemElement = styled.div`
  align-items: ${(props) => get(props, 'alignItems', FLEX_START)};
  display: ${(props) => get(props, 'display', 'flex')};
  flex: ${({ flex }) => (flex === undefined ? 1 : flex)};
  flex-direction: ${(props) => (props.vertical ? 'column' : 'row')};
  flex-wrap: ${(props) => get(props, 'flexWrap', 'initial')};
  justify-content: ${(props) => get(props, 'justify', FLEX_START)};

  ${space};
  ${heightHelper}
  ${widthHelper}
`

const Item = ({
  alignItems = FLEX_START,
  flexWrap = 'initial',
  justify = FLEX_START,
  vertical = false,
  ...props
}) => (
  <ItemElement
    alignItems={alignItems}
    flexWrap={flexWrap}
    justify={justify}
    vertical={vertical}
    {...props}
  />
)

ItemElement.propTypes = {
  /** Defines how flex items are laid out along the secondary axis. */
  alignItems: PropTypes.string,
  /** Sets the flex direction. If true items are layed out in a column. */
  vertical: PropTypes.bool,
  /** Sets whether flex items are forced onto one line or can wrap on multiple ones. */
  flexWrap: PropTypes.string,
  /** Specifies alignment along the main axis. */
  justify: PropTypes.string,
}

export default Item
