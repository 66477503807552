import { useCallback, useMemo } from 'react'

import { useSearch } from './useSearch'
import { usePagination } from './pagination'

const defaultFilter = (searchQuery) => (journal) => true

export function usePaginatedItems({
  items = [],
  itemsPerPage = 20,
  filterFn = defaultFilter,
}) {
  const { page, setPage, ...pagination } = usePagination(
    items.length,
    itemsPerPage,
  )
  const { changeSearch, clearSearch, searchQuery } = useSearch('')

  const filteredItems = useMemo(
    () => items.filter(filterFn(searchQuery)),
    [items, filterFn, searchQuery],
  )

  const paginatedItems = useMemo(
    () => filteredItems.slice(page * itemsPerPage, (page + 1) * itemsPerPage),
    [page, filteredItems, itemsPerPage],
  )

  const handleSearch = useCallback(
    (e) => {
      if (page !== 0) {
        setPage(0)
      }
      changeSearch(e)
    },
    [page, setPage, changeSearch],
  )

  return {
    page,
    totalCount: filteredItems.length,
    paginatedItems,
    clearSearch,
    searchQuery,
    handleSearch,
    setPage,
    ...pagination,
  }
}
