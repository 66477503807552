import { Author } from '@shared/ui/types'

type ParsedAffiliations = {
  authors: Author[]
  affiliations: string[]
}

export const parseAffiliations = (
  authors: Author[] = [],
): {
  affiliations: string[]
  authors: Author[]
} =>
  authors.reduce<ParsedAffiliations>(
    (acc, curr) => {
      if (acc.affiliations.includes(curr.alias.aff)) {
        acc.authors = [
          ...acc.authors,
          {
            ...curr,
            affiliationNumber:
              acc.affiliations.findIndex((e) => e === curr.alias.aff) + 1,
          },
        ]
      } else {
        acc.affiliations = [...acc.affiliations, curr.alias.aff]
        acc.authors = [
          ...acc.authors,
          {
            ...curr,
            affiliationNumber:
              acc.affiliations.findIndex((e) => e === curr.alias.aff) + 1,
          },
        ]
      }

      return acc
    },
    { affiliations: [], authors: [] },
  )
