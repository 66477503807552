import React from 'react'
import { Col, Pagination, Spinner, Text, Row, Space } from '@hindawi/phenom-ui'
import { ManuscriptCard } from '../../../../../../component-dashboard/client/components'
import styled from 'styled-components'

const SearchResultsContainer = styled(Row)`
  -ms-overflow-style: none;
  max-height: calc(100vh - 390px);
  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`

export const SearchResults = ({
  loading,
  showResults,
  manuscripts,
  setIsModalVisible,
  page,
  PageSize,
  totalManuscripts,
  handleChangePage,
}) => {
  return (
    <Space size={32} direction="vertical" style={{ width: '100%' }}>
      <SearchResultsContainer justify={'center'}>
        {loading && <Spinner />}
        {showResults && (
          <Row gutter={[0, 16]} style={{ width: '100%' }}>
            {manuscripts.map((manuscript, key) => (
              <Col span={24} key={manuscript.id}>
                <ManuscriptCard
                  key={key}
                  manuscript={manuscript}
                  propagationCallback={() => setIsModalVisible(false)}
                />
              </Col>
            ))}
          </Row>
        )}
        {showResults && !manuscripts.length && <Text>No Results found</Text>}
      </SearchResultsContainer>
      <Row justify="end" style={{ width: '100%' }}>
        <Pagination
          total={totalManuscripts}
          current={page}
          defaultCurrent={1}
          hideOnSinglePage
          pageSize={PageSize}
          onChange={handleChangePage}
          showSizeChanger={false}
        />
      </Row>
    </Space>
  )
}
