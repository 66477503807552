import { Checkbox, Space } from '@hindawi/phenom-ui'
import styled from 'styled-components'

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 26px;
`

export const Title = styled.span`
  font-weight: 700;
`

export const CheckboxGroup = styled(Checkbox.Group)`
  width: 100%;

  .ant-checkbox-wrapper.ant-checkbox-group-item {
    display: flex;
    align-items: center;
    margin-right: 0;

    & > span:last-child {
      width: 100%;
      padding-right: 0;

      & > span {
        display: flex;
        justify-content: space-between;
      }
    }
  }
`

export const CheckboxGroupWrapper = styled(Space)`
  width: 100%;
`

export const StyledLabel = styled.span`
  position: relative;

  .spinner-wrapper {
    svg {
      height: 20px;
      width: 20px;
    }
  }

  .count {
    color: #4f4f4f;
    font-size: 11px;
    font-weight: 700;
  }
`
