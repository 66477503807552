import React, { useMemo } from 'react'

import { Space, Text, Preset } from '@hindawi/phenom-ui'

export type ReviewerBreakdownProps = {
  reviewers: { status: string }[]
}

const countByStatus = (reviewers: { status: string }[], status: string) =>
  reviewers.filter((reviewer) => reviewer?.status === status).length

const ReviewerBreakdown: React.FC<ReviewerBreakdownProps> = ({
  reviewers = [],
}) => {
  const { accepted, declined, submitted, pending, expired } = useMemo(
    () => ({
      accepted:
        countByStatus(reviewers, 'accepted') +
        countByStatus(reviewers, 'submitted'),
      declined: countByStatus(reviewers, 'declined'),
      submitted: countByStatus(reviewers, 'submitted'),
      pending: countByStatus(reviewers, 'pending'),
      expired: countByStatus(reviewers, 'expired'),
    }),
    [reviewers],
  )

  return (
    <Space size={16}>
      {[
        [reviewers.length, 'Invited'],
        [accepted, 'Agreed'],
        [submitted, 'Submitted'],
        [pending, 'Pending'],
        [declined, 'Declined'],
        [expired, 'Expired'],
      ].map(([value, label]) => (
        <Space data-test-id={`${label}-reviewers`} size={4} key={label}>
          <Text preset={Preset.BOLD}>{value} </Text>
          <Text style={{ fontSize: '14px' }}>{label}</Text>
        </Space>
      ))}
    </Space>
  )
}

export default ReviewerBreakdown
