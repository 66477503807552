import React from 'react'
import { Pagination as PaginationComponent } from '@hindawi/phenom-ui'
import { FiltersManagerAPII } from '../../../store/EADashboard/filters'

const Pagination: React.FC<{
  filters: FiltersManagerAPII
  manuscriptsTotalCount: any
}> = ({ filters, manuscriptsTotalCount }) => {
  const { page } = filters

  const { value, set } = page

  const handlePageChange = (pageNo: number): void => {
    set(pageNo)
  }

  if (manuscriptsTotalCount) {
    return (
      <PaginationComponent
        current={value}
        defaultCurrent={1}
        onChange={handlePageChange}
        pageSize={10}
        showSizeChanger={false}
        total={manuscriptsTotalCount}
      />
    )
  }
  return null
}

export default Pagination
