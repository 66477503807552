import React from 'react'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { space, fontWeight } from 'styled-system'

const textHelper = (props) => {
  if (get(props, 'selected')) {
    return css`
      color: #ffffff;
    `
  }
  if (get(props, 'secondary')) {
    return css`
      color: #828282;
    `
  }
  if (get(props, 'error')) {
    return css`
      color: #fc6a4b;
    `
  }

  if (get(props, 'alert')) {
    return css`
      color: #fc6a4b;
    `
  }

  if (get(props, 'warning')) {
    return css`
      color: #fc6a4b;
    `
  }

  if (get(props, 'emptyState')) {
    return css`
      color: #4f4f4f;
      font-size: 13px;
      line-height: 20px;
    `
  }

  if (get(props, 'customId')) {
    return css`
      color: #81ba40;
      font-size: 13px;
      font-weight: 700;
    `
  }

  if (get(props, 'newCustomId')) {
    return css`
      color: #4f4f4f;
      font-weight: 700;
    `
  }
  if (get(props, 'labelLine')) {
    return css`
      color: #828282;
      display: flex;
      &:after {
        display: block;
        content: ' ';
        border-bottom: 1px solid #e0e0e0;
        flex: 1 1 auto;
        margin-left: calc(4px * 4);
      }
    `
  }

  if (get(props, 'journal')) {
    return css`
      color: #4f4f4f;
      &:before {
        content: '•';
        padding-right: 4px;
      }
    `
  }

  if (get(props, 'invited')) {
    return css`
      color: #fcb624;
    `
  }
}

const fontSize = (props) => {
  if (get(props, 'small')) {
    return css`
      font-size: 10px;
    `
  }
  if (get(props, 'medium')) {
    return css`
      font-size: 13px;
    `
  }
  return css`
    font-size: 14px;
    line-height: 19px;
  `
}

const ellipsis = (props) => {
  if (props.ellipsis) {
    return css`
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    `
  }

  return css`
    white-space: ${(props) => get(props, 'whiteSpace', 'initial')};
  `
}

const color = (props) =>
  props.color &&
  css`
    color: ${props.color};
  `

const upperCase = (props) =>
  props.upperCase &&
  css`
    text-transform: uppercase;
  `

const StyledText = styled.span`
  align-items: center;
  display: ${(props) => get(props, 'display', 'inline-block')};
  font-family: 'Nunito';
  font-style: ${(props) => get(props, 'fontStyle', 'normal')};
  height: ${(props) => get(props, 'height', 'initial')};
  text-align: ${(props) => get(props, 'align', 'start')};
  opacity: ${(props) => (props.opacity ? props.opacity : '1')};

  ${fontSize};
  ${upperCase}
  ${ellipsis};
  ${textHelper};
  ${space}
  ${fontWeight};
  ${color}
`

const Text = ({ children, ...rest }) => (
  <StyledText {...rest}>{children}</StyledText>
)

Text.propTypes = {
  /** Default color for non-primary actions. */
  secondary: PropTypes.bool,
  /** Default color for error actions. */
  error: PropTypes.bool,
  /** Default style for the customId text. */
  customId: PropTypes.bool,
  /** Default style for text used as a label Line. */
  labelLine: PropTypes.bool,
  /** Default style used for journal text. */
  journal: PropTypes.bool,
  /** Default style used for small text. */
  small: PropTypes.bool,
}

Text.defaultProps = {
  secondary: false,
  error: false,
  customId: false,
  labelLine: false,
  journal: false,
  small: false,
}

export default Text
