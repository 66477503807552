import React, { Fragment } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import Root from './ModalRoot'
import { ModalContext } from '..'

const Modal = ({
  children,
  dismissable,
  overlayColor,
  component: Comp,
  modalKey,
  ...rest
}) => (
  <ModalContext.Consumer>
    {(modalProps) => (
      <Fragment>
        {modalProps.modalKey &&
          modalProps.modalKey === modalKey &&
          ReactDOM.createPortal(
            <Root
              onClick={(event) => {
                if (dismissable) modalProps.hideModal()
                event.stopPropagation()
              }}
              overlayColor={overlayColor}
            >
              <Comp {...rest} {...modalProps} />
            </Root>,
            document.getElementById('ps-modal-root'),
          )}
        {children(() => modalProps.showModal(modalKey))}
      </Fragment>
    )}
  </ModalContext.Consumer>
)

Modal.propTypes = {
  modalKey: PropTypes.string.isRequired,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
}

export default Modal
