// @ts-nocheck
import { useContext } from 'react'
import { DashboardWidgetContext } from '../'
import { useSuggestionsManager } from '../suggestions/useSuggestionsManager'
import { ManagerApiI } from '../../'
import { ValueT } from './state'

interface useSuggestionManagerProps {
  editorialAssistantId?: string
  journalId?: string
  sectionId?: string
}

type useSuggestionManagerT = (
  props?: useSuggestionManagerProps,
) => ManagerApiI<ValueT, string>

export const useManuscriptSuggestionsManager: useSuggestionManagerT = (
  params,
) => {
  const context = useContext(DashboardWidgetContext).manuscriptSuggestions
  const query = 'getManuscriptsSuggestions'

  const inputResolver = (searchTerm: string) => ({
    title: searchTerm,
    isLatestVersion: true,
    rangeStart: 0,
    rangeEnd: 99,
    ...params, // editorialAssistantId: (currentUser as { id: string }).id, // pass this from component
  })

  return useSuggestionsManager({
    context,
    query,
    inputResolver,
  })
}
