import styled from 'styled-components'
import { Card as _Card, Input as _Input, Space, Text } from '@hindawi/phenom-ui'
import { SearchWithAutocomplete as _SearchWithAutocomplete } from '../SearchWithAutocomplete'

export const SearchWithAutocomplete = styled(_SearchWithAutocomplete)`
  width: 100%;
`

export const InputSearch = styled(_Input.Search)`
  width: 100%;
`

export const SearchCategoryWrapper = styled(Space)`
  height: 100%;

  & span {
    font-weight: 700;
  }
`

export const Wrapper = styled(_Card)`
  height: 64px;
  width: 100%;
  box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.11);
  border-color: white;

  &:before {
    background: rgba(0, 0, 0, 0.3);
    content: '';
    display: block;
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: 300;
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;

    transition: opacity 0.3s ease;
  }

  &.widgetExpanded:before {
    opacity: 1;
    pointer-events: all;
  }

  &.widgetExpanded .widget-content.ant-card {
    height: 447px;
    z-index: 301;

    transition:
      height 0.3s ease,
      z-index 0s ease;
  }

  .ant-row {
    margin-bottom: 12px;
  }
`

export const Card = styled(_Card)`
  position: absolute;
  top: 0;
  left: 0;
  height: 64px;
  width: 100%;
  z-index: 1;
  border-color: white;

  transition:
    height 0.3s ease,
    z-index 0s ease 0.3s;

  .ant-card-body {
    height: 100%;
  }

  .greeter-row {
    text-align: center;
    height: calc(100% - 48px);
    width: 100%;
    justify-content: center;

    h2 {
      color: #828282;
    }
  }
`

export const ManuscriptListingWrapper = styled(Space)`
  width: 100%;
  height: calc(100% - 48px);
  justify-content: space-between;

  .close-button-wrapper {
    text-align: end;
  }
`

export const JournalName = styled(Text)`
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 700;
`

export const ManuscriptTitle = styled(Text)`
  font-size: 16px;
  font-weight: 400;
`
