import gql from 'graphql-tag'

import { dashboardManuscriptDetails } from './fragments'

export const getManuscripts = gql`
  query getManuscripts($input: DashboardInput) {
    getManuscripts(input: $input) {
      manuscripts {
        ...dashboardManuscriptDetails
      }
      totalCount
      statusWithFilter {
        visibleStatus
        count
        statusFilter
      }
    }
  }
  ${dashboardManuscriptDetails}
`

export const getViewerManuscripts = gql`
  query getViewerManuscripts($input: ViewerDashboardInput) {
    getViewerManuscripts(input: $input) {
      manuscripts {
        ...dashboardManuscriptDetails
      }
      totalCount
      statusWithFilter {
        visibleStatus
        count
        statusFilter
      }
    }
  }
  ${dashboardManuscriptDetails}
`

export const getManuscriptsV2 = gql`
  query getManuscriptsV2($input: GetManuscriptsInput) {
    getManuscriptsV2(input: $input) {
      manuscripts {
        ...dashboardManuscriptDetails
      }
      totalCount
      statuses
    }
  }
  ${dashboardManuscriptDetails}
`

export const getManuscriptsSuggestions = gql`
  query getManuscriptsSuggestions($input: GetManuscriptsInput) {
    getManuscriptsSuggestions(input: $input) {
      id
      title
      journal {
        id
        name
      }
    }
  }
`

export const searchManuscripts = gql`
  query searchManuscripts($input: SearchManuscriptInput) {
    searchManuscripts(input: $input) {
      manuscripts {
        ...dashboardManuscriptDetails
      }
      totalCount
    }
  }
  ${dashboardManuscriptDetails}
`

export const getJournalsSuggestions = gql`
  query getJournalsSuggestions($input: GetJournalsSuggestionsInput) {
    getJournalsSuggestions(input: $input) {
      id
      name
    }
  }
`

export const getSectionsSuggestions = gql`
  query getSectionsSuggestions($input: GetSectionsSuggestionsInput) {
    getSectionsSuggestions(input: $input) {
      id
      name
    }
  }
`

export const getSpecialIssuesSuggestions = gql`
  query getSpecialIssuesSuggestions($input: GetSpecialIssuesSuggestionsInput) {
    getSpecialIssuesSuggestions(input: $input) {
      id
      name
    }
  }
`

export const getStatusesManuscriptsCount = gql`
  query getStatusesManuscriptsCount($input: GetManuscriptsInput) {
    getStatusesManuscriptsCount(input: $input) {
      status
      count
    }
  }
`
