import './fonts/icons.css'

export default {
  /* Colors */
  colorBackground: '#ECF0F3',
  colorPrimary: 'red',
  colorSecondary: '#586971',
  colorFurniture: '#DBDBDB',
  colorFurnitureHue: '#939393',
  colorBorder: '#DBDBDB',
  colorBackgroundHue: '#FFFFFF',
  colorBackgroundHue2: '#F6F6F6',
  colorBackgroundHue3: '#EEEEEE',
  colorSuccess: '#63A945',
  colorError: '#FC6A4B',
  colorText: '#4F4F4F',
  colorTextReverse: '#667080',
  colorTextPlaceholder: '#595959',
  colorWarning: '#007e92', // hack: we use this for action link icons
  colorInfo: '#fcb74b',
  iconSolidInfo: '#5AC4E4',

  action: {
    color: '#007e92',
    colorActive: '#003049',
  },

  wizard: {
    colorBackground: '#ffffff',
  },

  appBar: {
    boxShadow: '0 2px 3px 0 rgba(25, 102, 141, 0.19)',
    colorBackground: '#ffffff',
    height: '70px',
    zIndex: 1000,
  },

  heading: {
    h1Color: '#589eb8',
    h2Color: '#007e92',
    h3Color: '#242424',
    h3LineHeight: '19px',
    h4Color: '#4F4F4F',
  },

  button: {
    borderActive: 'rgba(0,0,0,0.34)',
    borderDefault: 'rgba(0,0,0,0.14)',

    primaryText: '#f6f6f6',
    primary: '#63A945',
    disabled: '#dbdbdb',
    secondary: 'none',
    secondaryBorderWidth: '2px',
    secondaryBorderColor: '#003049',

    smallSize: '12px',
    smallHeight: 3,
    smallWidth: 10,

    mediumSize: '14px',
    mediumHeight: 4,
    mediumWidth: 12,

    largeSize: '14px',
    largeHeight: 5,
    largeWidth: 15,

    xLargeSize: '14px',
    xLargeHeight: 5,
    xLargeWidth: 24,
  },

  box: {
    backgroundColor: '#FFFFFF',
    borderRadius: '3px',
    border: '1px solid #E7E7E7',
  },

  menu: {
    hoverColor: '#003049',
    openerShadow: '0 2px 6px -1px rgba(125, 125, 125, 0.5)',
    optionBackground: 'rgba(0, 126, 146, 0.1)',
  },

  checkbox: {
    borderColor: '#939393',
  },

  tag: {
    color: '#ffffff',
    fontSize: '14px',
    fontWeight: '600',
    backgroundColor: '#586971',
    statusBackgroundColor: '#dbafc1',
    borderRadius: '2px',
  },

  dashboardCard: {
    hoverShadow: '0 1px 2px 1px #939393',
  },

  /* Text variables */
  // fonts

  // font sizes
  fontSizeBase: '14px',
  fontSizeBaseMedium: '13px',
  fontSizeBaseSmall: '10px',

  fontSizeHeading1: '30px',
  fontSizeHeading2: '24px',
  fontSizeHeading3: '16px',
  fontSizeHeading4: '14px',
  fontSizeHeading5: '14px',
  fontSizeHeading6: '14px',

  // line heights
  lineHeightBase: '18px',
  lineHeightBaseSmall: '13px',

  lineHeightHeading1: '37px',
  lineHeightHeading2: '29px',
  lineHeightHeading3: '20px',
  lineHeightHeading4: '18px',
  lineHeightHeading5: '18px',
  lineHeightHeading6: '18px',

  // font weights
  fontWeightNormal: '400',
  fontWeightSemibold: '600',
  fontWeightBold: '700',

  /* Spacing */
  gridUnit: '8px',

  /* Border */
  borderRadius: '4px',
  borderWidth: '1px', // julien: not 0
  borderStyle: 'solid',

  /* Shadow (for tooltip) */
  boxShadow: '0 1px 2px 1px #dbdbdb',

  /* Transition */
  transitionDuration: '0.2s', // TODO -- julien: not 0.05s
  transitionTimingFunction: 'ease',
  transitionDelay: '0',

  // z indexes
  modalIndex: 10000,
}
