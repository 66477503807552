import {
  Button,
  IconCaretDown,
  IconSearch,
  IconWileyLogo,
  NavBar,
  Text,
  UserControl,
} from '@hindawi/phenom-ui'
import { ROUTES } from '@shared/ui/constants'
import React, { useState } from 'react'
import { AutosaveIndicator } from '../../../../component-submission/client'
import { SearchModal } from './SearchModal'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'

const UserControlContainer = styled.div`
  display: flex;
  align-items: center;
`
const ClickableDropDownItem = styled.a`
  width: 128px;
  display: block;
`

const useDropdownItems = (
  isSuperAdmin,
  logout,
  goTo,
  hasAdministrativeRole,
  isDownloadFileRoute,
) => {
  if (isDownloadFileRoute) {
    return [
      {
        label: 'Logout',
        onClick: logout,
      },
    ]
  }

  const options: any = [
    {
      label: (
        <ClickableDropDownItem onClick={() => goTo(ROUTES.PROFILE)}>
          My Profile
        </ClickableDropDownItem>
      ),
    },
    {
      label: 'Logout',
      onClick: logout,
    },
  ]

  if (location.pathname === ROUTES.ADMIN_DASHBOARD) {
    return [
      {
        label: (
          <ClickableDropDownItem onClick={() => goTo(ROUTES.DASHBOARD)}>
            Dashboard
          </ClickableDropDownItem>
        ),
      },
      ...options,
    ]
  }

  if (isSuperAdmin) {
    return [
      {
        label: (
          <ClickableDropDownItem onClick={() => goTo(ROUTES.ADMIN_DASHBOARD)}>
            Admin Dashboard
          </ClickableDropDownItem>
        ),
      },
      ...options,
    ]
  } else if (hasAdministrativeRole) {
    return [
      {
        label: (
          <a
            style={{
              marginTop: '150px',
            }}
            onClick={() => goTo('/admin/journals')}
          >
            Journal List
          </a>
        ),
      },
      ...options,
    ]
  }
  return options
}

const useGetUsername = (currentUser) => {
  const { identities } = currentUser
  if (identities.length) {
    const localIdentity = identities.find(
      (identity) => identity.__typename === 'Local',
    )
    const { givenNames, surname } = localIdentity.name || {
      givenNames: '',
      surname: '',
    }
    return givenNames || surname
  }
  return ''
}

const UserControlContainerRoute = ({
  currentUser,
  logout,
  goTo,
  hasAdministrativeRole,
  isSuperAdmin,
  isDownloadFileRoute,
}) => {
  const username = useGetUsername(currentUser)
  const dropdownItems = useDropdownItems(
    isSuperAdmin,
    logout,
    goTo,
    hasAdministrativeRole,
    isDownloadFileRoute,
  )

  const { pathname } = useLocation()

  const renderAutosave = pathname.includes('/details/')
  return (
    <UserControlContainer>
      {renderAutosave && <AutosaveIndicator />}
      <UserControl options={dropdownItems}>
        <Text
          data-test-id="header-menu"
          ellipsis
          style={{
            maxWidth: '180px',
            color: 'inherit',
          }}
        >
          {username}
        </Text>
        <IconCaretDown />
      </UserControl>
    </UserControlContainer>
  )
}

export const AuthenticatedAppBarContent = ({
  logout,
  currentUser,
  goToDashboard,
  goTo,
  showSearchButton = false,
  isSuperAdmin = false,
  hasAdministrativeRole = false,
  isRedirectDisabled = false,
}) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

  return (
    <>
      <NavBar
        logo={
          <IconWileyLogo
            onClick={isRedirectDisabled ? undefined : goToDashboard}
            style={{
              cursor: isRedirectDisabled ? 'default' : 'pointer',
            }}
          />
        }
        actionSection={
          <>
            {showSearchButton && (
              <Button
                data-test-id="search-modal-button"
                type="default"
                icon={<IconSearch />}
                iconRight
                onClick={() => setIsModalVisible(true)}
              >
                Search
              </Button>
            )}
          </>
        }
        userControl={
          <UserControlContainerRoute
            currentUser={currentUser}
            logout={logout}
            goTo={goTo}
            hasAdministrativeRole={hasAdministrativeRole}
            isSuperAdmin={isSuperAdmin}
            isDownloadFileRoute={isRedirectDisabled}
          />
        }
      />
      {isModalVisible && (
        <SearchModal
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
        />
      )}
    </>
  )
}
