import { useCurrentUser } from '../../component-authentication/client'
import { ROLES } from '../constants'
import { RoleT } from '../types'

const STAFF_ROLES: RoleT[] = [ROLES.ADMIN, ROLES.EA]

type Dashboards =
  | 'editorialDashboard'
  | 'editorialAssistantDashboard'
  | 'adminDashboard'

export function useHasAccessToDashboard(): (dashboard: Dashboards) => boolean {
  const currentUser = useCurrentUser()

  const editorialDashboard =
    !!currentUser &&
    !currentUser.isSuperAdmin &&
    !STAFF_ROLES.includes(currentUser.role)

  const editorialAssistantDashboard =
    !!currentUser &&
    !currentUser.isSuperAdmin &&
    STAFF_ROLES.includes(currentUser.role)

  const adminDashboard = !!currentUser && currentUser.isSuperAdmin

  const accessMap: Record<Dashboards, boolean> = {
    editorialDashboard,
    adminDashboard,
    editorialAssistantDashboard,
  }

  return (dashboard: Dashboards) => {
    return accessMap[dashboard]
  }
}
