import { createContext } from 'react'

export default createContext({
  name: '',
  supportEmail: '',
  publisher: null,
  recommendationScreenInfoBox: {
    title: '',
    description: '',
  },
})
