// @ts-nocheck
import { useState } from 'react'
import { initialSelectedStatusCategories } from './statusCategories'
import { useLocalStorage } from '@shared/ui/hooks'

export const STATUS_FILTERS_KEY = 'statusFilters'

export type Option = {
  value: string
  displayValue: string
}

export type ValueT = Option | string | number

export const initialOption: Option = {
  value: null,
  displayValue: '',
}

export type SelectOptionT = {
  value: string
  label: string
}

export const orderValues = {
  NEWEST_UPDATED: 'newestUpdated',
  OLDEST_UPDATED: 'oldestUpdated',
  NEWEST_SUBMITTED: 'newestSubmitted',
  OLDEST_SUBMITTED: 'oldestSubmitted',
}

export const orderOptions: { [key: string]: SelectOptionT } = {
  [orderValues.NEWEST_UPDATED]: {
    value: orderValues.NEWEST_UPDATED,
    label: 'Status update date (newest)',
  },
  [orderValues.OLDEST_UPDATED]: {
    value: orderValues.OLDEST_UPDATED,
    label: 'Status update date (oldest)',
  },
  [orderValues.NEWEST_SUBMITTED]: {
    value: orderValues.NEWEST_SUBMITTED,
    label: 'Submission date (newest)',
  },
  [orderValues.OLDEST_SUBMITTED]: {
    value: orderValues.OLDEST_SUBMITTED,
    label: 'Submission date (oldest)',
  },
}

export const useFiltersState = () => {
  const [selectedJournal, setSelectedJournal] = useState(initialOption)
  const journal = {
    value: selectedJournal,
    set: setSelectedJournal,
  }
  const [selectedRole, setSelectedRole] = useState('')
  const role = {
    value: selectedRole,
    set: setSelectedRole,
  }

  const [selectedSection, setSelectedSection] = useState(initialOption)
  const section = {
    value: selectedSection,
    set: setSelectedSection,
  }

  const [selectedSectionSpecialIssue, setSelectedSectionSpecialIssue] =
    useState(initialOption)
  const specialIssue = {
    value: selectedSectionSpecialIssue,
    set: setSelectedSectionSpecialIssue,
  }

  const [selectedStatusCategories, setSelectedStatusCategories] =
    useLocalStorage(STATUS_FILTERS_KEY, initialSelectedStatusCategories)

  const selectedStatuses = {
    value: selectedStatusCategories,
    set: setSelectedStatusCategories,
  }

  const [authorEmail, setAuthorEmail] = useState('')
  const author = {
    value: authorEmail,
    set: setAuthorEmail,
  }

  const [AEemail, setAEemail] = useState('')
  const academicEditor = {
    value: AEemail,
    set: setAEemail,
  }

  const [currentPage, setCurrentPage] = useState(1)
  const page = {
    value: currentPage,
    set: setCurrentPage,
  }

  const [orderOption, setOrderOption] = useState(
    orderOptions[orderValues.NEWEST_UPDATED],
  )
  const orderCriteria = {
    value: orderOption,
    set: setOrderOption,
  }

  return {
    role,
    journal,
    section,
    specialIssue,
    selectedStatuses,
    author,
    academicEditor,
    page,
    orderCriteria,
    selectedStatusCategories,
  }
}
