import React from 'react'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import { space } from 'styled-system'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { withHandlers } from 'recompose'

import { displayHelper } from './styledHelpers'
import { Button } from '@hindawi/phenom-ui'

const actionLinkCSS = (props) => {
  if (get(props, 'whenHover')) {
    return css`
      &:hover * {
        color: #034a57;
      }
    `
  }
}

const ActionLink = ({
  to,
  onClick,
  disabled,
  children,
  renderLink,
  ...rest
}) => (
  <Root {...rest} to={to}>
    {renderLink(rest)}
  </Root>
)

ActionLink.propTypes = {
  /** Link/URL specifying where to navigate, outside or inside the app.
   * If present the component will behave like a navigation link. */
  to: PropTypes.string,
  /** Callback function fired when the component is clicked. */
  onClick: PropTypes.func,
  /** If true the component will be disabled (can't be interacted with). */
  disabled: PropTypes.bool,
}

ActionLink.defaultProps = {
  to: '',
  disabled: false,
  onClick: () => {},
}

export default withHandlers({
  renderLink:
    ({
      to,
      internal,
      disabled,
      onClick,
      children,
      fontSize,
      fontWeight,
      ...rest
    }) =>
    () => {
      if (to && !internal) {
        return (
          <ExternalLink
            href={disabled ? undefined : to}
            target="_blank"
            {...rest}
          >
            {children}
          </ExternalLink>
        )
      }

      return (
        <Button
          type="tertiary"
          disabled={disabled}
          fontSize={fontSize}
          fontWeight={fontWeight}
          onClick={onClick}
        >
          {children}
        </Button>
      )
    },
})(ActionLink)

// #region styles
const ExternalLink = styled.a`
  color: ${(props) => (props.secondary ? '#828282' : '#4F4F4F')};
  cursor: pointer;
  font-family: 'Nunito';
  line-height: 1;
  text-decoration: underline;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '')};
  opacity: ${(props) => (props.opacity ? props.opacity : '1')};
`

const Root = styled.div`
  align-items: ${(props) => get(props, 'alignItems', 'center')};
  flex: ${(props) => props.flex || 'none'};
  justify-content: center;
  height: inherit;
  width: max-content;

  &:hover * {
    color: #4f4f4f;
  }
  ${actionLinkCSS};
  ${displayHelper};
  ${space};
`
// #endregion
