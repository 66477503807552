import {
  IconTooltip,
  Popover,
  Preset,
  Select,
  Space,
  Text,
} from '@hindawi/phenom-ui'
import React from 'react'
import { statusLabels, STATUS_TOOLTIP } from '../constants'
import { PaginatedLazyQuery } from '../types'
import StatusTextContainer from './StatusTextContainer'

export const StatusSelect = ({
  onChangeStatus,
  statuses,
  chosenStatus,
}: {
  chosenStatus: PaginatedLazyQuery['chosenStatus']
  onChangeStatus: PaginatedLazyQuery['onChangeStatus']
  statuses: string[]
}) => {
  return (
    <Space direction="vertical" size={4} data-test-id="status-select">
      <StatusTextContainer>
        <Text preset={Preset.BOLD}>Status</Text>
        <Popover
          placement="right"
          content={<div style={{ padding: '12px' }}>{STATUS_TOOLTIP}</div>}
        >
          <IconTooltip />
        </Popover>
      </StatusTextContainer>
      <Select
        data-test-id="dashboard-filter-status"
        onChange={onChangeStatus}
        value={chosenStatus}
        style={{
          width: '248px',
        }}
      >
        {statuses.map((status: string) => {
          return (
            <Select.Option value={status} key={status}>
              {statusLabels[status] ?? status}
            </Select.Option>
          )
        })}
      </Select>
    </Space>
  )
}
