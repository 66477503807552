import { DateService, FormatType } from '../../component-date-service'

type DateParserProps = {
  timestamp: string
  timeAgo: string
}

export function useDateParser(data: {
  timestamp: string
  humanizeThreshold?: number
  format?: FormatType
}): DateParserProps {
  const defaultHumanizeThreshold = 0
  const { timestamp, humanizeThreshold, format } = data

  return {
    timestamp: DateService.getTimeStampToRender({
      timestamp,
      humanizeThreshold: humanizeThreshold || defaultHumanizeThreshold,
      format,
    }),
    timeAgo: DateService.getTimeAgo({ timestamp }),
  }
}
