import { Row, Tag } from '@hindawi/phenom-ui'
import React from 'react'

export const StatusCell = ({
  visibleStatus,
  statusPriority,
  version,
}: {
  visibleStatus: string
  statusPriority: 'high' | 'medium' | 'low' | 'positive' | undefined
  version: string
}) => (
  <Row className="status-overrider">
    <Tag
      leftLabel={visibleStatus}
      label={`v${version}`}
      priority={statusPriority}
    />
  </Row>
)
