import { ReactNode } from 'react'
import styled from 'styled-components'

const getStatusColor = ({ statusColor, theme, old }) =>
  old ? theme.textSecondaryColor : theme[statusColor]

export const Card = styled.div<{
  statusColor: string
  old: boolean
  onClick: () => void
  children: ReactNode
}>`
  border: 1px solid #e0e0e0;
  margin-bottom: 16px;
  border-radius: 6px;
  overflow: hidden;
  position: relative;

  &:before {
    content: '';
    height: 100%;
    width: 4px;
    background-color: ${getStatusColor};
    display: block;
    position: absolute;
  }
`

export const Content = styled.div`
  padding: 16px;
  padding-bottom: 0;

  .ant-row {
    flex-wrap: nowrap;

    &:first-child {
      line-height: 25px;

      a {
        margin-left: 4px;
      }
    }
  }

  .col-oh {
    overflow: hidden;
  }

  h5 {
    margin-bottom: 0;
  }

  .ant-typography.ant-typography-ellipsis {
    width: 100%;
  }

  .ant-typography-copy {
    margin-left: 0;
  }
`
export const Footer = styled.div`
  background: #f5f5f5;

  padding: 0 16px;
  height: 36px;
  line-height: 36px;
`
export const Label = styled.span`
  font-weight: 700;
`
