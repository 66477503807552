import { Card, Col, Row, Space, Spinner, Text, Title } from '@hindawi/phenom-ui'
import React from 'react'
import styled from 'styled-components'
import { ManuscriptListWithPagination, SearchResultDescription } from '.'

import { BinCategory } from '../types'

const ResultsTop = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const ResultsSection = ({
  manuscripts,
  loading,
  showResults,
  totalManuscripts,
  page,
  itemsPerPage,
  handleChangePage,
  chosenBin,
  resultsTopDescription,
  noManuscripts,
}) => (
  <Card data-test-id="dashboard-list-items">
    <Space direction="vertical" width="full" size={8}>
      <ResultsTop>
        <Col>
          <Title preset="small">{BinCategory[chosenBin]}</Title>
        </Col>
        <Col>
          {resultsTopDescription.length > 0 && (
            <SearchResultDescription
              resultsTopDescription={resultsTopDescription}
            />
          )}
        </Col>
      </ResultsTop>

      {!noManuscripts && loading && (
        <Row justify="center" data-test-id="loading-manuscripts-spinner">
          <Spinner />
        </Row>
      )}
      {!noManuscripts && showResults && (
        <Row justify="center">
          <Text data-test-id="no-manuscripts-found">No results found</Text>
        </Row>
      )}
      <ManuscriptListWithPagination
        totalManuscripts={totalManuscripts}
        itemsPerPage={itemsPerPage}
        handleChangePage={handleChangePage}
        manuscripts={manuscripts}
        page={page}
      />
    </Space>
  </Card>
)
