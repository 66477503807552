import styled, { css } from 'styled-components'

const modalPosition = () => css`
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
`

const ModalRoot = styled.div`
  align-items: center;
  display: flex;
  color: #4f4f4f;
  background-color: ${({ overlayColor }) =>
    overlayColor || 'rgba(0, 0, 0, 0.8)'};
  justify-content: center;
  z-index: 300;

  font-size: 14px;
  line-height: 18px;

  ${modalPosition};

  * {
    box-sizing: border-box;
  }
`

export default ModalRoot
