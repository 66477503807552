import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import Downshift from 'downshift'
import styled, { css } from 'styled-components'

import { useClientRect, Label, Icon } from '../'
import { validationColor } from './theme/toolkit'

const SearchableSelect = ({
  value,
  options,
  disabled,
  onChange,
  isLoading,
  noOptions,
  noResults,
  filterFunc,
  fieldLabel,
  placeholder,
  selectedItem,
  validationStatus,
  displayedOptions,
  onInputValueChange,
}) => {
  const [inputNode, inputPosition] = useClientRect()
  const downshiftInstance = useRef()

  useEffect(() => {
    if (!value && downshiftInstance) downshiftInstance.current.clearSelection()
  }, [value])

  const findValueLabel = (value, options) => {
    const found = options.find((o) => o.value === value)
    return found ? found.label : ''
  }
  return (
    <Downshift
      defaultHighlightedIndex={0}
      initialInputValue={findValueLabel(value, options)}
      itemToString={(item) => (item ? item.label : '')}
      onChange={onChange}
      onInputValueChange={(inputValue) =>
        onInputValueChange && onInputValueChange(inputValue)
      }
      ref={downshiftInstance}
      selectedItem={selectedItem}
    >
      {({
        isOpen,
        inputValue,
        toggleMenu,
        selectedItem,
        getItemProps,
        getRootProps,
        getMenuProps,
        getInputProps,
        getLabelProps,
        clearSelection,
        highlightedIndex,
      }) => (
        <Root {...getRootProps()}>
          {fieldLabel && (
            <Label as="label" {...getLabelProps()}>
              {fieldLabel}
            </Label>
          )}
          <InputWrapper>
            <Input
              {...getInputProps({
                placeholder,
                disabled,
                validationStatus,
              })}
              ref={inputNode}
            />
            {inputValue ? (
              <StyledIcon
                disabled={disabled}
                fontSize="16px"
                icon="remove"
                onClick={
                  disabled
                    ? undefined
                    : () => {
                        clearSelection()
                        onChange()
                      }
                }
              />
            ) : (
              <StyledIcon
                disabled={disabled}
                fontSize="16px"
                icon="search"
                onClick={disabled ? undefined : toggleMenu}
              />
            )}
          </InputWrapper>
          <Dropdown>
            <ItemList
              {...getMenuProps({
                isOpen,
                inputPosition,
                displayedOptions,
              })}
            >
              {isOpen
                ? renderOptions({
                    options,
                    isLoading,
                    noResults,
                    noOptions,
                    inputValue,
                    filterFunc,
                    selectedItem,
                    getItemProps,
                    highlightedIndex,
                  })
                : null}
            </ItemList>
          </Dropdown>
        </Root>
      )}
    </Downshift>
  )
}

SearchableSelect.propTypes = {
  displayedOptions: PropTypes.number,
  fieldLabel: PropTypes.node,
  filterFunc: PropTypes.func,
  noOptions: PropTypes.node,
  noResults: PropTypes.node,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.node,
      sublabel: PropTypes.node,
    }),
  ),
  onChange: PropTypes.func,
}

SearchableSelect.defaultProps = {
  displayedOptions: 5,
  fieldLabel: '',
  filterFunc: (item, inputValue) =>
    !inputValue ||
    item.label.toLowerCase().includes(inputValue.toLowerCase()) ||
    (item.sublabel &&
      item.sublabel.toLowerCase().includes(inputValue.toLowerCase())),
  noOptions: 'No options available',
  noResults: 'No results found',
  placeholder: '',
  onChange: () => {},
  options: [],
}

const renderOptions = ({
  options,
  isLoading,
  noResults,
  noOptions,
  inputValue,
  filterFunc,
  selectedItem,
  getItemProps,
  highlightedIndex,
}) => {
  if (isLoading) return <NoItems>Loading...</NoItems>
  if (!options.length) return <NoItems>{noOptions}</NoItems>

  const renderedOptions = options.filter((item) => filterFunc(item, inputValue))

  return renderedOptions.length ? (
    renderedOptions.map((item, index) => (
      <Item
        key={item.value}
        {...getItemProps({
          key: item.value,
          index,
          item,
          isHighlighted: highlightedIndex === index,
        })}
      >
        <ItemLabel isSelected={selectedItem === item}>{item.label}</ItemLabel>
        {item.sublabel && <ItemSublabel>{item.sublabel}</ItemSublabel>}
      </Item>
    ))
  ) : (
    <NoItems>{noResults}</NoItems>
  )
}

const Dropdown = ({ children }) =>
  ReactDOM.createPortal(children, document.body)

export default SearchableSelect

// #region styles
const dropdownPosition = ({ inputPosition }) => css`
  top: ${inputPosition.bottom}px;
  left: ${inputPosition.left}px;
  width: ${inputPosition.width}px;
`

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`
const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`

const Input = styled.input`
  box-sizing: border-box;
  width: 100%;
  height: calc(4px * 8);
  padding: 0;
  padding-left: calc(4px);
  padding-right: calc(4px * 8);

  border-width: 1px;
  border-style: solid;
  border-color: ${({ disabled }) => (disabled ? '#E0E0E0' : validationColor)};
  border-radius: 4px;

  font-family: 'Nunito';
  font-size: 14px;
  color: ${({ disabled }) => (disabled ? '#E0E0E0' : '#333333')};
  ::placeholder {
    color: ${({ disabled }) => (disabled ? '#E0E0E0' : '#333333')};
    opacity: 1;
    font-family: 'Nunito';
  }
  :focus {
    outline: none;
    border-color: #003049;
  }
`

const StyledIcon = styled(Icon)`
  position: absolute;
  top: 10px;
  right: 8px;

  color: ${({ disabled }) => (disabled ? '#E0E0E0' : '#333333')};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`

const ItemList = styled.ul`
  position: absolute;
  ${dropdownPosition}

  max-height: calc(
    4px * 8 * ${({ displayedOptions }) => displayedOptions}
  );
  overflow: auto;
  padding: 0;
  margin: 0;
  margin-top: 2px;

  border: 1px solid #e0e0e0;
  border-radius: 4px;
  z-index: 400;
  list-style-type: none;
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  box-shadow: 0 2px 6px -1px rgba(125, 125, 125, 0.5);
`

const Item = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 calc(4px * 4);
  height: calc(4px * 8);

  background: ${({ isHighlighted }) => (isHighlighted ? '#F5F5F5' : 'white')};
  font-family: 'Nunito';
  font-size: 14px;
  cursor: pointer;
`

const ItemLabel = styled.span`
  margin-right: 16px;
  display: inline-block;
  align-items: center;

  color: #333333;
  font-weight: ${({ isSelected }) => (isSelected ? '700' : 'initial')};

  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const ItemSublabel = styled.span`
  display: flex;
  align-items: center;
  color: #4f4f4f;
`

const NoItems = styled.li`
  display: flex;
  align-items: center;
  padding: 0 calc(4px * 4);
  height: calc(4px * 8);

  color: #4f4f4f;
  background: #ffffff;
  font-family: 'Nunito';
  font-size: 13px;
  font-style: italic;
  cursor: default;
`
// #endregion
