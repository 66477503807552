import React, { useContext, useEffect, useState } from 'react'
import { get } from 'lodash'
import styled, { ThemeContext, css } from 'styled-components'
import { Row, Text, Icon } from '@hindawi/ui'
import { Field } from 'formik'
import PropTypes from 'prop-types'
import { Input } from '@hindawi/phenom-ui'

const ERROR_TYPES = {
  WARNING: 'warning',
  ERROR: 'error',
}

const errorState = (theme) => {
  const errorColor = get(theme, 'validation.errorColor')
  return (
    !!errorColor &&
    css`
      border-color: ${errorColor};
    `
  )
}

const warningState = (theme) => {
  const warningColor = get(theme, 'validation.warningColor')
  if (!warningColor) return null
  return (
    !!warningColor &&
    css`
      border-color: ${warningColor};
    `
  )
}

// the styling depends on the theme object set on the ThemeContext
const StyledField = styled(Field)`
  width: 250px;
  margin: 0;
  padding: 0 4px;
  display: flex;
  border-radius: ${({ theme }) => theme.borderRadius};
  &::placeholder {
    color: ${({ theme }) => theme.grey50};
  }
  ${({ invalidstate }) => invalidstate}
`

const Wrapper = styled(Row)`
  flex-direction: column;
`

export const AutoValidatedFormikField = ({
  errorType,
  setFieldValue,
  value,
  name,
  validators,
  icon,
  placeholder,
  disabled,
}) => {
  const theme = useContext(ThemeContext)
  const [error, setError] = useState(undefined)

  const validateFn = validators.length
    ? (value = '') => validators.reduce((acc, fn) => acc || fn(value), '')
    : (_) => undefined

  // normally we would use the Formik validateField method but
  // Formik does not know about warning level errors and for every error
  // it will disable the submit. This is why we do our own validation
  const validateField = (value) => {
    setError(validateFn(value))
  }

  useEffect(
    (_) => {
      validateField(value)
    },
    [value],
  )

  const handleChange = (e) => {
    setFieldValue(name, e.target.value)
  }

  const is_warning = errorType === ERROR_TYPES.WARNING
  const is_error = !is_warning

  let invalidState = ''
  let iconColor = 'validation.errorColor'
  if (error) {
    invalidState = errorState(theme)
    if (is_warning) {
      invalidState = warningState(theme)
      iconColor = 'validation.warningColor'
    }
  }

  return (
    <Wrapper>
      <Row justify="flex-start">
        <StyledField
          disabled={disabled}
          invalidstate={invalidState}
          name={name}
          onChange={handleChange}
          placeholder={placeholder}
          theme={theme}
          value={value || ''}
          as={Input}
        />
      </Row>
      {!!error && (
        <Row justify="flex-start">
          <Text error={is_error} warning={is_warning}>
            {!!icon && <Icon color={iconColor} icon={icon} mr={1} />}
            {error}
          </Text>
        </Row>
      )}
    </Wrapper>
  )
}

AutoValidatedFormikField.propTypes = {
  errorType: PropTypes.oneOf(Object.values(ERROR_TYPES)),
  setFieldValue: PropTypes.func.isRequired,
  value: PropTypes.string, // normally this should be required, but since the initial value is null, we will just have to be careful and set this when use the component
  name: PropTypes.string.isRequired,
  validators: PropTypes.arrayOf(PropTypes.func),
  icon: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
}

AutoValidatedFormikField.defaultProps = {
  icon: null,
  placeholder: '',
  errorType: ERROR_TYPES.ERROR,
  value: null,
  validators: [],
  disabled: false,
}
