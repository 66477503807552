import styled from 'styled-components'

import { Row } from '../'

const OverrideAlert = styled.div`
  div[role='alert'] {
    margin-top: 0;
  }
`
export const RowOverrideAlert = OverrideAlert.withComponent(Row)
