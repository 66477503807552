import { get, has } from 'lodash'
import { css } from 'styled-components'

export const positionHelper = css`
  position: ${(props) =>
    has(props, 'top', 'bottom', 'left', 'right') ? 'absolute' : 'initial'};
  top: ${(props) => (has(props, 'top') ? `${get(props, 'top')}px` : 'unset')};
  bottom: ${(props) =>
    has(props, 'bottom') ? `${get(props, 'bottom')}px` : 'unset'};
  left: ${(props) =>
    has(props, 'left') ? `${get(props, 'left')}px` : 'unset'};
  right: ${(props) =>
    has(props, 'right') ? `${get(props, 'right')}px` : 'unset'};
`

export const displayHelper = css`
  display: ${(props) => get(props, 'display', 'flex')};
`

export const radiusHelpers = (props) => {
  const borderTop = props.isFirst
    ? css`
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      `
    : css``

  const borderBottom = props.isLast
    ? css`
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      `
    : css``

  return css`
    border-radius: none;
    ${borderTop};
    ${borderBottom};
  `
}

export const heightHelper = (props) =>
  has(props, 'height')
    ? css`
        height: calc(4px * ${get(props, 'height', 8)});
      `
    : css`
        height: fit-content;
      `

export const widthHelper = (props) => {
  if (props.fitContent) {
    return css`
      width: fit-content;
    `
  }
  if (props.maxWidth) {
    return css`
      max-width: calc(4px * ${get(props, 'maxWidth')});
    `
  }
  if (props.minWidth !== undefined) {
    return css`
      min-width: calc(4px * ${get(props, 'minWidth')});
    `
  }
  if (props.width) {
    return css`
      width: calc(4px * ${get(props, 'width')});
    `
  }

  return css`
    width: 100%;
  `
}

export const colorHelper = ({ theme, color }) => css`
  color: ${get(theme, color, 'inherit')};
`

export const ellipsis = (props) => {
  if (props.ellipsis) {
    return css`
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    `
  }

  return css`
    white-space: ${(props) => get(props, 'whiteSpace', 'initial')};
  `
}

export const regular = () => css`
  font-weight: normal;
  font-style: normal;
`

export const regularItalic = () => css`
  font-weight: normal;
  font-style: italic;
`
