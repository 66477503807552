import { Col, IconRemove, Input } from '@hindawi/phenom-ui'
import React from 'react'
import { SEARCH_PLACEHOLDER } from '../constants'

export const SearchInput = ({ onSearch }) => (
  <Col width="full" minWidth="256px">
    <Input.Search
      data-test-id="search-input"
      allowClear={{
        clearIcon: <IconRemove />,
      }}
      placeholder={SEARCH_PLACEHOLDER}
      enterButton="Search"
      onSearch={onSearch}
    />
  </Col>
)
