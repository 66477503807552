import React from 'react'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import { space } from 'styled-system'
import styled from 'styled-components'

import { heightHelper, widthHelper } from '../styledHelpers'

/** @component */
const RowElement = styled.div`
  align-items: ${(props) => get(props, 'alignItems', 'center')};
  background-color: ${(props) => props.bgColor || 'transparent'};
  display: ${(props) => props.display || 'flex'};
  flex-wrap: ${(props) => get(props, 'flexWrap', 'initial')};
  justify-content: ${(props) => get(props, 'justify', 'space-evenly')};
  flex-direction: ${(props) => get(props, 'flexDirection', 'row')};

  ${heightHelper};
  ${widthHelper}
  ${space};
`

const Row = ({
  alignItems = 'center',
  bgColor = 'transparent',
  flexWrap = 'initial',
  justify = 'space-evenly',
  flexDirection = 'row',
  ...props
}) => (
  <RowElement
    alignItems={alignItems}
    bgColor={bgColor}
    flexWrap={flexWrap}
    justify={justify}
    flexDirection={flexDirection}
    {...props}
  />
)

Row.propTypes = {
  /** Defines how flex items are laid out along the seconday axis. */
  alignItems: PropTypes.string,
  /** Defines the background color. */
  bgColor: PropTypes.string,
  /** Sets whether flex items are forced onto one line or can wrap on multiple ones. */
  flexWrap: PropTypes.string,
  /** Specifies alignment along the main axis. */
  justify: PropTypes.string,
}

export default Row
