import { withStateHandlers } from 'recompose'

export default withStateHandlers(
  {
    isFetching: false,
    fetchingError: '',
  },
  {
    setFetching:
      ({ isFetching }) =>
      (value) => ({
        isFetching: value,
      }),
    toggleFetching:
      ({ isFetching }) =>
      () => ({
        isFetching: !isFetching,
      }),
    setError:
      ({ fetchingError }) =>
      (e) => ({
        fetchingError: e,
      }),
    clearError:
      ({ fetchingError }) =>
      () => ({
        fetchingError: '',
      }),
  },
)
