import {
  Card,
  Flex,
  IconTooltip,
  Popover,
  Radio,
  Text,
  Row,
} from '@hindawi/phenom-ui'
import React from 'react'
import styled from 'styled-components'
import {
  BinCategory,
  BinKeys,
  Permission,
  PermissionKeys,
  PermissionsInfo,
} from 'component-dashboard/client/components/editorial-dashboard/types'

const BinRadioList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: start;
`

const RadioContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const CustomizedRow = styled(Row)`
  box-shadow: 0px 4px 8px 0px rgba(51, 51, 51, 0.11);
`

const BinRadio = ({ binKey, bin, binCategory, onChangeBin, info }) => (
  <Radio
    data-test-id={`bin-radio-${binKey}`.toLowerCase()}
    key={binKey}
    checked={bin === binKey}
    value={binKey}
    onChange={(event) => onChangeBin(event?.target.value)}
  >
    <Flex gap={4} align="center">
      {binCategory[binKey]}
      {info !== undefined && info[binKey].length > 0 && (
        <Popover
          placement="right"
          content={
            <CustomizedRow width="260px" pb={12} pl={12} pr={12} pt={12}>
              {info[binKey]}
            </CustomizedRow>
          }
        >
          <IconTooltip />
        </Popover>
      )}
    </Flex>
  </Radio>
)

export const BinCard = ({
  bin,
  binCategory,
  onChangeBin,
  binList,
  getCountByBin,
  visible = true,
  info,
}: {
  bin: PermissionKeys | BinKeys
  binCategory: any
  binList: Permission[] | BinCategory[]
  onChangeBin: Function
  getCountByBin: Function
  visible?: boolean
  info?: PermissionsInfo
}) => {
  const countByBin = getCountByBin()
  if (!visible) {
    return <></>
  }
  return (
    <Card data-test-id="bins-card" style={{ boxShadow: 'none' }}>
      <BinRadioList data-test-id="bin-radio-list">
        {binList.map((binKey) => (
          <RadioContainer data-test-id="bin-radio-container" key={binKey}>
            <BinRadio
              binKey={binKey}
              bin={bin}
              binCategory={binCategory}
              onChangeBin={onChangeBin}
              info={info}
            />
            <Text
              data-test-id={`bin-total-${binKey}`.toLowerCase()}
              className={bin === binKey ? `bold-small` : 'bold-small-grey'}
            >
              {countByBin[binKey]}
            </Text>
          </RadioContainer>
        ))}
      </BinRadioList>
    </Card>
  )
}
