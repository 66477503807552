import React from 'react'
import { Text, Title, Tooltip } from '@hindawi/phenom-ui'

export const TitleCell = ({
  title,
  ellipsisRows,
  fontSize,
  lineHeight,
}: {
  title: string
  ellipsisRows: number
  fontSize: number
  lineHeight: number
}) => (
  <Text className="bold">
    <Tooltip
      copyContent={title}
      showCopyContent
      replaceTooltipText="Manuscript Title"
    >
      <Title
        data-test-id="manuscript-title"
        onClick={(event) => {
          event.stopPropagation()
        }}
        className="manuscript-title"
        style={{
          color: '#0a7388',
          fontWeight: '700',
          fontSize: `${fontSize}px`,
          lineHeight: `${lineHeight}px`,
        }}
        ellipsis={{ rows: ellipsisRows }}
        level={5}
      >
        {title}
      </Title>
    </Tooltip>
  </Text>
)
