import React, { useState } from 'react'
import { RorAutocomplete, Form, IconCaretDown, Input } from '@hindawi/phenom-ui'
import styled from 'styled-components'

// This may look weird but we're using it for sharing state with interval Formik state mechanism
// Because of the way Formik works we need to keep it here
// Each time we update a Formik field it triggers a re-render on the whole form which creates a lot of issues
// with keeping track of state for Ror inside renderRor
// keeping track of state in AuthorCardEdit did not seem to be working either
export class RorInfoState {
  aff
  affRorId

  constructor() {
    this.aff = ''
    this.affRorId = ''
  }

  getData() {
    return {
      aff: this.aff,
      affRorId: this.affRorId,
    }
  }

  setData(updates) {
    if (updates.aff !== undefined) {
      this.aff = updates.aff
    }

    if (updates.affRorId !== undefined) {
      this.affRorId = updates.affRorId
    }
  }

  resetData() {
    this.aff = ''
    this.affRorId = ''
  }
}

export const renderRor =
  ({
    initialValues,
    rorInfo,
    dropdownMatchSelectWidth,
    dataTestId = '',
    placeholder = '',
  }) =>
  // eslint-disable-next-line react/display-name
  (props) => {
    const { aff, affRorId } = rorInfo.getData()
    const [selectedRor, setSelectedRor] = useState({
      name: initialValues.aff || aff,
      id: initialValues.affRorId || affRorId,
    })

    const handleUpdates = ({ name, id }) => {
      setSelectedRor({ name, id })

      rorInfo.setData({
        aff: name,
        affRorId: name === '' ? '' : id,
      })
    }

    return (
      <RorWrapper>
        <Form.Item validateStatus={props.validationStatus}>
          <RorAutocomplete
            data-test-id={dataTestId || 'affiliation-author'}
            dropdownMatchSelectWidth={dropdownMatchSelectWidth}
            selectedRor={selectedRor}
            setSelectedRor={handleUpdates}
          >
            <Input placeholder={placeholder} suffix={<IconCaretDown />} />
          </RorAutocomplete>
        </Form.Item>
      </RorWrapper>
    )
  }

const RorWrapper = styled.div`
  width: 100%;

  > .ant-form-item {
    margin-bottom: 0;
  }

  .ant-row {
    margin-bottom: 0;
  }
`
