import React from 'react'
import { FourOFour } from '@hindawi/phenom-ui'
import { useJournal } from '../../component-journal-info'

const FourOFourPage = ({ history }) => {
  const { supportEmail } = useJournal()
  const goToDashboard = () => history.push('/')

  return (
    <FourOFour
      supportEmail={supportEmail}
      actionButton={{
        name: 'GO TO DASHBOARD',
        action: goToDashboard,
      }}
      websiteURL={''}
      supportText="In case of any urgent situation contact "
    />
  )
}

export default FourOFourPage
