import { useEffect, useState } from 'react'

type TitleAspectsMapperType = {
  minWidth: number
  rows: number
  fontSize: number
  lineHeight: number
}

type OmittedWidthAspectsType = Omit<TitleAspectsMapperType, 'minWidth'>

const TitleAspectsMapper: TitleAspectsMapperType[] = [
  { minWidth: 1024, rows: 2, fontSize: 16, lineHeight: 24 },
  { minWidth: 768, rows: 3, fontSize: 16, lineHeight: 24 },
  { minWidth: 580, rows: 4, fontSize: 16, lineHeight: 24 },
  { minWidth: 400, rows: 5, fontSize: 14, lineHeight: 20 },
  { minWidth: 0, rows: 6, fontSize: 14, lineHeight: 20 },
]

const calculateAspects = () => {
  const windowWidth = window.innerWidth
  const properSize = TitleAspectsMapper.find(
    ({ minWidth }) => windowWidth >= minWidth,
  )

  return {
    rows: properSize?.rows ?? 2,
    fontSize: properSize?.fontSize ?? 24,
    lineHeight: properSize?.lineHeight ?? 24,
  }
}

export const useGetTitleAspects = (): OmittedWidthAspectsType => {
  const [titleAspects, setTitleAspects] =
    useState<OmittedWidthAspectsType>(calculateAspects())

  useEffect(() => {
    const handleResize = () => {
      setTitleAspects(calculateAspects())
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return titleAspects
}
