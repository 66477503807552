import { ClientJS } from 'clientjs'

const fingerprintCookieName = 'BROWSER_FINGERPRINT'

const setCookie = (name, value, days) => {
  const d = new Date()
  d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000)
  const expires = 'expires=' + d.toUTCString()
  document.cookie = name + '=' + value + ';' + expires + ';path=/'
}

const getCookieValue = (name) => {
  const cookieName = name + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const cookieArray = decodedCookie.split(';')
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i]
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1)
    }
    if (cookie.indexOf(cookieName) === 0) {
      return cookie.substring(cookieName.length, cookie.length)
    }
  }
  return null
}

// Return a 32-bit integer representing the browsers fingerprint converted to string.
const generateBrowserFingerprint = () => {
  const browserFingerprint = getCookieValue(fingerprintCookieName)

  if (!browserFingerprint) {
    const client = new ClientJS()
    const newBrowserFingerprint = client.getFingerprint().toString()

    setCookie(fingerprintCookieName, newBrowserFingerprint, 7)
  }
}

export { generateBrowserFingerprint, getCookieValue }
