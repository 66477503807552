export type StatusT = {
  checked?: boolean
  label: string
  value: string
}

export type SelectStatusCategoryT = {
  label: string
  selectedValues: string[]
}

export type StatusCategoryT = {
  label: string
  statuses: StatusT[]
}

export type SelectedStatusCategoriesT = SelectStatusCategoryT[] & {
  flatMap?: (args: any) => [] | never[] // ts does not know about flatMap :(
}

type getVisibleStatusForManuscriptT = (manuscriptStatus: string) => string

export const statusCategories: StatusCategoryT[] = [
  {
    label: 'Submission & Screening',
    statuses: [
      {
        label: 'In Screening',
        value: 'technicalChecks',
      },
      {
        label: 'In Revision Checks',
        value: 'revisionChecks',
      },
    ],
  },
  {
    label: 'In Review',
    statuses: [
      {
        label: 'Assign AE',
        value: 'submitted',
      },
      {
        label: 'Pending Editor Response',
        value: 'academicEditorInvited',
      },
      {
        label: 'Invite Reviewers',
        value: 'academicEditorAssigned',
      },
      {
        label: 'Pending Reviewer response',
        value: 'reviewersInvited',
      },
      {
        label: 'Under Review',
        value: 'underReview',
      },
      {
        label: 'Awaiting Recommendation',
        value: 'reviewCompleted',
      },
      {
        label: 'Revision Requested',
        value: 'revisionRequested',
      },
      {
        label: 'Revision Submitted',
        value: 'revisionSubmitted',
      },
      {
        label: 'Awaiting Decision',
        value: 'pendingApproval,makeDecision',
      },
    ],
  },
  {
    label: 'Post Review',
    statuses: [
      {
        label: 'In Quality Checks',
        value: 'inQA',
      },
      {
        label: 'Accepted',
        value: 'accepted,qualityChecksSubmitted,qualityChecksRequested',
      },
    ],
  },
  {
    label: 'Final',
    statuses: [
      {
        label: 'Published',
        value: 'published',
      },
      {
        label: 'Refused To Consider',
        value: 'refusedToConsider',
      },
      {
        label: 'Rejected',
        value: 'rejected',
      },
      {
        label: 'Withdrawn',
        value: 'withdrawn',
      },
      {
        label: 'Deleted',
        value: 'deleted',
      },
      {
        label: 'Void',
        value: 'void',
      },
    ],
  },
]

export const allEaStatuses: StatusT[] = statusCategories.flatMap(
  ({ statuses }) => statuses,
)

const inReviewOptions = [
  'submitted',
  'academicEditorInvited',
  'academicEditorAssigned',
  'reviewersInvited',
  'underReview',
  'reviewCompleted',
  'revisionRequested',
  'revisionSubmitted',
  'pendingApproval,makeDecision',
]

export const initialSelectedStatusCategories = statusCategories.map(
  ({ label }) => ({
    label,
    selectedValues: label === 'In Review' ? inReviewOptions : [],
  }),
)

export const getVisibleStatusForManuscript: getVisibleStatusForManuscriptT = (
  manuscriptStatus,
) =>
  allEaStatuses.find((status) => status.value.includes(manuscriptStatus))
    ?.label || ''
