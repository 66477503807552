export const KEYCLOAK_ROLES = Object.freeze({
  ADMIN: 'admin',
  EA: 'editorial_assistant',
  AE: 'academic_editor',
  JM: 'journal_manager',
  TE: 'triage_editor',
})

export const ROLES = Object.freeze({
  USER: 'user',
  ADMIN: 'admin',
  AUTHOR: 'author',
  REVIEWER: 'reviewer',
  TE: 'triageEditor',
  AE: 'academicEditor',
  EA: 'editorialAssistant',
  SUBMITTING_STAFF_MEMBER: 'submittingStaffMember',
})
