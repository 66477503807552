import { withProps } from 'recompose'
import { get } from 'lodash'

export default withProps(({ role, academicEditor }) => {
  const academicEditorStatus = get(academicEditor, 'status', '')
  let academicEditorVisibleStatus = 'Unassigned'
  if (academicEditorStatus === 'accepted') {
    academicEditorVisibleStatus = `${get(
      academicEditor,
      'alias.name.surname',
      '',
    )} ${get(academicEditor, 'alias.name.givenNames', '')}`
  } else if (role === 'author' && academicEditorStatus === 'pending') {
    academicEditorVisibleStatus = 'Assigned'
  } else if (academicEditorStatus === 'pending') {
    academicEditorVisibleStatus = 'Invited'
  }
  return {
    academicEditorVisibleStatus,
  }
})
