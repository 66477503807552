import React, { Fragment } from 'react'
import { Formik } from 'formik'
import { isNumber, get } from 'lodash'
import styled from 'styled-components'
import { compose, withHandlers, withProps } from 'recompose'
import {
  Checkbox,
  CountrySelector,
  Input,
  Preset,
  Spinner,
  Title,
} from '@hindawi/phenom-ui'

import {
  Row,
  Text,
  Icon,
  Item,
  Label,
  validators,
  ValidatedFormField,
} from '../'

import { RorInfoState, renderRor } from './ror.helpers'

const rorInfo = new RorInfoState()

const submitHandler =
  ({ setFieldValue, setTouched, submitForm, initialValues }) =>
  () => {
    if (initialValues.aff && rorInfo.getData().aff === '') {
      // means we're in the edit mode and not touching aff field
      rorInfo.setData({
        aff: initialValues.aff,
        affRorId: initialValues.affRorId,
      })
    }

    const { aff, affRorId } = rorInfo.getData()

    // Ror component is quite custom so we need to manually trigger
    // updates for Formik fields
    setFieldValue('aff', aff)
    setFieldValue('affRorId', affRorId)

    // we need to do this manually since Formik doesn't seem to be doing it for some reason
    setTouched({ aff: true })

    // because we manually update aff field with `setFieldValue`, that triggers some update mechanism
    // inside Formik and prevents it from triggering `submitForm`
    // this is why we need to wait a bit for that action to clear up and then be able to run submit form action
    setTimeout(() => {
      submitForm().then(() => {
        rorInfo.resetData()
      })
    }, 10)
  }

const AuthorCardEdit = ({
  index,
  saveAuthor,
  isFetching,
  cancelEdit,
  fetchingError,
  initialValues,
  isAuthorEmailEditable,
}) => {
  const handleCancelEdit = () => {
    rorInfo.resetData()
    cancelEdit()
  }

  return (
    <Formik initialValues={initialValues} onSubmit={saveAuthor(index)}>
      {({ setFieldValue, setTouched, submitForm }) => (
        <Fragment>
          <Row data-testid="new-user" justify="space-between" mb={1}>
            <Row justify="flex-start">
              <Title preset={Preset.SMALL}>
                {isNumber(index) ? `#${index + 1} Author` : 'Author'}
              </Title>
              <ValidatedFormField
                component={(input) => (
                  <Checkbox
                    style={{ marginLeft: '10px' }}
                    checked={input.value}
                    {...input}
                    label="Corresponding"
                  >
                    Corresponding
                  </Checkbox>
                )}
                name="isCorresponding"
              />
            </Row>

            {fetchingError && (
              <Row>
                <Text error>{fetchingError}</Text>
              </Row>
            )}

            {isFetching ? (
              <StyledSpinner>
                <Spinner size="small" />
              </StyledSpinner>
            ) : (
              <Fragment>
                <Icon
                  data-test-id={`cancel-edit-${index}`}
                  fontSize="16px"
                  icon="remove"
                  mr={3}
                  onClick={handleCancelEdit}
                />

                <Icon
                  data-test-id={`save-author-${index}`}
                  fontSize="16px"
                  icon="save"
                  mr={1}
                  onClick={submitHandler({
                    setFieldValue,
                    setTouched,
                    submitForm,
                    initialValues,
                  })}
                />
              </Fragment>
            )}
          </Row>
          <Fragment>
            <Row>
              <Item mr={1} vertical>
                <Label required>Email</Label>
                <ValidatedFormField
                  component={Input}
                  data-test-id="email-author"
                  disabled={!isAuthorEmailEditable && initialValues.email}
                  inline
                  name="email"
                  validate={[validators.required, validators.emailValidator]}
                />
              </Item>

              <Item mr={1} vertical>
                <Label required>First Name</Label>
                <ValidatedFormField
                  component={Input}
                  data-test-id="givenNames-author"
                  inline
                  name="givenNames"
                  validate={[validators.required]}
                />
              </Item>

              <Item mr={1} vertical>
                <Label required>Last Name</Label>
                <ValidatedFormField
                  component={Input}
                  data-test-id="surname-author"
                  inline
                  name="surname"
                  validate={[validators.required]}
                />
              </Item>

              <Item mr={1} vertical>
                <Label required>Affiliation</Label>
                <ValidatedFormField
                  component={renderRor({
                    initialValues,
                    rorInfo,
                    dropdownMatchSelectWidth: 392,
                  })}
                  data-test-id="affiliation-author"
                  inline
                  name="aff"
                  validate={[validators.required]}
                />
              </Item>

              <Item mr={1} vertical>
                <Label required>Country</Label>
                <ValidatedFormField
                  component={CountrySelector}
                  data-test-id="country-author"
                  inline
                  name="country"
                  validate={[validators.required]}
                  style={{ width: '200px' }}
                />
              </Item>
            </Row>
          </Fragment>
        </Fragment>
      )}
    </Formik>
  )
}

export default compose(
  withProps(({ item }) => ({
    initialValues: {
      aff: get(item, 'alias.aff', ''),
      affRorId: get(item, 'alias.affRorId', ''),
      email: get(item, 'alias.email', ''),
      country: get(item, 'alias.country', ''),
      surname: get(item, 'alias.name.surname', ''),
      givenNames: get(item, 'alias.name.givenNames', ''),
      isSubmitting: get(item, 'isSubmitting'),
      isCorresponding: get(item, 'isCorresponding'),
    },
  })),
  withHandlers({
    changeCorresponding:
      ({ setCorresponding, item, ...props }) =>
      () => {
        setCorresponding(item, props)
      },
  }),
)(AuthorCardEdit)

// #region styles
const StyledSpinner = styled.div`
  position: absolute;
  right: calc(4px * 4);
  top: calc(4px * 2);
`
// #endregion
