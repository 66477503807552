import React from 'react'
import styled from 'styled-components'

const StyledStatusTextContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  padding: 0;
`

function StatusTextContainer({ children }) {
  return <StyledStatusTextContainer>{children}</StyledStatusTextContainer>
}

export default StatusTextContainer
