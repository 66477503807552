import React from 'react'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { countries } from '@hindawi/phenom-ui'
import { Text, Row, Label, Item } from '../'

const PersonInfo = ({ person }) => {
  const aff = get(person, 'alias.aff', '')
  const email = get(person, 'alias.email', '')
  const surname = get(person, 'alias.name.surname', '')
  const givenNames = get(person, 'alias.name.givenNames', '')
  const country = countries.getName(person?.alias?.country || '')

  return (
    <Row>
      <WithEllipsis mr={1} vertical>
        <Label>Email</Label>
        <Text ellipsis title={email}>
          {email}
        </Text>
      </WithEllipsis>
      <Item mr={1} vertical>
        <Label>First name</Label>
        <Text title={givenNames}>{givenNames}</Text>
      </Item>
      <Item mr={1} vertical>
        <Label>Last name</Label>
        <Text>{surname}</Text>
      </Item>
      <WithEllipsis mr={1} vertical>
        <Label>Affiliation</Label>
        <Text ellipsis title={aff}>
          {aff}
        </Text>
      </WithEllipsis>
      <WithEllipsis vertical>
        <Label>Country</Label>
        <Text ellipsis>{country}</Text>
      </WithEllipsis>
    </Row>
  )
}

PersonInfo.propTypes = {
  /** Person with information */
  person: PropTypes.shape({
    id: PropTypes.string,
    isCorresponding: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    alias: PropTypes.shape({
      aff: PropTypes.string,
      email: PropTypes.string,
      country: PropTypes.string,
      name: PropTypes.shape({
        givenNames: PropTypes.string,
        surname: PropTypes.string,
        title: PropTypes.string,
      }),
    }),
  }).isRequired,
}

export default PersonInfo

const WithEllipsis = styled(Item)`
  display: inline-grid;
  flex-direction: column;
  flex: 1;
`
// #endregion
