import { useCallback, useEffect, useState } from 'react'

const getSize = (el) =>
  el
    ? el.getBoundingClientRect()
    : { bottom: 0, height: 0, left: 0, right: 0, top: 0, width: 0, x: 0, y: 0 }

const useClientRect = () => {
  const [node, setNode] = useState()
  const [rect, setRect] = useState(getSize(node))

  const handleResizeAndScroll = useCallback(
    () => setRect(getSize(node)),
    [node],
  )

  useEffect(() => {
    if (node) {
      setRect(getSize(node))
    }
  }, [node])

  useEffect(() => {
    window.addEventListener('resize', handleResizeAndScroll)
    window.addEventListener('scroll', handleResizeAndScroll)

    // subscribe to scroll events on all parents, since any of them may have
    // overflow: scroll and mess up the popup's positioning
    const listenedParents = []
    if (node) {
      let parent = node.parentNode
      while (parent) {
        listenedParents.push(parent)
        parent.addEventListener('scroll', handleResizeAndScroll)
        parent = parent.parentNode
      }
    }

    return () => {
      window.removeEventListener('resize', handleResizeAndScroll)
      window.removeEventListener('scroll', handleResizeAndScroll)
      listenedParents.forEach((parent) =>
        parent.removeEventListener('scroll', handleResizeAndScroll),
      )
    }
  }, [node, handleResizeAndScroll])

  return [setNode, rect]
}

export default useClientRect
