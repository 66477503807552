import React from 'react'
import { space } from 'styled-system'
import styled from 'styled-components'

import { heightHelper } from '../'
import { Preset, Title } from '@hindawi/phenom-ui'

const Label = ({ children, height = null, required = false, ...rest }: any) => (
  <Root height={height} {...rest}>
    <Title preset={Preset.SMALL}>{children}</Title>
    {required && <Required height={height}>*</Required>}
  </Root>
)

export default Label

// #region styles
const Required = styled.span<{
  height: number | null
}>`
  color: #fc6a4b;
  margin-left: calc(4px / 2);
  ${heightHelper};
`

const Root = styled.div<{
  height: number | null
  disabled?: boolean
}>`
  align-items: center;
  display: flex;
  text-align: center;
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
  ${heightHelper};
  ${space};
`
// #endregion
