import { useState } from 'react'

export type Option = {
  value: string
  displayValue: string
}

export type ValueT = Option | string | number

export const initialOption: Option = {
  value: '',
  displayValue: '',
}

export const useFiltersState = () => {
  const [selectedManuscript, setSelectedManuscript] = useState(initialOption)
  const widgetManuscriptByTitle = {
    value: selectedManuscript,
    set: setSelectedManuscript,
  }

  const [customId, setCustomId] = useState('')
  const widgetManuscriptId = {
    value: customId,
    set: setCustomId,
  }

  return {
    widgetManuscriptByTitle,
    widgetManuscriptId,
  }
}
