import React from 'react'
import { get } from 'lodash'
import { Redirect } from 'react-router-dom'
import { Row, ShadowedBox, Text } from '@hindawi/ui'
import { Button, Title } from '@hindawi/phenom-ui'

const InfoPage = ({ location, history }) => {
  const pathState = get(location, 'state', {})
  if (!pathState.title) {
    return <Redirect to="/" />
  }
  return (
    <ShadowedBox center mt={10} pb={6} pt={6}>
      <Title level={4} style={{ textAlign: 'center' }}>
        {pathState.title}
      </Title>
      <Row mt={2}>
        <Text>{pathState.content}</Text>
      </Row>

      {pathState.path && (
        <Row mt={4}>
          <Button onClick={() => history.push(pathState.path)} type="primary">
            {pathState.buttonText}
          </Button>
        </Row>
      )}
    </ShadowedBox>
  )
}

export default InfoPage
