// @ts-nocheck
import { useContext } from 'react'
import { useLazyQuery } from 'react-apollo'
import { queries } from '../../../graphql'
import { DashboardContext } from '../'
import { searchManuscriptInputResolverI } from '../manuscripts'
import { useCurrentUser } from '../../../../../component-authentication/client'

export const useStatusesCountManager = () => {
  const { value, set } = useContext(DashboardContext).statusesCount
  const query = 'getStatusesManuscriptsCount'

  const setStatusesCount = (data) => {
    const statusesCount = data[query].reduce((statuses, currentPair) => {
      statuses[currentPair.status] = currentPair.count
      return statuses
    }, {})
    set(statusesCount)
  }

  const [queryStatuses, { data, loading }] = useLazyQuery(queries[query], {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      setStatusesCount(data)
    },
  })

  const currentUser = useCurrentUser()

  const getStatusesCount = ({
    submittingStaffMemberId,
    authorEmail,
    academicEditorEmail,
    academicEditorStatus,
    journalId,
    sectionId,
    specialIssueId,
  }: Partial<searchManuscriptInputResolverI>) =>
    queryStatuses({
      variables: {
        input: {
          editorialAssistantId:
            currentUser.role === 'editorialAssistant'
              ? currentUser.id
              : undefined,
          submittingStaffMemberId,
          authorEmail: authorEmail || undefined,
          academicEditorEmail: academicEditorEmail || undefined,
          academicEditorStatus: academicEditorStatus || undefined,
          isLatestVersion: true,
          journalId,
          sectionId,
          specialIssueId,
        },
      },
    })

  const reset = () => {
    set({})
  }

  return {
    set,
    reset,
    fetch: getStatusesCount,
    value,
    loading,
  }
}
