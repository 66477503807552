import { get } from 'lodash'
import PropTypes from 'prop-types'
import { space } from 'styled-system'
import styled, { css } from 'styled-components'

const tagCSS = (props) => {
  if (get(props, `status`)) {
    return css`
      background-color: #dbafc1;
      padding: calc(4px / 2) 4px;
      height: calc(4px * 6);
      display: flex;
      align-items: center;
    `
  }

  if (get(props, `pending`)) {
    return css`
      background-color: #939393;
      height: calc(4px * 6);
      width: calc(4px * 20);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      border-radius: 4px;
    `
  }

  if (get(props, `dateLabel`)) {
    return css`
      background-color: #f5f5f5;
      padding: calc(4px / 2) 4px;
      font-weight: 700;
    `
  }

  if (get(props, `authorTag`)) {
    return css`
      background-color: #4f4f4f;
      font-weight: 700;
    `
  }

  return css`
    background-color: #586971;
  `
}

/** @component */
const Tag = styled.div`
  border-radius: 2px;
  color: #ffffff;
  display: initial;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  font-family: 'Nunito';
  padding: 1px 2px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;

  ${tagCSS};
  ${space};
`

Tag.propTypes = {
  /** Old status of the corresponding user. */
  oldStatus: PropTypes.bool,
  /** New status of the corresponding user. */
  status: PropTypes.bool,
}

Tag.defaultProps = {
  oldStatus: false,
  status: false,
}

/** @component */
export default Tag
