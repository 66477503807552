// @ts-nocheck
import { useContext, useMemo } from 'react'
import { DashboardWidgetContext } from '../'
import { initialOption, Option } from './state'
import { ManagerApiI } from '../../'
import {
  useWidgetManuscripts,
  searchManuscriptInputResolverI,
} from '../widgetManuscripts'
import { useCurrentUser } from '../../../../../component-authentication/client'
const useWidgetFilterManager = ({ context }) => {
  const { value, set } = context

  const reset = () => {
    set(initialOption) // or empty string, pass from manager
  }

  return {
    value,
    set,
    reset,
  }
}

export const useManuscriptByTitleManager: () => ManagerApiI<
  Option,
  null
> = () => {
  const context = useContext(DashboardWidgetContext).widgetManuscriptByTitle
  return useWidgetFilterManager({ context })
}

export const useManuscriptCustomIdManager: () => ManagerApiI<
  string,
  null
> = () => {
  const context = useContext(DashboardWidgetContext).widgetManuscriptId
  const manager = useWidgetFilterManager({ context })
  const reset = () => manager.set('')
  return {
    ...manager,
    reset,
  }
}

export const useWidgetFiltersManager = () => {
  const manuscripts = useWidgetManuscripts()

  const manuscriptByTtitle = useManuscriptByTitleManager()
  const manuscriptCustomId = useManuscriptCustomIdManager()
  const currentUser = useCurrentUser()

  const searchManuscripts = () => {
    if (!manuscriptByTtitle.value.value && !manuscriptCustomId.value.length) {
      return
    }
    const payload: Partial<searchManuscriptInputResolverI> = {
      id: manuscriptByTtitle.value.value || undefined,
      customId: manuscriptCustomId.value || undefined,
      role: currentUser.role,
    }

    manuscripts.fetch(payload)
  }

  useMemo(
    () => searchManuscripts(),
    [manuscriptByTtitle.value.value, manuscriptCustomId.value],
  )

  return {
    manuscriptByTtitle,
    manuscriptCustomId,
    manuscripts,
    searchManuscripts,
  }
}
