import { Button, Collapse, IconRemove, Spinner } from '@hindawi/phenom-ui'
import React from 'react'
import { FiltersManagerAPII } from '../../../store/EADashboard/filters'
import {
  statusCategories,
  StatusCategoryT,
  StatusT,
} from '../../../store/EADashboard/filters/statusCategories'
import {
  CheckboxGroup,
  CheckboxGroupWrapper,
  Header,
  StyledLabel,
  Title,
} from './styles'

const { Panel } = Collapse

export const StatusFilters: React.FC<{
  filters: FiltersManagerAPII
  statusesCount: any
}> = ({ filters, statusesCount }) => {
  const { statuses } = filters

  const { value: statusesCountValue, loading: statusesCountLoading } =
    statusesCount

  const {
    set: updateSelectedStatusCategories,
    reset,
    value: selectedStatusCategories,
  } = statuses

  interface AddCountI {
    status: StatusT
    loading: boolean
    count: number
  }

  interface ResultingStatusI {
    label: string | JSX.Element
    value: string
  }

  const addCount = ({
    status,
    loading,
    count,
  }: AddCountI): ResultingStatusI => {
    const countLabel = loading ? (
      <span className="spinner-wrapper">
        <Spinner />
      </span>
    ) : (
      `${count || 0}`
    )

    return {
      value: status.value,
      label: (
        <StyledLabel>
          <span>{status.label}</span>
          <span className="count">{countLabel}</span>
        </StyledLabel>
      ),
    }
  }

  const countManuscripts = (status: StatusT) => {
    if (status.value.includes(',')) {
      const composedStatuses = status.value.split(',')
      return composedStatuses.reduce(
        (count, currentStatus) =>
          count + (statusesCountValue[currentStatus] || 0),
        0,
      )
    }
    return statusesCountValue[status.value] || 0
  }

  const parseStatuses = (statuses: StatusT[]) =>
    statuses.map((status) =>
      addCount({
        status,
        loading: statusesCountLoading,
        count: countManuscripts(status),
      }),
    )

  const handleCheckboxChange =
    (categoryLabel: string) => (newValues: string[]) => {
      const updatedSelectedStatusCategories = selectedStatusCategories.map(
        (category) =>
          category.label === categoryLabel
            ? { ...category, selectedValues: newValues }
            : category,
      )

      updateSelectedStatusCategories(updatedSelectedStatusCategories)
    }

  const getSelectedValuesByLabel = (label: string) => {
    const selectedValuesResults = selectedStatusCategories.find(
      (category) => category.label === label,
    )
    if (!selectedValuesResults) return []

    return selectedValuesResults.selectedValues
  }

  return (
    <div>
      <Header>
        <Title>Manuscripts status</Title>
        <Button
          data-test-id="reset-all-statuses-button"
          icon={<IconRemove />}
          onClick={reset}
          type="link"
        >
          Reset all
        </Button>
      </Header>
      <Collapse
        accordion
        defaultActiveKey={['In Review']}
        expandIconPosition="end"
      >
        {statusCategories.map((category: StatusCategoryT) => (
          <Panel header={category.label} key={category.label}>
            <CheckboxGroupWrapper direction="vertical" size="small">
              <CheckboxGroup
                // @ts-ignore
                onChange={handleCheckboxChange(category.label)}
                options={parseStatuses(category.statuses)}
                value={getSelectedValuesByLabel(category.label)}
              />
            </CheckboxGroupWrapper>
          </Panel>
        ))}
      </Collapse>
    </div>
  )
}

export default StatusFilters
