import React from 'react'
import { OrderCategory } from '../types'
import { Preset, Select, Space, Text } from '@hindawi/phenom-ui'
import StatusTextContainer from './StatusTextContainer'

export const OrderSelect = ({
  onChangeOrder,
  chosenOrder,
}: {
  onChangeOrder: (value: string) => void
  chosenOrder: string
}) => (
  <Space direction="vertical" size={4} data-test-id="order-select">
    <StatusTextContainer>
      <Text preset={Preset.BOLD} data-test-id="order-select-title">
        Sort by
      </Text>
    </StatusTextContainer>
    <Select
      data-test-id="dashboard-filter-order"
      onChange={onChangeOrder}
      value={chosenOrder}
      style={{
        width: '248px',
      }}
    >
      {[
        OrderCategory.UPDATED_DATE_OLDEST_FIRST,
        OrderCategory.UPDATED_DATE_NEWEST_FIRST,
        OrderCategory.SUBMISSION_DATE_OLDEST_FIRST,
        OrderCategory.SUBMISSION_DATE_NEWEST_FIRST,
      ].map((orderKey) => {
        return (
          <Select.Option value={orderKey} key={orderKey}>
            {OrderCategory[orderKey]}
          </Select.Option>
        )
      })}
    </Select>
  </Space>
)
