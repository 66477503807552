import React from 'react'
import PropTypes from 'prop-types'
import { space } from 'styled-system'
import styled from 'styled-components'

import { Icon } from '../'

const DragHandle = (props) => (
  <Handle {...props}>
    <Icon color="colorSecondary" fontSize="17px" icon="move" />
  </Handle>
)

DragHandle.displayName = 'DragHandle'

DragHandle.protoTypes = {
  /** Designed size for icon */
  size: PropTypes.number,
}
DragHandle.defaultProps = {
  size: 2,
}

export default DragHandle

// #region styles
const Handle = styled.div`
  align-self: stretch;
  align-items: center;
  background-color: transparent;
  border-right: 1px solid #e0e0e0;
  cursor: move;
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: calc(4px * 8);

  span {
    padding: 0;
  }

  ${space};
`
// #endregion
