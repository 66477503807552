import React from 'react'
import { last } from 'lodash'
import { withProps, withHandlers, compose } from 'recompose'

import { withSignedUrl } from '../graphql/withGQL'
import { IconPreview } from '@hindawi/phenom-ui'

const hasPreview = (originalName = '') => {
  const extension = last(originalName.split('.')).toLocaleLowerCase()
  return ['pdf', 'png', 'jpg'].includes(extension)
}

const PreviewFile = ({
  onPreview,
  hasPreview,
  getSignedUrl,
  getSignedUrlResult,
  ...rest
}) => (hasPreview ? <IconPreview onClick={onPreview} {...rest} /> : null)

export default compose(
  withSignedUrl,
  withProps(({ file: { originalName } = {} }) => ({
    hasPreview: hasPreview(originalName),
  })),
  withHandlers({
    onPreview:
      ({ getSignedUrl, file }) =>
      () => {
        getSignedUrl(file.id).then((r) => {
          window.open(r.data.getSignedUrl)
        })
      },
  }),
)(PreviewFile)
