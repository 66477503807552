import React from 'react'
import { Row, Col, Text } from '@hindawi/phenom-ui'
import { Delimiter } from './'

export const AcademicEditorCell = ({ academicEditor, academicEditorLabel }) => (
  <Col
    className="manuscript-card-academic-editor"
    key={academicEditorLabel}
    onClick={(event) => event.stopPropagation()}
  >
    <Row>
      {academicEditorLabel && (
        <>
          <Col>
            <Text className="bold">{academicEditorLabel}: </Text>
          </Col>
          <Delimiter />
          <div>
            {academicEditor ? (
              <Text>
                {academicEditor?.alias?.name?.givenNames}{' '}
                {academicEditor?.alias?.name?.surname}
              </Text>
            ) : (
              <Text> Unassigned</Text>
            )}
          </div>
        </>
      )}
    </Row>
  </Col>
)
