import { isNumber, get } from 'lodash'
import { space } from 'styled-system'
import styled, { css } from 'styled-components'

const width = (props) => css`
  width: calc(4px * ${get(props, 'width', 100)});
`

const centered = (props) => {
  if (props.center) {
    return css`
      margin: 0 auto;
    `
  }
}
// Should be migrated to WhiteBox inside @shared/ui
export default styled.div.attrs((props) => ({
  pt: get(props, 'pt', 4),
  pr: get(props, 'pr', 4),
  pb: get(props, 'pb', 4),
  pl: get(props, 'pl', 4),
}))`
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 1px 2px 1px #dbdbdb;
  display: flex;
  flex: ${({ flex }) => (isNumber(flex) ? flex : 1)};
  flex-direction: column;

  position: ${(props) => get(props, 'position', 'initial')};

  ${centered};
  ${width};
  ${space};
`
