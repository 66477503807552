// @ts-nocheck
import React, { createContext } from 'react'
import {
  useFiltersState,
  ValueT as FilterValueT,
  Option,
  SelectOptionT,
} from './filters'
import { useFiltersState as useWidgetFiltersState } from './widgetFilters'
import { StateI } from '../'
import { useManuscriptsState, ValueT as ManuscriptsValueT } from './manuscripts'
import {
  useManuscriptsState as useWidgetManuscriptsState,
  ValueT as WidgetManuscriptsValueT,
} from './widgetManuscripts'
import { useStatusesCountState, StatusCountT } from './stausesCount'

import {
  ValueT as SuggestionValueT,
  useJournalSuggestionsState,
  useSectionSuggestionsState,
  useSpecialIssueSuggestionsState,
  useManuscriptSuggestionsState,
} from './suggestions'

import { SelectedStatusCategoriesT } from './filters/statusCategories'

export interface DashboardContextI {
  role?: StateI<string>
  journal?: StateI<Option>
  section?: StateI<Option>
  specialIssue?: StateI<Option>
  selectedStatuses?: StateI<SelectedStatusCategoriesT>
  statusesCount?: StateI<StatusCountT>
  author?: StateI<FilterValueT>
  academicEditor?: StateI<FilterValueT>
  page?: StateI<FilterValueT>
  orderCriteria?: StateI<SelectOptionT>
  manuscripts?: StateI<ManuscriptsValueT>
  totalManuscripts?: StateI<number>
}

interface DashboardWidgetContextI {
  widgetManuscriptByTitle?: StateI<FilterValueT>
  widgetManuscriptId?: StateI<FilterValueT>
  widgetManuscripts?: StateI<ManuscriptsValueT>
  manuscriptSuggestions?: StateI<SuggestionValueT>
}

interface SuggestionsContextI {
  journalSuggestions?: StateI<SuggestionValueT>
  sectionSuggestions?: StateI<SuggestionValueT>
  specialIssueSuggestions?: StateI<SuggestionValueT>
}

export const DashboardContext: React.Context<DashboardContextI> = createContext(
  {},
)

export const DashboardWidgetContext: React.Context<DashboardWidgetContextI> =
  createContext({})

export const SuggestionsContext: React.Context<SuggestionsContextI> =
  createContext({})

export const DashboardProvider: React.FC = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const { entries: manuscripts, total } = useManuscriptsState()

  const state = {
    ...useFiltersState(), // selected journal, selected section, selected si, author, ae, statuses
    manuscripts,
    totalManuscripts: total,
    statusesCount: useStatusesCountState(),
  }
  return (
    <DashboardContext.Provider value={state}>
      {children}
    </DashboardContext.Provider>
  )
}

export const DashboardWidgetProvider: React.FC = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const { entries: widgetManuscripts } = useWidgetManuscriptsState()

  const state = {
    ...useWidgetFiltersState(),
    widgetManuscripts,
    manuscriptSuggestions: useManuscriptSuggestionsState(),
  }

  return (
    <DashboardWidgetContext.Provider value={state}>
      {children}
    </DashboardWidgetContext.Provider>
  )
}

export const SuggestionsProvider = ({ children }) => {
  const state = {
    journalSuggestions: useJournalSuggestionsState(),
    sectionSuggestions: useSectionSuggestionsState(),
    specialIssueSuggestions: useSpecialIssueSuggestionsState(),
  }
  return (
    <SuggestionsContext.Provider value={state}>
      {children}
    </SuggestionsContext.Provider>
  )
}
