import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useKeycloak } from '../../../../component-sso/client'
import { AuthenticatedAppBarContent, UnauthenticatedAppBarContent } from './'
import { clearAuthTokens } from '../../utils'

const DownloadFileBar = () => {
  const { keycloak, loading } = useKeycloak()
  const [currentUser, setCurrentUser] = useState()
  const history = useHistory()
  const isRedirectDisabled = true
  useEffect(() => {
    if (keycloak) {
      setCurrentUser({
        // @ts-ignore
        identities: [
          {
            name: {
              givenNames: keycloak.tokenParsed?.given_name,
              surname: keycloak.tokenParsed?.family_name,
              __typename: 'Name',
            },
            email: keycloak.tokenParsed?.username,
            __typename: 'Local',
          },
        ],
      })
    }
  }, [keycloak])

  const logout = useCallback(() => {
    void keycloak?.logout({
      redirectUri: `${window.location.origin}/loggedOut`,
    })
    document.cookie =
      'idToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/downloadReviewFiles'
    clearAuthTokens()
  }, [keycloak])

  const goTo = (path) => {
    history.push(path)
  }

  const isUnauthenticated =
    !keycloak?.authenticated || keycloak?.isTokenExpired()

  if (isUnauthenticated) {
    return <UnauthenticatedAppBarContent />
  }

  return !currentUser || loading ? (
    <UnauthenticatedAppBarContent />
  ) : (
    <AuthenticatedAppBarContent
      currentUser={currentUser}
      goTo={goTo}
      goToDashboard={() => {}}
      logout={logout}
      isRedirectDisabled={isRedirectDisabled}
    />
  )
}

export default DownloadFileBar
