import styled from 'styled-components'

export const ResultsSectionContainer = styled.div`
  flex-grow: 3;
`
export const FiltersSection = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 312px;
  max-width: 280px;

  @media (max-width: 630px) {
    max-width: 100%;
    align-items: center;
  }
`

export const Root = styled.div`
  padding: 16px 32px 16px 32px;
  display: flex;
  gap: 16px;

  @media (max-width: 630px) {
    flex-direction: column;
    gap: 32px;
  }
`
