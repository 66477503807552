import gql from 'graphql-tag'

export const fileDetails = gql`
  fragment fileDetails on File {
    id
    type
    size
    originalName
    filename
    mimeType
  }
`
