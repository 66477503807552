import { get, omit } from 'lodash'
import React, { useState, useCallback } from 'react'

import { ModalContext, ModalRoot } from '..'

function ModalProvider({ children }) {
  const [modals, setModals] = useState({})

  const registerModal = useCallback(({ modalKey, component }) => {
    if (typeof component !== 'function') {
      console.error(
        'Wrong type of component! Only React components are allowed.',
      )
    }
    setModals((modals) => ({ ...modals, [modalKey]: component }))
  }, [])

  const unregisterModal = useCallback((modalKey) => {
    setModals((modals) => omit(modals, modalKey))
  }, [])

  const [modalState, setModalState] = useState({
    data: undefined,
    modalKey: undefined,
  })

  const hideModal = () => {
    setModalState({
      data: undefined,
      modalKey: undefined,
    })
  }

  const showModal = (modalKey, data) => {
    setModalState({
      data,
      modalKey,
    })
  }

  const selectedModal = get(modals, `${modalState.modalKey}`)
  return (
    <ModalContext.Provider
      value={{
        ...modalState,
        showModal,
        hideModal,
        registerModal,
        unregisterModal,
      }}
    >
      {selectedModal && (
        <ModalRoot>
          {selectedModal({ showModal, hideModal, ...modalState.data })}
        </ModalRoot>
      )}
      {children}
    </ModalContext.Provider>
  )
}

export default ModalProvider
