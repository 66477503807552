import { useCallback, useState } from 'react'

export const useBoolean = (
  defaultValue: boolean = false,
): {
  value: boolean
  setValue: React.Dispatch<React.SetStateAction<boolean>>
  toggle: () => void
  setTrue: () => void
  setFalse: () => void
} => {
  const [value, setValue] = useState(defaultValue)
  const toggle = useCallback(() => setValue((prev) => !prev), [])
  const setTrue = useCallback(() => setValue(true), [])
  const setFalse = useCallback(() => setValue(false), [])
  return {
    value,
    setValue,
    toggle,
    setFalse,
    setTrue,
  }
}
