const REVIEWERS_GUIDELINES_LINK =
  'https://www.hindawi.com/publish-research/reviewers/'
const EDITORIAL_THRESHOLD_GUIDE_LINK =
  'https://editors.hindawi.com/page/editorial-threshold'
const PEER_REVIEW_CHECKLIST_LINK =
  'https://downloads.hindawi.com/peer_review_checklist.pdf'
const REVIEWER_HUB_LINK = 'https://reviewers.hindawi.com/'
const WILEY_PEER_REVIEW_RESOURCES =
  'https://authorservices.wiley.com/Reviewers/journal-reviewers/how-to-perform-a-peer-review/index.html'

module.exports = {
  name: 'Wiley',
  supportEmail: 'reviewhelp@wiley.com',
  pageTitle: 'Wiley',
  links: {
    transferManuscriptLink: 'https://www.hindawi.com/transfer-manuscript',
    privacyLink: 'https://www.wiley.com/privacy',
    termsLink: 'https://www.wiley.com/terms-of-use',
    ethicsLink: 'https://www.hindawi.com/ethics/',
    coiLink: 'https://authorservices.wiley.com/ethics-guidelines/index.html',
    peerReviewGuidelinesLink:
      'https://authorservices.wiley.com/Reviewers/journal-reviewers/how-to-perform-a-peer-review/general-and-ethical-guidelines.html',
    dataAvailabilityLink:
      'https://www.hindawi.com/publish-research/authors/research-data/',
    apcLink: 'https://www.hindawi.com/journals/{journalCode}/apc',
    authorsGuidelinesLink: 'https://www.hindawi.com/publish-research/authors/',
    journalAuthorsGuidelinesLink:
      'https://www.hindawi.com/journals/{journalCode}/guidelines/',
    reviewLink: 'https://review.wiley.com',
    invoicingLink: 'https://invoicing.hindawi.com',
    authorServiceLink:
      'https://www.hindawi.com/publish-research/authors/author-services/?utm_source=email&utm_medium=marketing%20referral&utm_campaign=HDW_MRKT_GBL_AWA_EMIL_OWN_AUTH_HIND_X2_10170',
  },
  emailData: {
    logo: 'https://review.wiley.com/assets/wiley/email-logo.png',
    ctaColor: '#123d80',
    logoLink: '',
    shortReviewLink: 'review.wiley.com',
    staffEmail: 'reviewhelp@wiley.com',
    privacy:
      'For more information, please see our <a style="color: #0000EE; text-decoration: underline;" href="https://www.wiley.com/en-ie/privacy">privacy policy</a>.',
    address: '111 River Street, Hoboken, NJ 07030 USA. 877-762-2974.',
    publisher: 'Hindawi Limited',
    footerText: {
      unregisteredUsers: `This message was sent to {recipientEmail} by John Wiley & Sons, Inc.`,
      registeredUsers: `This message was sent to {recipientEmail} by John Wiley & Sons, Inc.`,
    },
    communicationServiceMailLogo:
      'https://static.hindawi.com/logo-hindawi-194-below.png',
  },
  recommendationScreenInfoBox: {
    HINDAWI: {
      title:
        'Your report should review the research presented in the manuscript, and provide detailed and constructive feedback to the author(s).',
      description: `
            <p>Research published in the journal must be:</p>
            <ul>
              <li>Ethically sound; </li>
              <li>Scientifically valid; </li>
              <li>Technically accurate in its methods and results; </li>
              <li>In scope; </li>
              <li>Representative of a specific advance, or replication, or null/negative result; </li>
              <li>As reproducible as possible; </li>
              <li>Journals that are not categorized as sound science may also consider novelty or perceived impact/interest when making a decision.  </li>
            </ul>
               <p style="margin-bottom: 8px">For guidance on constructing a high-quality review report, please see 
               <a href=${WILEY_PEER_REVIEW_RESOURCES} target="_blank">Wiley’s peer review resources</a>.
               </p>
              <strong>A report that only suggests grammar and formatting corrections may not be considered.</strong>`,
    },
    WILEY: {
      title:
        'Your report should review the research presented in the manuscript, and provide detailed and constructive feedback to the author(s).',
      description: `
            <p>Research published in the journal must be:</p>
            <ul>
              <li>Ethically sound; </li>
              <li>Scientifically valid; </li>
              <li>Technically accurate in its methods and results; </li>
              <li>In scope; </li>
              <li>Representative of a specific advance, or replication, or null/negative result; </li>
              <li>As reproducible as possible; </li>
              <li>Journals that are not categorized as sound science may also consider novelty or perceived impact/interest when making a decision.  </li>
            </ul>
               <p style="margin-bottom: 8px">For guidance on constructing a high-quality review report, please see 
               <a href=${WILEY_PEER_REVIEW_RESOURCES} target="_blank">Wiley’s peer review resources</a>.
               </p>
              <strong>A report that only suggests grammar and formatting corrections may not be considered.</strong>`,
    },
    getDescriptionWithLink(link) {
      return (
        this.description +
        `<p>For more information on these review questions, please see this article on our <a href=${link} title="Reviewer Hub" target="_blank" rel="noreferrer">[Reviewer Hub]</a>.</p>`
      )
    },
  },
}
