import { useEffect } from 'react'
import { useLazyQuery } from 'react-apollo'
import { queries } from '../../../graphql'
import { ManagerT } from '../../'
import { ValueT } from './state'

let entries_timeout
const timeout_duration = 300

export type SuggestionManagerT = ManagerT<ValueT, string>

export const useSuggestionsManager: SuggestionManagerT = ({
  context,
  query = '',
  inputResolver = () => {},
}) => {
  const { value, set } = context
  const [getQuery, { data, loading }] = useLazyQuery(queries[query], {
    fetchPolicy: 'network-only',
  })

  const reset = () => {
    set([])
  }

  const fetch = (searchTerm: string) => {
    if (searchTerm.length < 3) {
      reset()
      return false
    }
    clearTimeout(entries_timeout)
    entries_timeout = setTimeout(
      () =>
        getQuery({
          variables: {
            input: inputResolver(searchTerm),
          },
        }),
      timeout_duration,
    )
    return true
  }

  useEffect(() => {
    set(data && data[query] ? data[query] : [])
  }, [data])

  return {
    value,
    loading,
    fetch,
    reset,
    set,
  }
}
