import { useEffect } from 'react'
import ResizeObserver from 'resize-observer-polyfill'

const useResizeObserver = ({ selector = '#root main>div', handler }) => {
  useEffect(() => {
    const resizeObserver = new ResizeObserver(handler)
    const scrollContent = document.querySelector(selector)
    resizeObserver.observe(scrollContent)
    return () => {
      resizeObserver.unobserve(scrollContent)
    }
  }, [])
}

export default useResizeObserver
