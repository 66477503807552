import React from 'react'
import PropTypes from 'prop-types'
import { space } from 'styled-system'
import styled, { css } from 'styled-components'

import { positionHelper } from '../'

const Icon = ({ icon, onClick = () => {}, className = '', ...rest }) => (
  <StyledIcon
    className={`icn_icn_${icon} ${className}`}
    onClick={onClick}
    {...rest}
  />
)

Icon.propTypes = {
  icon: PropTypes.oneOf([
    'addUser',
    'arrowRight',
    'arrowEndLeft',
    'caretRight',
    'caretUp',
    'warning',
    'collapse',
    'expand',
    'arrowLeft',
    'arrowEnd',
    'checks',
    'remove',
    'info',
    'solidInfo',
    'tooltip',
    'downloadZip',
    'checkedBox',
    'moreDefault',
    'check',
    'resend',
    'caretLeft',
    'caretDown',
    'save',
    'delete',
    'link',
    'edit',
    'download',
    'preview',
    'remove1',
    'move',
    'bredcrumbs2',
    'calendar',
    'search',
    'dashboard',
    'manuscripts',
    'reports',
    'withdraw',
    'reassign',
    'lead',
    'solidLead',
  ]).isRequired,
}

export default Icon

// #region styles

const reverse = (props) => {
  if (props.reverse) {
    return css`
      transform: rotate(180deg);
    `
  }
  if (props.inclined) {
    return css`
      transform: rotate(45deg);
    `
  }
}

const iconColor = ({ theme, primary, error, warning, color }) => {
  if (color) return color

  if (primary) {
    return theme.actionPrimaryColor
  }

  if (warning) {
    return theme.statusInvite
  }

  if (error) {
    return theme.warningColor
  }

  return 'inherit'
}
const StyledIcon = styled.span`
  color: ${iconColor};
  cursor: pointer;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '12px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '12px')};
  ${positionHelper};
  ${space};
  ${reverse};
`
// #endregion
