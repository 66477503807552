import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button, Space } from '@hindawi/phenom-ui'

import { Icon } from '../'

const PaginationComponent = ({
  totalCount,
  toLast,
  toFirst,
  nextPage,
  prevPage,
  setPage,
  page,
  hasMore = true,
  itemsPerPage,
  changeItemsPerPage = () => {},
}) => {
  const pageLimit = 5
  const offset = Math.floor(pageLimit / 2)
  const totalPages = Math.ceil(totalCount / itemsPerPage)

  const pageRange = [
    ...Array(totalPages <= pageLimit ? totalPages : pageLimit),
  ].map((item, index) => {
    if (totalPages <= pageLimit || page <= offset) return index // start of range or all pages can be displayed
    if (page >= totalPages - offset) return offset * -2 + index + totalPages - 1 // end of range
    return page - offset + index
  })

  const disabledRight = page === totalPages - 1
  const disabledLeft = page === 0
  if (!totalPages) {
    return null
  }
  return (
    <Root data-test-id="pagination-component" size="small">
      <Button
        type={disabledLeft ? 'secondary' : 'ghost'}
        data-test-id="arrow-end-left"
        disabled={disabledLeft}
        onClick={toFirst}
        icon={<Icon disabled={disabledLeft} icon="arrowEndLeft" />}
      />
      <Button
        type={disabledLeft ? 'secondary' : 'ghost'}
        data-test-id="caret-left"
        disabled={disabledLeft}
        onClick={prevPage}
        icon={<Icon disabled={disabledLeft} icon="arrowRight" reverse />}
      />
      {pageRange.map((pageNumber) => (
        <Button
          type={pageNumber === page ? 'secondary' : 'ghost'}
          data-test-id="page-numbers"
          key={pageNumber}
          onClick={() => setPage(pageNumber)}
        >
          {pageNumber + 1}
        </Button>
      ))}

      <Button
        type={disabledRight ? 'secondary' : 'ghost'}
        data-test-id="caret-right"
        disabled={disabledRight}
        onClick={nextPage}
        icon={<Icon disabled={disabledRight} icon="arrowRight" />}
      />
      <Button
        type={disabledRight ? 'secondary' : 'ghost'}
        data-test-id="arrow-end-right"
        disabled={disabledRight}
        onClick={toLast}
        icon={<Icon disabled={disabledRight} icon="arrowEnd" />}
      />
    </Root>
  )
}

export const Pagination = ({ children, ...props }) => (
  <Fragment>
    <PaginationComponent {...props} />
    {typeof children === 'function' && children(props)}
  </Fragment>
)

Pagination.propTypes = {
  /** Page current number. */
  page: PropTypes.number,
  /** Indicates if there are more pages to be displayed. */
  hasMore: PropTypes.bool,
  /** Maximum items displayed. */
  maxItems: PropTypes.number,
  /** Items displayed per page. */
  itemsPerPage: PropTypes.number,
  /** Change how many items should be displayed per page. */
  changeItemsPerPage: PropTypes.func,
}
Pagination.defaultProps = {
  page: 1,
  hasMore: false,
  maxItems: 23,
  itemsPerPage: 10,
  changeItemsPerPage: () => {},
}

export default PaginationComponent

const Root = styled(Space)`
  .ant-btn {
    width: 32px;
  }
`

// #endregion
