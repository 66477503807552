import React from 'react'
import styled from 'styled-components'

import { ActionLink, Row, Text } from '../'

const Footer = ({ supportEmail, privacyLink, termsLink }) => (
  <Root alignItems="center" data-test-id="footer-row-id" justify="center">
    <ActionLink data-test-id="privacy-policy" ml={3} to={privacyLink}>
      Privacy Policy
    </ActionLink>
    <ActionLink data-test-id="terms-of-service" ml={3} to={termsLink}>
      Terms of Service
    </ActionLink>
    <Text display="flex" ml={4} secondary>
      Support:
      <ActionLink
        data-test-id="email-support"
        ml={1}
        to={`mailto:${supportEmail}`}
      >
        {supportEmail}
      </ActionLink>
    </Text>
  </Root>
)

export default Footer

const Root = styled(Row)`
  background-color: #ecf0f3;
  height: calc(4px * 10);
  margin-top: 16px;
  z-index: 200;
`
