import { useFetching } from '@hindawi/ui'

import { createAnchorElement, removeAnchorElement } from '../fileUtils'
import { getAuthTokens } from '../../../component-authentication/client/utils'

const useZipDownload = ({ manuscriptId, archiveName }) => {
  const { isFetching, setFetching, fetchingError, setError } =
    useFetching(false)

  const getURL = `${window.location.origin}/files/zip/${manuscriptId}`
  const { token } = getAuthTokens()
  const setErr = ({ name }) => setError(name)

  const downloadZip = () => {
    setFetching(true)
    const xhr = new XMLHttpRequest()
    xhr.onreadystatechange = function onXhrStateChange() {
      if (this.readyState === 4) {
        setFetching(false)
        if (this.status >= 200 && this.status < 300) {
          const fileName = archiveName || `${manuscriptId}-archive.zip`
          const f = new File([this.response], fileName, {
            type: 'application/zip',
          })

          const { a, url } = createAnchorElement(f, fileName)
          a.click()
          removeAnchorElement(a, url)
        } else if (this.status >= 400) {
          new Response(this.response)
            .text()
            .then(JSON.parse)
            .then(setErr)
            .catch(console.error)
        }
      }
    }
    xhr.open('GET', getURL)
    xhr.responseType = 'blob'
    xhr.setRequestHeader('Authorization', `Bearer ${token}`)
    xhr.send()
  }

  return { downloadZip, isFetching, fetchingError }
}

export default useZipDownload
