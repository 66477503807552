import { useState } from 'react'

export type ValueT = any[]

export const useManuscriptsState = () => {
  const [manuscripts, setManuscripts] = useState([])

  const entries = {
    value: manuscripts,
    set: setManuscripts,
  }

  return {
    entries,
  }
}
