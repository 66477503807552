import React from 'react'
import { Tooltip, Text } from '@hindawi/phenom-ui'
import { Delimiter } from './'

type JournalT = {
  name: string | undefined
}

export const JournalCell = ({ journal }: { journal: JournalT }) => (
  <>
    {journal && (
      <Tooltip
        copyContent={journal.name}
        showCopyContent
        replaceTooltipText="Journal"
      >
        <Text className="bold">Journal:</Text>
        <Delimiter />
        <Text ellipsis>{journal.name}</Text>
      </Tooltip>
    )}
  </>
)
