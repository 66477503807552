export const ROUTES = {
  ADMIN_DASHBOARD: '/admin',
  ADMIN_USERS: '/admin/users',
  ADMIN_JOURNALS: '/admin/journals',
  INVITATION: '/invitation/:invitationId',

  EA_DASHBOARD: '/staff-dashboard',
  ADMIN_SEARCH_DASHBOARD: '/admin-dashboard',
  DASHBOARD: '/',

  MANUSCRIPT_DETAILS: '/details/:submissionId/:manuscriptId',
  MANUSCRIPT_DETAILS_GATEWAY: '/details/:customId',

  JOURNAL_DETAILS: '/admin/journals/:journalId',

  PROFILE: '/profile',

  ENMASS_JOURNAL_CONFIGURATION: '/admin/enmass-journal-configuration',

  PAGE_NOT_FOUND: '/404',
} as const
