import React, { Fragment } from 'react'
import { Field } from 'formik'
import PropTypes from 'prop-types'
import { get, isEqual } from 'lodash'
import styled from 'styled-components'
import {
  compose,
  withProps,
  withHandlers,
  shouldUpdate,
  setDisplayName,
} from 'recompose'

const WrappedComponent = compose(
  withHandlers({
    onChange:
      ({
        form: { setFieldValue },
        field: { onChange, name },
        onChange: parentOnChange,
      }) =>
      (v) => {
        if (parentOnChange) parentOnChange(v)
        if (typeof v === 'object') {
          onChange(v)
        } else {
          setFieldValue(name, v)
        }
      },
  }),
  withProps(({ form: { errors, touched, submitCount }, name }) => ({
    hasError: (submitCount > 0 || get(touched, name)) && get(errors, name),
  })),
  withProps(({ name, hasError, form: { errors } }) => ({
    error: hasError && get(errors, name),
    validationStatus: hasError ? 'error' : 'default',
  })),
  shouldUpdate(
    (prev, next) =>
      get(prev, 'field.value') !== get(next, 'field.value') ||
      get(prev, 'error') !== get(next, 'error') ||
      get(prev, 'disabled') !== get(next, 'disabled') ||
      !isEqual(get(prev, 'options'), get(next, 'options')),
  ),
)(
  ({
    form,
    error,
    hasError,
    onChange,
    validationStatus,
    component: Component,
    field: { name, value, onBlur },
    ...props
  }) => (
    <Fragment>
      <Component
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        {...props}
        validationStatus={validationStatus}
      />

      <ErrorWrapper
        data-test-id={
          props['data-test-id'] ? `${props['data-test-id']}-error` : undefined
        }
        className="erron-wrapper"
      >
        {hasError && <ErrorMessage>{error}</ErrorMessage>}
      </ErrorWrapper>
    </Fragment>
  ),
)

const ValidatedFormField = ({ name, component, validateFn, ...props }) => (
  <Field name={name} validate={validateFn}>
    {(fieldProps) => (
      <WrappedComponent
        component={component}
        name={name}
        {...props}
        {...fieldProps}
      />
    )}
  </Field>
)

ValidatedFormField.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
}

export default compose(
  setDisplayName('ValidatedFormikField'),
  withProps(({ validate = [] }) => ({
    validateFn: validate.length
      ? (value = '') => validate.reduce((acc, fn) => acc || fn(value), '')
      : '',
  })),
  shouldUpdate(
    (prev, next) =>
      !isEqual(get(prev, 'options'), get(next, 'options')) ||
      get(prev, 'validate') !== get(next, 'validate') ||
      get(prev, 'disabled') !== get(next, 'disabled'),
  ),
)(ValidatedFormField)

// #region styles
const ErrorWrapper = styled.div`
  height: calc(4px * 5);
`
const ErrorMessage = styled.span`
  color: #fc6a4b;
  font-family: 'Nunito';
  font-size: 10px;
  line-height: 1;
  margin-top: calc(4px / 2);

  &:not(:last-child) {
    margin-bottom: calc(4px * 2);
  }
`
// #endregion
