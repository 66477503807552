import { compose, withHandlers } from 'recompose'

import FileLayout from './FileLayout'
import { withSignedUrl } from '../graphql'

export default compose(
  withSignedUrl,
  withHandlers({
    onPreview:
      ({ getSignedUrl }) =>
      (f) =>
      () => {
        getSignedUrl(f.id).then((r) => {
          window.open(r.data.getSignedUrl)
        })
      },
    onDelete:
      ({ onDelete, item }) =>
      () => {
        if (typeof onDelete === 'function') onDelete(item)
      },
  }),
)(FileLayout)
