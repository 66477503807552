import './fonts/icons.css'

import oldTheme from './oldTheme'

function generateSpaces(gridUnit = 4) {
  return Array.from({ length: 200 }, (v, i) => i * gridUnit)
}

// all values are in gridUnits
// fonts and lineheights are the only exceptions
const colors = {
  actionPrimaryColor: '#81BA40',
  actionSecondaryColor: '#828282',
  backgroundColor: '#F5F5F5',
  contrastGrayColor: '#4F4F4F',
  furnitureColor: '#E0E0E0',
  infoColor: '#FFCA5B',
  labelColor: '#F5F5F5',
  labelLineColor: '#828282',
  colorBorder: '#E0E0E0',
  mainTextColor: '#333',
  textPrimaryColor: '#333333',
  textSecondaryColor: '#4F4F4F',
  transparent: 'transparent',
  warningColor: '#FC6A4B',
  warning: '#F89D10',
  white: '#FFFFFF',
  statusPending: '#FF5547',
  statusInvite: '#FCB624',
  statusApproved: '#0A7388',
  statusRejected: '#FF5547',
  statusInProgress: '#F89D10',
  statusWithdrawn: '#686868',
  highlightColor: '#F5F5F5',
  disabledColor: '#C9C9C9',
  grey10: '#FAFAFA',
  grey20: '#F5F5F5',
  grey30: '#E0E0E0',
  grey40: '#BDBDBD',
  grey50: '#828282',
  grey60: '#686868',
  grey70: '#4F4F4F',
  grey80: '#333333',
  darkBlue: '#034A57',
  colorError: '#FC6A4B',
  blue5: '#F3F8F9',
  blue40: '#9DC7CF',
  blue50: '#0576D1',
  blue60: '#6CABB8',
  blue100: '#0A7388',
}

const sizes = {
  borderRadius: '4px',
  borderRadiusContainer: '6px',

  h1Size: '30px',
  h1LineHeight: '41px',
  h2Size: '20px',
  h2LineHeight: '27px',
  h3Size: '16px',
  h3LineHeight: '22px',

  smallLabelSize: '11px',
  smallLabelLineHeight: '15px',

  mainTextSize: '14px',
  mainTextLineHeight: '19px',
  secondaryTextSize: '14px',
  secondaryTextLineHeight: '17px',

  bannerTextSize: '12px',
  bannerTextLineHeight: '16px',
  counterTextSize: '11px',
  counterTextLineHeight: '11px',
}

const buttons = {
  borderSize: '1px',
  small: {
    height: 6,
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '14px',
    minWidth: 20,
  },
  medium: {
    height: 8,
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '15px',
    minWidth: 24,
  },
  default: {
    height: 10,
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '18px',
    minWidth: 30,
  },
  xLarge: {
    height: 10,
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '18px',
    minWidth: 48,
  },
  primary: {
    backgroundColor: colors.actionPrimaryColor,
    color: colors.white,
    disabledBgColor: colors.furnitureColor,
    fontSize: sizes.h4Size,
    lineHeight: sizes.h4LineHeight,
  },
  secondary: {
    backgroundColor: colors.transparent,
    borderColor: colors.labelColor,
    color: colors.labelColor,
    disabledBorderColor: colors.furnitureColor,
    disabledColor: colors.furnitureColor,
    fontSize: sizes.h4Size,
    lineHeight: sizes.h4LineHeight,
  },
}

const shadows = {
  shadowMedium: '3px 4px 10px 1px rgba(51, 51, 51, 0.18)',
  boxShadow: '0px 4px 8px rgba(51, 51, 51, 0.150867);',
}

const text = {
  textSecondaryColor: colors.textSecondaryColor,
  textPrimaryColor: colors.textPrimaryColor,
  warningColor: colors.warningColor,
  furnitureColor: colors.furnitureColor,

  fontSizeBase: '14px',
  fontSizeBaseMedium: '13px',
  fontSizeBaseSmall: '11px',
}

const validation = {
  errorColor: colors.colorError,
  warningColor: colors.warning,
}

const zIndex = {
  appBar: '200',
  modal: '300',
  select: '400',
  datePicker: '300',
}

export default {
  ...oldTheme,
  buttons,
  text,
  validation,
  zIndex,
  shadows,
  gridUnit: '4px',
  lightenPercent: 20,
  defaultFont: 'Nunito',
  ...colors,
  ...sizes,
  // properties used by styled-system
  space: generateSpaces(),
  // override pubsweet components
}
