import { Flex } from '@hindawi/phenom-ui'
import React from 'react'
import styled from 'styled-components'

const WhiteAreaFlex = ({
  className,
  children,
}: {
  className?: string
  children: React.ReactNode
}) => (
  <Flex className={className} width="inherit" p={24} gap={24} height="100%">
    {children}
  </Flex>
)

const WhiteArea = styled(WhiteAreaFlex)`
  background-color: var(--color-neutral-white);
  border-radius: 6px;
  box-shadow: 0px 4px 8px 0px rgba(51, 51, 51, 0.11);
`

export function WhiteBox({
  children,
}: {
  children: React.ReactNode
}): React.ReactNode {
  return <WhiteArea>{children}</WhiteArea>
}
