import { graphql } from 'react-apollo'
import { compose } from 'recompose'

import * as queries from './queries'
import * as mutations from './mutations'

export default compose(
  graphql(mutations.confirmUser, {
    name: 'confirmUser',
    options: {
      refetchQueries: [
        {
          query: queries.currentUser,
        },
      ],
    },
  }),
)
