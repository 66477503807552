import React from 'react'
import { Col, Row, Text } from '@hindawi/phenom-ui'
import { Delimiter } from './'

export const ArticleTypeCell = ({ articleType }) => (
  <Row>
    {articleType && articleType.name && (
      <Col flex="auto">
        <Text className="bold"> Article Type:</Text>
        <Delimiter />
        <span>{articleType.name}</span>
      </Col>
    )}
  </Row>
)
