import gql from 'graphql-tag'
import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { setContext } from 'apollo-link-context'
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory'
import { createUploadLink } from 'apollo-upload-client'
import { getAuthTokens } from '../../component-authentication/client/utils'
import { getCookieValue } from '../../component-peer-review/client/fingerprint'

export default function makeApolloClient() {
  const typeDefs = gql`
    extend type Mutation {
      updateAutosave: Boolean
    }
  `
  const uploadLink = createUploadLink({
    credentials: 'include',
    uri: process.env.GATEWAY_URI,
  })
  const authLink = setContext((_, { headers }) => {
    const { token } = getAuthTokens()
    const browserFingerprint = getCookieValue('BROWSER_FINGERPRINT')
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
        'browser-fingerprint': browserFingerprint ? browserFingerprint : '',
      },
    }
  })

  const clientState = {
    typeDefs,
    resolvers: {
      Mutation: {
        updateAutosave: (_, { params }, { cache }) => {
          const data = {
            autosave: {
              __typename: 'AutosaveState',
              ...params,
            },
          }
          cache.writeData({ data })
          return null
        },
      },
    },
    defaults: {
      autosave: {
        __typename: 'AutosaveState',
        error: null,
        updatedAt: null,
        inProgress: false,
      },
    },
  }

  const config = {
    link: ApolloLink.from([authLink, uploadLink]),
    cache: new InMemoryCache({
      fragmentMatcher: new IntrospectionFragmentMatcher({
        introspectionQueryResultData: {
          __schema: {
            types: [],
          },
        },
      }),
    }),
    ...clientState,
  }

  return new ApolloClient(config)
}
