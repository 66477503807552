export const visibleDeclarations = {
  declarations: {
    researchArticle: {
      conflictOfInterest: {
        isRequired: true,
        isVisible: true,
      },
      dataAvailability: {
        isRequired: true,
        isVisible: true,
      },
      fundingStatement: {
        isRequired: true,
        isVisible: true,
      },
    },
    reviewArticle: {
      conflictOfInterest: {
        isRequired: true,
        isVisible: true,
      },
      dataAvailability: {
        isRequired: false,
        isVisible: true,
      },
      fundingStatement: {
        isRequired: true,
        isVisible: true,
      },
    },
    letterToTheEditor: {
      conflictOfInterest: {
        isRequired: true,
        isVisible: true,
      },
      dataAvailability: {
        isRequired: false,
        isVisible: false,
      },
      fundingStatement: {
        isRequired: false,
        isVisible: false,
      },
    },
    caseReport: {
      conflictOfInterest: {
        isRequired: true,
        isVisible: true,
      },
      dataAvailability: {
        isRequired: false,
        isVisible: true,
      },
      fundingStatement: {
        isRequired: true,
        isVisible: true,
      },
    },
    erratum: {
      conflictOfInterest: {
        isRequired: false,
        isVisible: false,
      },
      dataAvailability: {
        isRequired: false,
        isVisible: false,
      },
      fundingStatement: {
        isRequired: false,
        isVisible: false,
      },
    },
    corrigendum: {
      conflictOfInterest: {
        isRequired: false,
        isVisible: false,
      },
      dataAvailability: {
        isRequired: false,
        isVisible: false,
      },
      fundingStatement: {
        isRequired: false,
        isVisible: false,
      },
    },
    editorial: {
      conflictOfInterest: {
        isRequired: true,
        isVisible: true,
      },
      dataAvailability: {
        isRequired: false,
        isVisible: false,
      },
      fundingStatement: {
        isRequired: false,
        isVisible: false,
      },
    },
    retraction: {
      conflictOfInterest: {
        isRequired: false,
        isVisible: false,
      },
      dataAvailability: {
        isRequired: false,
        isVisible: false,
      },
      fundingStatement: {
        isRequired: false,
        isVisible: false,
      },
    },
    expressionOfConcern: {
      conflictOfInterest: {
        isRequired: false,
        isVisible: false,
      },
      dataAvailability: {
        isRequired: false,
        isVisible: false,
      },
      fundingStatement: {
        isRequired: false,
        isVisible: false,
      },
    },
    caseSeries: {
      conflictOfInterest: {
        isRequired: true,
        isVisible: true,
      },
      dataAvailability: {
        isRequired: false,
        isVisible: true,
      },
      fundingStatement: {
        isRequired: true,
        isVisible: true,
      },
    },
    defaultType: {
      conflictOfInterest: {
        isRequired: true,
        isVisible: true,
      },
      dataAvailability: {
        isRequired: false,
        isVisible: true,
      },
      fundingStatement: {
        isRequired: true,
        isVisible: true,
      },
    },
  },
  questions: {
    conflictOfInterest: {
      title: 'Do any authors have Conflicts of Interest to declare?',
      subtitle:
        'Please describe any of the authors’ potential conflicts of interest, such as financial interests, affiliations, or personal interests or beliefs, that could be perceived to affect the objectivity or neutrality of the manuscript. Guidance on what constitutes a conflict of interest can be found',
      placeholder:
        'Enter text — If you already included a conflict of interest statement in your manuscript you can copy and paste it here.',
    },
    dataAvailability: {
      title: 'Data Availability Statement',
      subtitle:
        'Please describe where the underlying data supporting the results of your study can be found, including, where applicable, hyperlinks to publicly archived datasets analysed or generated during the study. Help and templates can be found ',
      placeholder:
        'Enter text — if you already included a data availability statement in your manuscript you can copy and paste it here.',
    },
    fundingStatement: {
      title: 'Funding Statement',
      subtitle:
        'Please describe how this study was funded, naming each financially supporting body followed by any associated grant numbers in square brackets.',
      placeholder:
        'Enter text — if you already included a funding statement in your manuscript you can copy and paste it here.',
    },
  },
}
