// @ts-nocheck
import React, { useState } from 'react'
import styled from 'styled-components'
import { Form, Row, Col, Input, Button } from '@hindawi/phenom-ui'
import { Suggestion, Option } from '../SuggestionsManager'
import { SearchWithAutocomplete } from '../SearchWithAutocomplete'

import { SuggestionsProvider } from '../../../store/EADashboard/index'
import { FiltersManagerAPII } from '../../../store/EADashboard/filters'
import { connect } from '../../../store'

import {
  useJournalSuggestionsManager,
  useSectionSuggestionsManager,
  useSpecialIssueSuggestionsManager,
} from '../../../store/EADashboard/suggestions'

const { Item } = Form

const FormItem = styled(Item)`
  margin-bottom: 0;
`

const ColButton = styled(Col)`
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
`

const Filters: React.FC<{ filters: FiltersManagerAPII }> = ({ filters }) => {
  const [localJournal, setLocalJournal] = useState('')
  const [localSection, setLocalSection] = useState('')
  const [localSI, setLocalSI] = useState('')

  const [localAuthor, setLocalAuthor] = useState('')
  const [localEditor, setLocalEditor] = useState('')

  const { journal, section, specialIssue, author, editor } = filters
  // journals
  const journalSuggestions = useJournalSuggestionsManager()

  // sections
  const sectionSuggestions = useSectionSuggestionsManager({
    journalId: journal.value.value,
  })

  // special issues
  const specialIssueSuggestions = useSpecialIssueSuggestionsManager({
    ...(!section?.value?.value &&
      journal?.value?.value && {
        journalId: journal.value.value,
      }),
    sectionId: section?.value?.value || undefined,
  })

  const resetFilters = () => {
    setLocalJournal('')
    setLocalEditor('')
    setLocalAuthor('')
    setLocalSection('')
    setLocalSI('')

    journal.reset()
    section.reset()
    specialIssue.reset()
    journalSuggestions.reset()
    sectionSuggestions.reset()
    specialIssueSuggestions.reset()
    author.reset()
    editor.reset()
  }

  const handleSectionChange = (value: string) => {
    setLocalSection(value)
    if (!value.length && section.value.value) {
      section.reset()
    }
  }

  const handleSpecialIssueChange = (value: string) => {
    setLocalSI(value)
    if (!value.length && specialIssue.value.value) {
      specialIssue.reset()
    }
  }

  const handleJournalChange = (value: string) => {
    setLocalJournal(value)
    if (!value.length && journal.value.value) {
      journal.reset()
    }
  }

  const handleJournalSelect = (entry: Option) => {
    journal.set(entry)
    setLocalJournal(entry.displayValue)
    handleSectionChange('')
    handleSpecialIssueChange('')
    journalSuggestions.reset()
    sectionSuggestions.reset()
  }

  const handleSectionSelect = (entry: Option) => {
    section.set(entry)
    setLocalSection(entry.displayValue)
  }

  const handleSISelect = (entry: Option) => {
    specialIssue.set(entry)
    setLocalSI(entry.displayValue)
  }

  const handleAuthorSearch = () => {
    author.set(localAuthor)
  }

  const handleEditorSearch = () => {
    editor.set(localEditor)
  }

  return (
    <Form layout="vertical">
      <Row gutter={[12, 12]}>
        <Col span={8}>
          <FormItem label="Journal">
            <SearchWithAutocomplete
              getSuggestions={journalSuggestions.fetch}
              loading={journalSuggestions.loading}
              onChange={handleJournalChange}
              renderDisplayValue={({ name }: Suggestion) => name}
              renderLabel={({ name }: Suggestion) => name}
              renderValue={({ id }: Suggestion) => id}
              selectSuggestion={handleJournalSelect}
              suggestions={journalSuggestions.value}
              value={localJournal}
            />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem label="Section">
            <SearchWithAutocomplete
              disabled={!journal.value.value}
              getSuggestions={sectionSuggestions.fetch}
              loading={sectionSuggestions.loading}
              onChange={handleSectionChange}
              renderDisplayValue={({ name }: Suggestion) => name}
              renderLabel={({ name }: Suggestion) => name}
              renderValue={({ id }: Suggestion) => id}
              selectSuggestion={handleSectionSelect}
              suggestions={sectionSuggestions.value}
              value={localSection}
            />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem label="Special Issue">
            <SearchWithAutocomplete
              disabled={!journal.value.value}
              getSuggestions={specialIssueSuggestions.fetch}
              loading={specialIssueSuggestions.loading}
              onChange={handleSpecialIssueChange}
              renderDisplayValue={({ name }: Suggestion) => name}
              renderLabel={({ name }: Suggestion) => name}
              renderValue={({ id }: Suggestion) => id}
              selectSuggestion={handleSISelect}
              suggestions={specialIssueSuggestions.value}
              value={localSI}
            />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={[12, 12]}>
        <Col span={8}>
          <Form.Item label="Author Email">
            <Input.Search
              enterButton
              onChange={(e) => setLocalAuthor(e.target.value)}
              onSearch={handleAuthorSearch}
              placeholder="Filter by Author Email"
              value={localAuthor}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Editor email">
            <Input.Search
              enterButton
              onChange={(e) => setLocalEditor(e.target.value)}
              onSearch={handleEditorSearch}
              placeholder="Filter by Editor Email"
              value={localEditor}
            />
          </Form.Item>
        </Col>
        <ColButton offset={4} span={4}>
          <Form.Item style={{ marginBottom: '4px' }}>
            <Button onClick={resetFilters} type="secondary">
              Clear filters
            </Button>
          </Form.Item>
        </ColButton>
      </Row>
    </Form>
  )
}

export default connect(SuggestionsProvider, Filters)
