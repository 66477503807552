import React from 'react'
import styled from 'styled-components'
import { Fade, Text, Loader } from '@hindawi/ui'

import { useZipDownload } from '../decorators'
import { IconDownloadZip } from '@hindawi/phenom-ui'

const DownloadZip = ({ manuscript, ...rest }) => {
  const { isFetching, downloadZip, fetchingError } = useZipDownload({
    manuscriptId: manuscript.id,
    archiveName: `ID-${manuscript.customId || manuscript.id}`,
  })

  return (
    <Root>
      {isFetching ? (
        <Loader iconSize={2} mb={1 / 2} />
      ) : (
        <IconDownloadZip onClick={downloadZip} />
      )}
      {fetchingError && (
        <Fade>
          <ErrorWrapper>
            <Text error>{fetchingError}</Text>
          </ErrorWrapper>
        </Fade>
      )}
    </Root>
  )
}

export default DownloadZip

// #region styles
const Root = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
`

const ErrorWrapper = styled.div`
  position: absolute;
  top: calc(4px * 3);
  left: 0;

  width: calc(4px * 20);
`
// #endregion
