import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Select, Form, Switch } from '@hindawi/phenom-ui'

import {
  orderOptions,
  FiltersManagerAPII,
  usePageManager,
} from '../../../store/EADashboard/filters'

import { useManuscripts } from '../../../store/EADashboard/manuscripts'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;

  .ant-form .ant-form-item:last-child {
    margin-right: 0;
  }

  .ant-select .ant-select-selector {
    padding: 0 !important;

    .ant-select-selection-item {
      padding-right: 15px !important;
    }
  }

  .ant-select-arrow {
    right: 0;
  }

  .expand-cards-switch-wrapper .ant-form-item-control-input-content {
    display: flex;
  }
`

const ManuscriptsListHeader: React.FC<{ filters: FiltersManagerAPII }> = ({
  filters,
}) => {
  const [expandCards, setExpandCards] = useState(false)
  const page = usePageManager()
  const { setManuscriptsCardExpanded } = useManuscripts()
  const { orderCriteria } = filters
  const handleCriteriaChange = (value) => {
    orderCriteria.set(orderOptions[value])
  }

  useEffect(() => {
    setExpandCards(false)
  }, [page.value])

  const expandCardsHandler = (checked) => {
    setManuscriptsCardExpanded(checked)
    setExpandCards(checked)
  }

  return (
    <Wrapper>
      <div />
      <Form layout="inline" size="small">
        <Form.Item
          className="expand-cards-switch-wrapper"
          label="Detailed view"
        >
          <Switch checked={expandCards} onChange={expandCardsHandler} />
        </Form.Item>
        <Form.Item label="Sort by:">
          <Select
            bordered={false}
            dropdownMatchSelectWidth={false}
            onChange={handleCriteriaChange}
            options={Object.values(orderOptions)}
            value={orderCriteria.value.value}
          />
        </Form.Item>
      </Form>
    </Wrapper>
  )
}

export default ManuscriptsListHeader
