import React from 'react'
import { Col, Preset, Text } from '@hindawi/phenom-ui'
import { Delimiter } from '.'
import { DateParser } from '@hindawi/ui'

export const DateCell = ({ date, label }: Record<'date' | 'label', string>) => (
  <>
    {date && (
      <DateParser humanizeThreshold={0} timestamp={date}>
        {(timestamp, timeAgo) => (
          <div className="submitted-date">
            <Col>
              <Text preset={Preset.BOLD} className="bold">
                {label}:
              </Text>
            </Col>
            <Delimiter />
            <Text>{timestamp}</Text>
            <Delimiter />
            <Text>({timeAgo})</Text>
          </div>
        )}
      </DateParser>
    )}
  </>
)
