// @ts-nocheck
import { useContext, useEffect } from 'react'
import { useTracking } from 'react-tracking'
import { useLazyQuery } from 'react-apollo'
import { pickBy } from 'lodash'
import { queries } from '../../../graphql'
import { getVisibleStatusForManuscript } from '../filters/statusCategories'
import { ManagerApiI } from '../../'
import { DashboardWidgetContext } from '../'
import { ValueT } from './state'
import { useCurrentUser } from '../../../../../component-authentication/client'

const timer = {
  startTime: 0,
  initStartTime: () => (timer.startTime = performance.now()),
  getElapsedTime: () => performance.now() - timer.startTime,
}

let usedFilters = []

export interface searchManuscriptInputResolverI {
  editorialAssistantId?: string
  rangeStart?: number
  rangeEnd?: number
  customId?: string
  id?: string
  role?: string
}

type useManuscriptManagerT = () => ManagerApiI<
  ValueT,
  searchManuscriptInputResolverI
>

export const useWidgetManuscripts: useManuscriptManagerT = () => {
  const { value, set } = useContext(DashboardWidgetContext).widgetManuscripts
  const tracking = useTracking()
  const query = 'getManuscriptsV2'

  const currentUser = useCurrentUser()

  const [queryManuscripts, { data, loading }] = useLazyQuery(queries[query], {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      tracking.trackEvent({
        userRole: 'Editorial Assistant',
        pageTemplate: 'Editorial Assitant Page',
        queryTime: timer.getElapsedTime(),
        filters: usedFilters,
      })

      usedFilters = []
    },
  })

  useEffect(() => {
    const fetchedManuscripts =
      data && data[query] && data[query].manuscripts
        ? data[query].manuscripts
        : []

    set(
      fetchedManuscripts.map((manuscript) => ({
        ...manuscript,
        visibleStatus: getVisibleStatusForManuscript(manuscript.status),
      })),
    )
  }, [data])

  const getManuscripts = ({
    rangeStart = 0,
    rangeEnd = 10,
    editorialAssistantId,
    customId,
    id,
    role,
  }: Partial<searchManuscriptInputResolverI>) => {
    timer.initStartTime()

    usedFilters = Object.keys(
      pickBy(
        {
          customId,
          id,
        },
        Boolean,
      ),
    )

    return queryManuscripts({
      variables: {
        input: {
          editorialAssistantId:
            currentUser.role === 'admin' ? undefined : currentUser.id,
          customId,
          id,
          isLatestVersion: true,
          rangeStart,
          rangeEnd,
        },
      },
    })
  }

  const reset = () => {
    set([])
  }

  return {
    value,
    fetch: getManuscripts,
    set,
    loading,
    reset,
  }
}
