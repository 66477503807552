import React, { useEffect, useState } from 'react'
import {
  keycloak as keycloakClient,
  useKeycloak,
} from '../../component-sso/client'

import { TechnicalErrorPage } from '../../app-review/app/TechnicalErrorPage'
import { LoadingState } from '../../app-review/app/LoadingState'

function DownloadFileAuthenticatedRoute(props) {
  const { component: Component, location } = props
  const { keycloak, setKeycloak, loading } = useKeycloak()
  const [keycloakError, setKeycloakError] = useState(null)
  const [keycloakLoading, setKeycloakLoading] = useState(true)

  useEffect(() => {
    if (keycloak === null) {
      const keycloakEnv = process.env.KEYCLOAK
      setKeycloakLoading(true)
      void keycloakClient.init(
        keycloakEnv,
        (keycloak) => {
          if (setKeycloak) {
            setKeycloak(keycloak)
          }
          setKeycloakLoading(false)
        },
        (error) => {
          setKeycloakError(error)
          setKeycloakLoading(false)
        },
      )
    } else {
      setKeycloakLoading(false)
    }
  }, [])

  if (keycloakLoading) {
    return <LoadingState />
  } else if (keycloakError) {
    console.error(keycloakError)
    return <TechnicalErrorPage />
  } else if (keycloak) {
    if (!keycloak.authenticated || keycloak.isTokenExpired()) {
      void keycloak.login({ redirectUri: location.href })
      return null
    } else if (loading) {
      return <LoadingState />
    } else {
      return <Component />
    }
  } else {
    throw new Error('Unknown error')
  }
}

export default DownloadFileAuthenticatedRoute
