import { Pagination, Space } from '@hindawi/phenom-ui'
import React from 'react'
import styled from 'styled-components'
import { ManuscriptCard } from '../../ManuscriptCard'

const PaginationContainer = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
`

export const ManuscriptListWithPagination = ({
  manuscripts,
  handleChangePage,
  page,
  itemsPerPage,
  totalManuscripts,
}) => (
  <Space direction="vertical" width="full" size={16}>
    {manuscripts.map((manuscript, key) => {
      return <ManuscriptCard manuscript={manuscript} key={key} />
    })}

    {totalManuscripts > itemsPerPage ? (
      <PaginationContainer>
        <Pagination
          data-test-id="editorial-dashboard-pagination"
          total={totalManuscripts}
          current={page}
          defaultCurrent={1}
          hideOnSinglePage
          pageSize={itemsPerPage}
          onChange={handleChangePage}
          showSizeChanger={false}
        />
      </PaginationContainer>
    ) : null}
  </Space>
)
