import LogRocket from 'logrocket'

export const parseError = (e) => (e.message || '').split(':').pop().trim()

const AUTH_TOKENS = 'authTokens'

export const getAuthTokens = () =>
  JSON.parse(window.localStorage.getItem(AUTH_TOKENS) || '{}')

export const setAuthTokens = ({ token, idToken, refreshToken }) =>
  window.localStorage.setItem(
    AUTH_TOKENS,
    JSON.stringify({ token, idToken, refreshToken }),
  )

export const clearAuthTokens = () => {
  window.localStorage.removeItem(AUTH_TOKENS)
}

export const parseSearchParams = (url) => {
  const params = new URLSearchParams(url)
  const parsedObject = {}
  /* eslint-disable */
  for (let [key, value] of params) {
    parsedObject[key] = value
  }
  /* eslint-enable */
  return parsedObject
}

export const getConnectId = (token) => {
  try {
    const tokenParts = token.split('.')
    const { connectId } = JSON.parse(atob(tokenParts[1]))
    return connectId
  } catch {
    return undefined
  }
}

export const identifyLogrocketUser = (keycloak) => {
  if (!process.env.LOGROCKET_ID) {
    return
  }
  const { token } = keycloak
  if (!token) {
    return
  }
  const connectId = getConnectId(token)
  if (!connectId) {
    return
  }
  LogRocket.identify(connectId)
}
